import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import KeycloakService from '../../../Keycloak'
import {VoterLoginFlowContext} from '../../contexts/VoterLoginFlowContext'
import VoterAuthService from '../../services/auth/VoterAuthService'
import AdminVoterAuthService from '../../services/auth/AdminVoterAuthService'
import KeycloakAdminService from '../../../KeycloakAdminService'
import {useTranslation} from '../../hooks/useTranslation'

const LogoutButton = () => {
  const history = useHistory()
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  const {translate} = useTranslation()

  const handleLogout = async () => {
    try {
      if (KeycloakService.isLoggedIn()) {
        VOTER_LOGIN_CONTEXT.setLoginFlow('Login')
        await VoterAuthService.logOut(history)
      }
      if (KeycloakAdminService.isLoggedIn()) {
        VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
        await AdminVoterAuthService.logOut(history)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <span className='adminTopbar__logoutTxt--MEXICO' onClick={handleLogout}>
      {translate('Logout')}
    </span>
  )
}

export default LogoutButton
