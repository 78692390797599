/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from 'react'
import {useTranslation} from '../../../hooks/useTranslation'
import PasswordValidationTxt from '../../../components/password_validation_txt/PasswordValidationTxt'
import {passwordValidateFunction} from './PasswordValidateHelperFunctions'

const MIN_LENGTH_PW = 8

const passwordReqs = {
  minLength: `Minimum ${MIN_LENGTH_PW} characters in length`,
  upperCase: 'At least one uppercase letter',
  lowerCase: 'At least one lowercase letter',
  digit: 'At least one number',
  specialChar: 'At least one special character (!,#,@,%,&,^,*,(,),+,=,-,[,],~)',
}

const possibleErrors = Object.values(passwordReqs)

export function PasswordValidate(props) {
  let {formik} = props
  const {translate} = useTranslation()
  const [errorList, setErrorList] = useState([])

  useEffect(() => {
    passwordValidateFunction(
      formik.values.password,
      setErrorList,
      MIN_LENGTH_PW,
      errorList,
      passwordReqs
    )
    let valid = errorList.length === 0
    // || (errorList.length === 1 && errorList.includes(passwordReqs.specialChar))
    props.result(valid)
  }, [formik.values.password])

  return (
    <div className='createPassword__form__body__validationContainer'>
      <span
        className={`createPassword__form__body__validation__title ${
          errorList.length == 0
            ? //||
              // (errorList.length == 1 && errorList.includes(passwordReqs.specialChar))
              'createPassword__form__body__validation__title--green'
            : ''
        } `}
      >
        {translate('Your password must meet the minimum requirements:')}
      </span>

      {/* START */}
      {/* Iterate throught the list of possible errors  */}
      {possibleErrors.map((errorMsg, i) => {
        /* check if the ith value does not exist in the current errors list (errorsList) */
        return (
          <PasswordValidationTxt
            text={translate(errorMsg)}
            isValid={!errorList.includes(errorMsg)}
          />
        )
      })}
      {/* END */}
    </div>
  )
}
