import React from 'react'
import styled from 'styled-components'
import NoAttachmentsMessage from './no_attachments_message/NoAttachmentsMessage'
import DocCard from '../../pages/help-docs/DocCard'

const Attachments = ({listOfDocs, getAttachments, viewOnly}) => {
  const eventHasAttachments = listOfDocs.length > 0
  return (
    <StyledAttachments>
      {eventHasAttachments ? (
        listOfDocs.map((doc, index) => (
          <DocCard
            doc={doc}
            index={index}
            key={doc.id}
            getDocs={getAttachments}
            viewOnly={viewOnly}
          />
        ))
      ) : (
        <NoAttachmentsMessage />
      )}
    </StyledAttachments>
  )
}

const StyledAttachments = styled.div`
  margin-left: 25px;
  display: flex;
  flex-flow: row wrap;
  margin-right: 100px;
  margin-top: 35px;

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`

export default Attachments
