import React, {useContext} from 'react'
import {getFileType} from '../../helperfunctions/FileHelpers'
import {languageValueToKey} from '../../languages/languagekey'
import {toAbsoluteUrl} from '../../helperfunctions/AssetHelpers'
import {LanguageContext} from '../../contexts/LanguageContext'

const FileIcon = ({filename}) => {
  const {currentLanguage} = useContext(LanguageContext)
  const languageKey = languageValueToKey[currentLanguage]
  const fileType = getFileType(filename[languageKey])

  const getIcon = () => {
    switch (fileType) {
      case 'pdf':
        return <img alt='pdf icon' className='' src={toAbsoluteUrl('/media/design/icon_pdf.svg')} />
      case 'jpg':
      case 'jpeg':
        return <img alt='jpg icon' className='' src={toAbsoluteUrl('/media/design/icon_jpg.svg')} />
      case 'png':
        return <img alt='png icon' className='' src={toAbsoluteUrl('/media/design/icon_png.svg')} />
      case 'doc':
      case 'docx':
        return <img alt='doc icon' className='' src={toAbsoluteUrl('/media/design/icon_doc.svg')} />
      case 'mov':
      case 'mp4':
        return (
          <img
            alt='mov/mp4 icon'
            className=''
            src={toAbsoluteUrl('/media/design/icon_video.svg')}
          />
        )
      case 'mp3':
      case 'wav':
        return (
          <img
            alt='mp3/wav icon'
            className=''
            src={toAbsoluteUrl('/media/design/icon_audio.svg')}
          />
        )
      default:
        return null
    }
  }

  return getIcon()
}

export default FileIcon
