/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useFormik} from 'formik'
import TextField from '../../../../modules/auth/reusable/TextField'
import * as Yup from 'yup'

import '../my_profile/MEXICO__MyProfile.scss'
import '../dashboard/MEXICO__Dashboard.scss'
import '../common.scss'
import './MEXICO__ChangePassword.scss'
import {useHistory} from 'react-router-dom'
import VoterAuthService from '../../../../services/auth/VoterAuthService'
import {PasswordValidate} from '../../../public/onboarding/PasswordValidate'
import {useTranslation} from '../../../../hooks/useTranslation'
import {addPeriodToText} from '../../../common/utility_functions/TextUtility'

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function MEXICO__ChangePassword(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [authError, setAuthError] = useState(false)

  const [validationResult, setValidationResult] = useState(false)

  let flow = props.history?.location?.state?.flow

  const passwordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    password: Yup.string().required('New password is required'),
    confirmPassword: Yup.string()
      .required('Password confirmation is required')
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match"
        ),
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    enableReinitialize: true,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setAuthError(false)
      setTimeout(async () => {
        try {
          const userInput = {
            // USER INFO
            currentPassword: values.currentPassword,
            password: values.password,
            confirmPassword: values.confirmPassword,
          }
          let updated
          updated = await VoterAuthService.updateUserPassword(
            userInput.currentPassword,
            userInput.password
          )
          setLoading(false)
          if (updated?.success) {
            history.push('/voting/voter/my-profile', {
              status: 'verificationSuccess--changePassword',
            })
            formik.resetForm()
          } else {
            setAuthError(true)
          }
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  useEffect(() => {
    setAuthError(false)
  }, [formik.values.currentPassword])

  return (
    <div className='MEXICO__ChangePassword '>
      <span className='myProfiletM__title'> {translate('Change Password')}</span>
      <div className='common__pg__header'>
        <div>
          <span
            className='common__backBtn'
            onClick={() => {
              history.goBack()
            }}
          >
            {`< ${translate('Back')}`}
          </span>
          <span className='common__pg__header__title'>{translate('Change Password')}</span>
        </div>
      </div>

      <div className='changePassword__body'>
        <div className='changePassword__body__formOuter'>
          <form
            className='changePassword__body__formInner'
            onSubmit={(e) => {
              e.preventDefault()
              formik.submitForm()
            }}
          >
            <div className='changePassword__body__form__inputContainer'>
              <TextField
                formik={formik}
                type='password'
                field='currentPassword'
                placeholder={translate('Current password')}
                label={translate('Current password')}
                isRequired={true}
                view='password'
              />

              {formik.touched['currentPassword'] && formik.errors['currentPassword'] && (
                <div className='TextField__voterErrWrap'>
                  <span className='TextField__voterErrTxt' role='alert'>
                    {addPeriodToText(translate(formik.errors['currentPassword']))}
                  </span>
                </div>
              )}
              <div className='createPassword__form__body__password__confirmInput'>
                <TextField
                  formik={formik}
                  type='password'
                  field='password'
                  placeholder={translate('New password')}
                  label={translate('New password')}
                  isRequired={true}
                  view='password'
                />

                {formik.touched['password'] && formik.errors['password'] && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {addPeriodToText(translate(formik.errors['password']))}
                    </span>
                  </div>
                )}
              </div>
              <div className='createPassword__form__body__password__confirmInput'>
                <TextField
                  formik={formik}
                  type='password'
                  field='confirmPassword'
                  placeholder={translate('Confirm new password')}
                  label={translate('Confirm new password')}
                  isRequired={true}
                  view='password'
                />

                {formik.touched['confirmPassword'] && formik.errors['confirmPassword'] && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {addPeriodToText(translate(formik.errors['confirmPassword']))}
                    </span>
                  </div>
                )}

                {authError && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {addPeriodToText(
                        translate('Authentication failed, please check current password')
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <PasswordValidate
              formik={formik}
              result={(value) => {
                setValidationResult(value)
                // console.log('value', value)
              }}
            />
          </form>

          <div
            className='changePassword__body__form__footer'
            onClick={() => {
              formik.submitForm()
            }}
          >
            <button
              className={
                validationResult
                  ? 'authMethodLogin__nextBtn'
                  : 'authMethodLogin__nextBtn common__disabledBtn'
              }
              disabled={!validationResult}
            >
              {translate('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
