import axios from 'axios'
import environment from '../../../utils/environment'
import KeycloakService from '../../../KeycloakAdminService'
import Cookies from 'universal-cookie'
import {languageValueToKey} from '../../languages/languagekey'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyTokenAdmin'

const axiosInstance = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}/admin`,
  withCredentials: true,
  headers: {},
})

// attach access token
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = KeycloakService.getToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
axiosInstance.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }

    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
      cookies.remove(VERIFY_COOKIE)
    }
    return res
  },
  async (err) => {
    console.log(err)
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  }
)

const axiosInstanceVerify = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}/admin`,
  withCredentials: true,
  headers: {},
})

// attach access token
axiosInstanceVerify.interceptors.request.use(
  async (config) => {
    const accessToken = cookies.get(VERIFY_COOKIE)
    // console.log('access token', accessToken)
    if (sessionStorage.getItem('redirect') === 'true') {
      config.params = {...config.params, redirect: 'true'}
    }
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
axiosInstanceVerify.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
      cookies.remove(VERIFY_COOKIE)
    }
    return res
  },
  async (err) => {
    if (err.status === 403 || err.response?.status === 403) {
      cookies.remove(VERIFY_COOKIE)
    }
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  }
)

const AdminVoterAuthService = {
  // login with username and password then go to next step
  login: async (username, password, recaptchaToken, language) => {
    try {
      let config = {}
      language = languageValueToKey[language]
      if (sessionStorage.getItem('redirect') === 'true') {
        config.params = {...config.params, redirect: 'true'}
      }

      let resp = await axios.post(
        `${environment.resolveApi().voter_reg_rest}/admin/auth/login?locale=${language}`,
        {
          username: username,
          password: password,
          recaptchaToken,
        },
        config
      )
      let user
      resp = resp.data

      if (resp.accessToken) {
        cookies.set(VERIFY_COOKIE, resp.accessToken)
      }
      if (resp.user) {
        user = resp.user
      }
      if (resp.link && resp.link.includes('login-actions')) {
        window.location.replace(resp.link)
        cookies.remove(VERIFY_COOKIE)
      }

      return {
        success: true,
        user: user,
        link: resp.link,
        passwordResetRequired: resp.passwordResetRequired,
        onboarding: resp.onboarding,
      }
    } catch (error) {
      console.log(error.response?.data?.error)
      error = {
        ...error.response?.data?.error,
      }
      return {error}
    }
  },

  verifyAuthAppCode: async (code) => {
    try {
      await axiosInstanceVerify.post('/auth/mfa/verify/authapp', {code})

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },

  verifySMSCodeOnboard: async (verificationCode) => {
    try {
      const resp = await axiosInstanceVerify.post('/onboard/mfa/verify/sms', {verificationCode})

      return {success: true, qrCodeDataUrl: resp.qrCodeDataUrl, factorSid: resp.factorSid}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyAuthAppCodeOnboard: async (code, factorSid) => {
    try {
      const resp = await axiosInstanceVerify.post('/onboard/mfa/verify/authapp', {code, factorSid})
      return {success: true, passwordResetRequired: resp.link ? false : true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  updateUserPasswordOnboard: async (currentPassword, newPassword) => {
    try {
      await axiosInstanceVerify.put('/onboard/password', {
        currentPassword,
        newPassword,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
    }
  },

  requestRegenerateQRCode: async () => {
    try {
      await axiosInstance.post('/auth/request/regenerate-qr-code')
      return {success: true}
    } catch (error) {
      console.log(error)
      return {error}
    }
  },

  requestPasswordReset: async (username, email, recaptchaToken) => {
    try {
      await axiosInstanceVerify.post('/auth/request/resetpassword', {
        username,
        email,
        recaptchaToken,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyAuthAppCodePasswordResetFlow: async (code) => {
    try {
      await axiosInstanceVerify.post('/auth/resetpassword/mfa/verify/authapp', {code})

      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  updateUserPasswordResetPasswordFlow: async (currentPassword, newPassword) => {
    try {
      await axiosInstanceVerify.put('/auth/resetpassword/password', {
        currentPassword,
        newPassword,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
    }
  },

  getUserInfoKeycloakToken: () => {
    try {
      const parsedToken = KeycloakService.getParsedToken()
      let user = {}
      if (parsedToken) {
        user = {
          username: parsedToken.preferred_username,
          email: parsedToken.email,
          phone: parsedToken.phone_number,
          phoneNumber: parsedToken.phone_number,
          // TODO role
        }
      }
      return user
    } catch (error) {}
  },
  // TODO user not logged in keycloak
  // TODO update on login & if logged in whenever change
  //   updateUserLanguage: async (language) => {
  //     try {
  //       language = languageValueToKey[language]
  //       await axiosInstance.put('/account/language', {language: language})
  //     } catch (error) {}
  //   },

  updateUserPassword: async (currentPassword, newPassword) => {
    try {
      await axiosInstance.put('/auth/password', {currentPassword, newPassword})
      return {success: true}
    } catch (error) {
      // console.log(error)
    }
  },

  superAdminUnlockAccountFoAdminUser: async (keycloakId) => {
    try {
      await axiosInstance.post(`/auth/users/${keycloakId}/unlock`)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  superAdminResetPasswordForAdminUser: async (keycloakId) => {
    try {
      await axiosInstance.post(`/auth/users/${keycloakId}/resetpassword`)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  superAdminRegenerateQRCodeForAdminUser: async (keycloakId) => {
    try {
      await axiosInstance.post(`/auth/users/${keycloakId}/regenerate-qr-code`)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  superAdminFetchAdminUsers: async () => {
    try {
      let resp = await axiosInstance.get(`/admin-users`)
      return {success: true, data: resp}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  superAdminFetchRealmRoles: async () => {
    try {
      let resp = await axiosInstance.get(`/admin-roles`)
      return {success: true, data: resp}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  superAdminCreateUser: async (userData) => {
    try {
      let resp = await axiosInstance.post(`/admin-users`, userData)
      console.log(resp)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  superAdminDeleteUser: async (keycloakId) => {
    try {
      await axiosInstance.delete(`/admin-users/${keycloakId}`)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  getTiviElectionComponentLink: async () => {
    try {
      let resp = await axiosInstance.get(`/election-component-link`)
      return resp.tiviLink
    } catch (error) {
      // console.log(error)
      // return {error}
    }
  },
  // syncSession: async () => {
  //   try {
  //     let res = await axiosInstance.get('/sync-session', {tabId: window.name})
  //     console.log(res)
  //     return {success: true}
  //   } catch (error) {
  //     // console.log(error)
  //     return {error, success: false}
  //   }
  // },
  // clearSession: async () => {
  //   try {
  //     console.log('clear sessions')
  //     let res = await axiosInstance.get('/clear-session')
  //     console.log(res)
  //     return {success: true}
  //   } catch (error) {
  //     console.log(error)
  //     return {error, success: false}
  //   }
  // },
  extendSession: async () => {
    try {
      let resp = await axiosInstance.get('/auth/extend-session')
      if (resp.link) {
        window.location.location.href = resp.link
      }
    } catch (error) {
      console.log(error)
      return {error, success: false}
    }
  },
  logOut: async (history) => {
    if (KeycloakService.isLoggedIn()) {
      try {
        await axiosInstance.get('/auth/logout')
      } catch (error) {
        console.error('admin logout', error)
      }

      KeycloakService.doLogout()
    }
    sessionStorage.clear()
    history && history.push('/admin/login')
    if (!history) {
      window.location.reload()
    }
  },

  getHelpDocs: async () => {
    try {
      let resp = await axiosInstance.get(`/helpguide`)

      return resp
    } catch (error) {
      console.log(error)
    }
  },
  getHelpDocsEvent: async (eventId) => {
    try {
      let resp = await axiosInstance.get(`/helpguide/events/${eventId}`)

      return resp
    } catch (error) {
      console.log(error)
    }
  },
  createHelpDoc: async (document) => {
    try {
      console.log(document)
      let resp = await axiosInstance.post(`/helpguide`, document)

      return resp
    } catch (error) {
      console.log(error)
    }
  },
  createHelpDocEvent: async (document, eventId) => {
    try {
      let resp = await axiosInstance.post(`/helpguide/events/${eventId}`, document)

      return resp
    } catch (error) {
      console.log(error)
    }
  },
  deleteHelpDoc: async (documentId) => {
    try {
      await axiosInstance.delete(`/helpguide/${documentId}`)
      console.log('delete success')
    } catch (error) {
      console.log('delete errorr', error)
    }
  },

  getVoterDetails: async ({voterId}) => {
    try {
      let response = await axiosInstance.get(`/voters/${voterId}`)

      return response
    } catch (error) {
      console.log(error)
    }
  },
  getRegistrationState: async () => {
    try {
      let response = await axiosInstance.get(`/settings`)

      return response
    } catch (error) {
      console.log(error)
    }
  },
  updateRegistrationState: async (registrationState) => {
    try {
      let response = await axiosInstance.put(`/settings/registration-state/${registrationState}`)

      return response
    } catch (error) {
      console.log(error)
    }
  },
}

export const voterAPI = axiosInstance

export default AdminVoterAuthService
