/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'

import * as Yup from 'yup'
import './pagination.scss'

import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
//------------- FILTERS V2 ---------------
// import {FilterModal} from './FilterModal'
import {ModalWrapper} from '../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'

import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {EmailSendModal} from './EmailSendModal'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'

import {EditUserModal} from './edit_user_drodown/EditUserModal'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {districts} from '../../../../../portalData/Districts'

import '../../../../super_admin/mexico/pages/admin_users/MEXICO__SuperAdmin__Users.scss'
import '../../nova_scotia/address_list/AdminAddressList.scss'
import '../../nova_scotia/electors_list/AdminElectorsList.scss'
import './ElectorsListTable.scss'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import ElectorsListTableHeader from './ElectorsListTableHeader'

import ElectorsListFooter from './ElectorsListFooter'
import UserFiltersModal from './UserFitlersModal'
import SearchTextFieldNoFormik from '../../../../../modules/auth/reusable/SearchTextFieldNoFormik'
import ElectorsListRow from './ElectorsListRow'
import {FiFrown} from 'react-icons/fi'

export const NUM_VOTERS_PER_PAGE = 20
export const initialValues = {}

export const loginSchema = Yup.object().shape({})

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}
export function ElectorsListTable(props) {
  const {translate, translateObj} = useTranslation()

  const [filterModal, setFilterModal] = useState(false)
  const [emailFilterModal, setEmailFilterModal] = useState(false)
  const [thanksModal, setThanksModal] = useState(false)
  const [downloadURL, setDownloadURL] = useState(null)
  const [downloadingList, setDownloadingList] = useState(false)
  const [districtFilter, setDistrictFilter] = useState('all')
  const [channelFilter, setChannelFilter] = useState('all')
  const [loading, setLoading] = useState(true)

  const [users, setUsers] = useState([])
  // total number of users
  const [numUsers, setNumUsers] = useState(0)

  const [currentPageNum, setCurrentPageNum] = useState(0)
  const [onlineEvents, setOnlineEvents] = useState([])
  const [editUserModal, setEditUserModal] = useState(false)
  const [userToEdit, setUserToEdit] = useState({email: ''})
  const [editUserModalView, setEditUserModalView] = useState('Edit')
  const {primaryColor} = useVoterStyles()

  const [filters, setFilters] = useState({})
  const [search, setSearch] = useState('')
  //------------- FILTERS V2 ---------------
  // const [filter, setFilter] = useState({})

  const viewOnly =
    !KeycloakAdminService.hasRole('tivi-root-admin') &&
    !KeycloakAdminService.hasRole('tivi-event-admin')

  //------------- FILTERS V2 ---------------
  // const getUsers2 = async ({
  //   accountIdGroup,
  //   accountIdSubgroup,
  //   accountIdRegion,
  //   accountIdBranch,
  //   accountIdClassification,
  //   acountIdSubclassification,
  //   accountIdEmployer,
  //   accountIdDepartment,
  //   accountIdChapter,
  //   userType,
  //   search,
  // }: {
  //   accountIdGroup?: string
  //   accountIdSubgroup?: string
  //   accountIdRegion?: string
  //   accountIdBranch?: string
  //   accountIdClassification?: string
  //   acountIdSubclassification?: string
  //   accountIdEmployer?: string
  //   accountIdDepartment?: string
  //   accountIdChapter?: string
  //   userType?: string
  //   limit?: number
  //   skip?: number
  //   search?: string
  // }) => {
  //   setLoading(true)
  //   let resp = await AdminVoterService.fetchVoters2({
  //     accountIdGroup,
  //     accountIdSubgroup,
  //     accountIdRegion,
  //     accountIdBranch,
  //     accountIdClassification,
  //     acountIdSubclassification,
  //     accountIdEmployer,
  //     accountIdDepartment,
  //     accountIdChapter,
  //     userType,
  //     limit: NUM_VOTERS_PER_PAGE,
  //     skip: currentPageNum * NUM_VOTERS_PER_PAGE,
  //     search,
  //   })
  //   if (resp?.users) {
  //     // console.log('resp?.users', resp?.users)
  //     //@ts-ignore
  //     setUsers(resp.users)
  //     setNumUsers(resp.numUsers)
  //   }
  //   setLoading(false)
  // }
  //------------- FILTERS V2 ---------------

  const getUsers = async () => {
    setLoading(true)
    let resp = await AdminVoterService.fetchVoters(
      filters,
      channelFilter,
      NUM_VOTERS_PER_PAGE,
      currentPageNum * NUM_VOTERS_PER_PAGE,
      search
    )
    if (resp?.users) {
      // console.log('resp?.users', resp?.users)
      //@ts-ignore
      setUsers(resp.users)
      setNumUsers(resp.numUsers)
    }
    setLoading(false)
  }

  useEffect(() => {
    getUsers()
  }, [filters, currentPageNum])

  // TODO: BUG on quickly clearing search
  useEffect(() => {
    setCurrentPageNum(0)
    getUsers()
  }, [search])

  const getDowloadUrl = async () => {
    const res = await AdminVoterService.getVoterCsv(filters, channelFilter)
    if (res?.success) {
      // @ts-ignore
      const href = URL.createObjectURL(res.data)
      setDownloadURL(href)
      const link = document.createElement('a')
      let districtName = districts.find((o) => o.value === districtFilter)?.label || districtFilter
      link.download = `voterlist-${districtName}-${channelFilter}`
      link.href = href
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setDownloadingList(false)
  }

  // // get online events for email
  // useEffect(() => {
  //   const getOnlineEvents = async () => {
  //     const res = await AdminVoterService.getOnlineEventsList(districtFilter)

  //     if (res) {
  //       //@ts-ignore
  //       let events = res.map((e) => {
  //         return {...e, value: e.tiviId, label: translateObj(e.name)}
  //       })
  //       setOnlineEvents(events)
  //     }
  //   }
  //   getOnlineEvents()
  // }, [districtFilter, channelFilter])

  // const sendEmailsOnlineDistrict = async (tivId) => {
  //   const res = await AdminVoterService.sendEmailsOnlineVoters(tivId)
  //   if (res.success) {
  //     setEmailFilterModal(false)
  //     alert(translate('Success'))
  //   } else {
  //     alert('Error')
  //   }
  // }

  // ------------- FILTERS V2 ---------------
  // const handleSearchChange = debounce(async (value) => {
  //   setFilter({...filter, search: value})
  //   setSearch(value)
  // }, 300)

  // const resetComponent = async () => {
  //   await getUsers2({...filter})
  // }
  // useEffect(() => {
  //   resetComponent()
  // }, [filter, currentPageNum])
  // ------------- FILTERS V2 ---------------

  return (
    <div id='Voter'>
      {/* MODAL */}
      {/* <PropertyInfoModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      /> */}
      {/* <VoterInfoModal
        isOpen={voterInfoModal}
        onAfterOpen={afterOpenVoterModal}
        onRequestClose={() => {
          setVoterInfoModal(false)
        }}
        style={customStyles}
      /> */}

      <EditUserModal
        isOpen={editUserModal}
        onRequestClose={() => {
          setEditUserModal(false)
        }}
        style={customStyles}
        modalView={editUserModalView}
        userToEdit={userToEdit}
      />

      <ModalWrapper
        isOpen={thanksModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setThanksModal(false)
        }}
        style={customStyles}
        modalView={downloadingList ? 'Downloading' : 'Downloaded'}
        headerLabel={translate('Export Electors List')}
        downloadURL={downloadURL}
        districtFilter={districtFilter}
        channelFilter={channelFilter}
      />

      {/* 
  // ------------- FILTERS V2 ---------------
      <FilterModal
        isOpen={filterModal}
        onRequestClose={(value) => {
          setFilterModal(false)
          if (value == 'close-clear') {
            getUsers2({})
          }
        }}
        style={customStyles}
        onUpdateFilter={({
          accountIdGroup,
          accountIdSubgroup,
          accountIdRegion,
          accountIdBranch,
          accountIdClassification,
          acountIdSubclassification,
          accountIdEmployer,
          accountIdDepartment,
          accountIdChapter,
          userType,
        }) => {
          setFilter({
            accountIdGroup,
            accountIdSubgroup,
            accountIdRegion,
            accountIdBranch,
            accountIdClassification,
            acountIdSubclassification,
            accountIdEmployer,
            accountIdDepartment,
            accountIdChapter,
            userType,
            search,
          })
        }}
        currentFilter={filter}
        districtFilter={districtFilter}
        channelFilter={channelFilter}
        appliedFiltersfilters={filters}
        setAppliedFilters={setFilters}
      /> 
        // ------------- FILTERS V2 ---------------
      */}

      <UserFiltersModal
        isOpen={filterModal}
        onRequestClose={() => {
          setFilterModal(false)
        }}
        style={customStyles}
        appliedFiltersfilters={filters}
        setAppliedFilters={setFilters}
      />

      <EmailSendModal
        isOpen={emailFilterModal}
        // onAfterOpen={after}
        onRequestClose={() => {
          setEmailFilterModal(false)
        }}
        style={customStyles}
        // onSend={sendEmailsOnlineDistrict}
        options={onlineEvents}
      />

      {/* CONSOLE */}
      <div className='voterAddrList__tableContanier'>
        {/* TABLE */}
        <div className='voterAddrList__table'>
          <div className='voterAddrList__table__actionBar'>
            <SearchTextFieldNoFormik
              type='text'
              field='search'
              placeholder={translate('Member ID')}
              label=''
              isRequired={false}
              noLabel={true}
              view='admin_searchbar-no-formik'
              customStyling={{width: 250, marginRight: 25}}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                // ------------- FILTERS V2 ---------------
                // handleSearchChange(e.target.value)
              }}
            />{' '}
            <div />
            <div>
              <img
                onClick={() => {
                  setFilterModal(true)
                }}
                className='voterAddrList__table__actionBar__filterIcon'
                src={toAbsoluteUrl('/media/design/icon_funnel.svg')}
                alt='filter'
              />
              {!viewOnly && (
                <img
                  onClick={() => {
                    setDownloadingList(true)
                    setThanksModal(true)
                    getDowloadUrl()
                  }}
                  className='voterAddrList__table__actionBar__downloadIcon'
                  src={toAbsoluteUrl('/media/design/icon_file_download.svg')}
                  alt='download'
                />
              )}
              {/* {!viewOnly && (
                <img
                  onClick={() => {
                    setEmailFilterModal(true)
                  }}
                  className='voterAddrList__table__actionBar__downloadIcon'
                  src={toAbsoluteUrl('/media/design/icon_email.svg')}
                  alt='email'
                />
              )} */}
            </div>
          </div>

          <ElectorsListTableHeader />
          {loading ? (
            <div className='voterAddrList__table voterAddrList__table--loading'>
              <img
                className='voterAddrList__table--loading__icon'
                alt='spinner icon'
                src={toAbsoluteUrl('/media/design/spinner.svg')}
              />
            </div>
          ) : (
            <div className='voterAddrList__tableBody'>
              {users && users.length > 0 ? (
                <>
                  {users.map((user) => (
                    <ElectorsListRow
                      user={user}
                      setUserToEdit={setUserToEdit}
                      setEditUserModal={setEditUserModal}
                      setEditUserModalView={setEditUserModalView}
                    />
                  ))}
                </>
              ) : (
                <div className='electorsListTable--noResult'>
                  <FiFrown size={60} color={primaryColor} />
                  <span className='electorsListTable--noResult__txt'>
                    {translate('Sorry, no voters using your search filter.')}
                  </span>

                  <span
                    className='electorsListTable--noResult__searchLink'
                    onClick={() => {
                      setFilterModal(true)
                    }}
                  >
                    {translate('Change Filter')}
                  </span>

                  <span
                    className='electorsListTable--noResult__searchLink'
                    onClick={() => {
                      // ------------- FILTERS V2 ---------------
                      // setFilter({search: ''})
                      setFilters({})
                      setSearch('')
                      setCurrentPageNum(0)
                    }}
                  >
                    {translate('Reset Filter')}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <ElectorsListFooter
        currentPageNum={currentPageNum}
        numUsers={numUsers}
        setCurrentPageNum={setCurrentPageNum}
      />
    </div>
  )
}
