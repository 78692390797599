/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {useState} from 'react'
import {VoterAuthContext} from '../contexts/VoterAuthContext'

import {VoterUserContext} from '../contexts/VoterUserContext'
import {VoterLoginFlowContext} from '../contexts/VoterLoginFlowContext'
import {VoterRoutes} from './VoterRoutes'
import {useVoterKeyCloakAuth} from '../hooks/useVoterKeycloakAuth'

const VoterRegRoutes = () => {
  const [adminView, setAdminView] = useState(false)
  const [user, setUser] = useState({votingChannel: '', username: ''})

  const [loginFlow, setLoginFlow] = useState('Login')

  useVoterKeyCloakAuth({adminView, setAdminView, user, setUser})
  return (
    <div style={{maxWidth: '100%', display: 'flex', width: '100%', flexFlow: 'column'}}>
      <VoterAuthContext.Provider value={{adminView, setAdminView}}>
        <VoterLoginFlowContext.Provider value={{loginFlow, setLoginFlow}}>
          <VoterUserContext.Provider value={{user, setUser}}>
            <VoterRoutes adminView={adminView} />
          </VoterUserContext.Provider>
        </VoterLoginFlowContext.Provider>
      </VoterAuthContext.Provider>
    </div>
  )
}

export {VoterRegRoutes}
