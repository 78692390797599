import {useTranslation} from '../../hooks/useTranslation'
import LanguageSpecificColon from '../language_specific_colon/LanguageSpecificColon'

const RenderTBD = ({title, size, layout}) => {
  const {translate} = useTranslation()
  if (size === 'big') {
    return (
      <div
        className={`eventVoteCard__footer__contentWrap ${
          layout !== 'row' ? 'eventVoteCard__footer__contentWrap-vertical' : ''
        }`}
      >
        <span
          className={`eventVoteCard__footer__title
      ${layout !== 'row' ? 'eventVoteCard__footer__title-vertical' : ''} `}
        >
          {translate(title)}
          <LanguageSpecificColon />
        </span>

        <span
          className={`eventVoteCard__footer__title
           ${layout !== 'row' ? 'eventVoteCard__footer__title-vertical' : ''} `}
          style={{color: '#a1a5b7'}}
        >
          {translate('TBD')}
        </span>
      </div>
    )
  }
  return (
    <div className='dashboard__votingStatusWrap'>
      <div className='eventDisplayCard__body__content__vtClosed__label'>
        {translate(title)}
        <LanguageSpecificColon />
      </div>
      <div className='eventDisplayCard__body__content__vtClosed__value'>{translate('TBD')}</div>
    </div>
  )
}
export default RenderTBD
