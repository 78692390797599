/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import './FilterModal.scss'
import Modal from 'react-modal'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {ModalHeaderWithLabel} from '../../../../../../layout/ModalHeaderWithLabel'
import {Spinner} from 'react-bootstrap-v5'
import StyledReactSelect from '../../../../../resuable/custom_dropdown/StyledReactSelect'
import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {LanguageContext} from '../../../../../contexts/LanguageContext'

const USER_FITLER_TYPES = [
  'Group',
  'Subgroup',
  'Region',
  'Branch',
  'Classification',
  'Subclassification',
  'Employer',
  'Department',
  'Chapter',
]
const USER_TYPES = ['REG', 'RAND', 'RTIR', 'CANC', 'TEST']

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    minWidth: '500px',
    minHeight: '500px',
    maxWidth: '780px',
    width: 'fit-content',
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',

    padding: 0,
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
    overflowY: 'scroll',
  },
}

export const UserFiltersModal = (props) => {
  const {translate, translateObj} = useTranslation()
  const {currentLanguage} = useContext(LanguageContext)

  let {isOpen, appliedFilters, setAppliedFilters} = props

  const [options, setOptions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState(appliedFilters || {})
  const {customBtnClass} = useVoterStyles()

  const getParentFilters = async () => {
    const optionsUserType = USER_TYPES.map((u) => {
      return {label: u, value: u, accountId: u}
    })
    let localOptions = {userType: optionsUserType}
    for (var i = 0; i < USER_FITLER_TYPES.length; i++) {
      if (i % 2 === 0) {
        let optionsFilter = await AdminVoterService.getParentFilters(
          USER_FITLER_TYPES[i],
          translateObj
        )
        localOptions = {...localOptions, [USER_FITLER_TYPES[i]]: optionsFilter || []}
      }
    }
    setOptions(localOptions)
    setLoading(false)
  }

  const getChildFilters = async (filters, filterType, parentFilterIndex) => {
    let groupedChildFilters = []
    for (var filter of filters) {
      let childFilters = await AdminVoterService.getChildFilters(filter.accountId, translateObj)
      groupedChildFilters.push({
        label: translateObj(filter.title),
        options: childFilters,
      })
    }
    setOptions({...options, [USER_FITLER_TYPES[parentFilterIndex + 1]]: groupedChildFilters})
  }

  const onSelectFilter = (filter, action, filterType) => {
    setFilters({...filters, [`accountId${filterType}`]: filter})
    let filterIndex = USER_FITLER_TYPES.findIndex((f) => f === filterType)
    if (filterIndex % 2 === 0) {
      getChildFilters(filter, filterType, filterIndex)
      // for simplicity if clear all parent filters or remove one parent filter, reset selected children
      if (
        (action.action === 'clear' || action.action === 'remove-value') &&
        filterIndex < USER_FITLER_TYPES.length - 1
      ) {
        setFilters({
          ...filters,
          [`accountId${USER_FITLER_TYPES[filterIndex + 1]}`]: [],
          [`accountId${filterType}`]: filter,
        })
      }
    }
  }
  const onSelectUserType = (filter) => {
    setFilters({...filters, [`userType`]: filter})
  }

  const onCancel = () => {
    setFilters({})
    getParentFilters()
    props.onRequestClose()
  }

  const onApplyFilters = () => {
    setAppliedFilters(filters)
    props.onRequestClose()
  }

  // UNCOMMENT FOR DEBUGING
  // useEffect(() => {
  //   console.log('selected filters', filters)
  //   console.log('dropdown options', options)
  // }, [filters])

  useEffect(() => {
    getParentFilters()
  }, [currentLanguage])

  const render = () => {
    if (loading) {
      return <Spinner animation='border' />
    }
    return (
      <div className='filters-container'>
        {USER_FITLER_TYPES.map((filterType) => (
          <div className='userfilterModal__dropdown__container'>
            <StyledReactSelect
              options={options[filterType] || []}
              customStyles={{width: 250}}
              label={filterType}
              key={filterType}
              onChange={(e, action) => onSelectFilter(e, action, filterType)}
              value={filters[`accountId${filterType}`] || []}
              isClearable
              isMulti
              placeholder={translate('Select...')}
            />
          </div>
        ))}
        <div className='userfilterModal__dropdown__container'>
          <StyledReactSelect
            options={options?.userType || []}
            customStyles={{width: 250}}
            label='Member Type'
            key={'userType'}
            onChange={(e, action) => onSelectUserType(e)}
            value={filters.userType || []}
            isClearable
            isMulti
            placeholder={translate('Select...')}
          />
        </div>
      </div>
    )
  }
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <ModalHeaderWithLabel label={translate('Filter By')} />

      <div className='filterModal__body'>{render()}</div>
      <div className='ModalFooter'>
        <button type='submit' className={'filterModal__cancelBtn '} onClick={onCancel}>
          {translate('Cancel')}
        </button>

        <button
          type='submit'
          className={
            customBtnClass
              ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
              : 'modalFooter__primaryBtn'
          }
          onClick={onApplyFilters}
        >
          {translate('Apply')}
        </button>
      </div>
    </Modal>
  )
}
export default UserFiltersModal
