/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'

import '@djthoms/pretty-checkbox'
import VerificationInput from 'react-verification-input'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {useHistory} from 'react-router-dom'
import {useVoterStyles} from '../../../../../../contexts/VoterStylesContext'
import {CustomBtn} from '../../../../../reusable/custom_btn/CustomBtn'
import {addPeriodToText} from '../../../../../common/utility_functions/TextUtility'

const SecurityQuestion = (props) => {
  let {view} = props
  const {translate} = useTranslation()
  const history = useHistory()

  const [codeError, setCodeError] = useState(null)
  const {customTxtClass} = useVoterStyles()
  const [notValid, setNotValid] = useState(true)

  const {code, setCode} = props
  useEffect(() => {
    // disabled button handler
    setCodeError('')
    if (code.length == 4) {
      setNotValid(false)
    }
  }, [code])

  return (
    <div>
      <div className='phoneVerify__form__body'>
        <div className='verifyMethod__formHeader'>
          <div className='verifyMethod__formHeaderTxtWrap'>
            <span className='confirmCode__formHeaderTxt'>
              {translate('Enter the last 4 digits of your')}{' '}
              <span
                className={customTxtClass ? customTxtClass : 'civik-primary-blue'}
                style={{fontWeight: 600}}
              >
                {addPeriodToText(translate('cell phone number'))}
              </span>{' '}
            </span>
          </div>
        </div>

        <div className='phoneVerify__inputsWrap'>
          <VerificationInput
            autoFocus={true}
            // inputProps={{autoComplete: 'one-time-code'}}
            value={code}
            onChange={setCode}
            validChars='0-9'
            length={4}
            classNames={{
              container: 'TextField__confirmCode__container',
              character: 'TextField__confirmCode--phoneVerify',
              characterInactive: 'TextField__confirmCode__inactive',
              characterSelected: 'TextField__confirmCode__selected__MEXICO',
            }}
          />

          {codeError && (
            <div className='voterRegister__singleInputErr confirmCode__errorWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate('Incorrect answer.')}
              </span>
            </div>
          )}
        </div>
      </div>
      {/*  */}

      <div className='phoneVerify__form__actionBar'>
        {view == 'Voter__MyProfile' && (
          <CustomBtn
            view='SecondaryBtn'
            text='Cancel'
            height={50}
            onClick={() => {
              history.push('/voting/voter/my-profile')
            }}
          />
        )}

        <CustomBtn
          view='ActionBtn'
          text='Next'
          height={50}
          disabled={notValid}
          onClick={props.onSubmit}
        />
      </div>
    </div>
  )
}

export default SecurityQuestion
