import {languageKeyToValue} from '../languages/languagekey'

/**
 * Using the browsers default language key,
 * returns the langugage value
 * @returns string - language value
 */
export const checkBrowserLanguagePreference = () => {
  try {
    // retreive the browser's default set language
    const browserLanguage = navigator.language

    // extract the first 2 characters (i.e. en-gb -> en)
    let browserLangKey = browserLanguage.substring(0, 2)

    // using the lang. key get the value (i.e. en -> English)
    let language = languageKeyToValue[browserLangKey]

    return language
  } catch (error) {
    console.log(error)
  }
}
