import React from 'react'
import styled from 'styled-components'
import {useTranslation} from '../../hooks/useTranslation'
import {EVENT_STATE_STATUS_LABEL, STATE_STATUSES} from '../../helperfunctions/GetEventStateStatus'
import themeVariables from '../../styles/_variables'

interface EventStateStatusProps {
  eventStatus: string
}

const StyledEventStateStatus = styled.span<{eventStatus: string}>`
  font-weight: 600;
  font-size: 18px;
  color: ${({eventStatus}) =>
    eventStatus === STATE_STATUSES.active
      ? themeVariables.successColor
      : eventStatus === STATE_STATUSES.closed
      ? themeVariables.errorColor
      : themeVariables.warningColor};
`

const EventStateStatus: React.FC<EventStateStatusProps> = ({eventStatus}) => {
  const {translate} = useTranslation()

  return (
    <StyledEventStateStatus eventStatus={eventStatus}>
      {translate(EVENT_STATE_STATUS_LABEL[eventStatus])}
    </StyledEventStateStatus>
  )
}

export default EventStateStatus
