import React, {Suspense} from 'react'
import {useTranslation} from '../../../../hooks/useTranslation'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import useCountdown from '../../../../hooks/useCountdown'
import EventCountdownTimeDateContainer from '../../../countdown_timer/CountdownTimerDateContainer'
import {isAdmin} from '../../../../pages/common/utility_functions/AuthUtility'
// Lazy load components
const EventStatus = React.lazy(() => import('../event_status/EventStatus'))
const CountdownTimerBig = React.lazy(() => import('../../../countdown_timer/CountdownTimerBig'))

// Import named export correctly
const LazyCustomBtn = React.lazy(() =>
  import('../../../../pages/reusable/custom_btn/CustomBtn').then((module) => ({
    default: module.CustomBtn,
  }))
)

export const AdminEvent = ({event, title, votingOpen, onSelectEvent}) => {
  const {translate, translateObj} = useTranslation()

  return (
    <div className='EventDisplayCard a-event'>
      <div className='a-event__body'>
        <div className='a-event__body__tc-n-icon'>
          <img
            className='a-event__body__icon'
            alt='ballot box icon'
            src={toAbsoluteUrl('/media/design/ballot-box.svg')}
          />

          <div className='a-event__body__tc'>
            <span
              className={`eventDisplayCard__body__content__title a-event__body__tc__title ${
                isAdmin() && 'a-event__body__tc__title--admin'
              }`}
            >
              {translateObj(title)}
            </span>

            <Suspense fallback={<div>Loading status...</div>}>
              <EventStatus
                hasVoted={false}
                votingOpen={votingOpen}
                eventState={event.state}
                customStyle={{
                  marginTop: 15,
                  marginBottom: 15,
                }}
              />{' '}
            </Suspense>

            <Suspense fallback={<div>Loading button...</div>}>
              <LazyCustomBtn
                view='ActionBtn'
                text={translate('Details')}
                height={37}
                onClick={() => {
                  onSelectEvent(event)
                }}
              />
            </Suspense>
          </div>
        </div>

        <Suspense fallback={<div>Loading countdown...</div>}>
          <EventCountdownTimeDateContainer event={event} votingOpen={votingOpen} />
        </Suspense>
      </div>
    </div>
  )
}
