import {useContext, useState} from 'react'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import {AdminSidebarMobile} from '../admin_sidebar_mobile/AdminSidebarMobile'
import './AdminNavbarMobile.scss'

import {useHistory} from 'react-router-dom'

import {useKeycloakSessionTime} from '../../../../hooks/useKeycloakSessionTime'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import {useKeycloakSession} from '../../../../hooks/useKeycloakSession'
import {useTranslation} from '../../../../hooks/useTranslation'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'

export function AdminNavbarMobile() {
  const {translate} = useTranslation()
  const history = useHistory()
  const [sideNavbarOpen, setSideNavbarOpen] = useState(false)
  // handles logging out redirect
  useKeycloakSession()
  const sessionTimeRemaining = useKeycloakSessionTime()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English'
          ? 'AdminNavbarMobile--spanish'
          : 'AdminNavbarMobile'
      }
    >
      <div className='adminNavMobile__contentWrap'>
        <img
          onClick={() => {
            setSideNavbarOpen(true)
          }}
          className='adminNavMobile__hamburgerMenu'
          src={toAbsoluteUrl('/media/design/icon_hamburger_menu.svg')}
          alt='menu'
        />{' '}
        {sessionTimeRemaining && !sessionTimeRemaining.includes('NaN') ? (
          <span className='adminTopbar__sessionTimeMobile'>
            <span className='adminTopbar__sessionTime__label'>
              {' '}
              {translate('Time left in session')}
              <LanguageSpecificColon />
            </span>
            <div className='adminTopbar__sessionTimeWrap'>
              <span className='adminTopbar__sessionTime__value'>
                {sessionTimeRemaining.substring(0, 1) !== '0'
                  ? sessionTimeRemaining.substring(0, 2)
                  : sessionTimeRemaining.substring(1, 2)}
              </span>
              <span className='adminTopbar__sessionTime__minTxt'>
                {sessionTimeRemaining.substring(2, 6)}
              </span>
              <span className='adminTopbar__sessionTime__value'>
                {sessionTimeRemaining.substring(6, 9)}
              </span>
              <span className='adminTopbar__sessionTime__secTxt'>
                {sessionTimeRemaining.substring(9, 13)}
              </span>
            </div>
          </span>
        ) : null}
      </div>
      <div className='common__mobileHide'>
        <AdminSidebarMobile sideNavbarOpen={sideNavbarOpen} setSideNavbarOpen={setSideNavbarOpen} />
      </div>
    </div>
  )
}
