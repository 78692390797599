import {useContext, useEffect, useState} from 'react'
import './AdminTopbar.scss'
import '../Common.scss'

import {LanguageContext} from '../../../../contexts/LanguageContext'

import {useKeycloakSessionTime} from '../../../../hooks/useKeycloakSessionTime'
import {useKeycloakSession} from '../../../../hooks/useKeycloakSession'
import {ModalWrapper} from '../../../voter/reusable/modals/modal_wrapper/ModalWrapper'
import {useSessionTimer} from '../../../../contexts/SessionTimerContext'
import {useTranslation} from '../../../../hooks/useTranslation'
import configOptions from '../../../../../utils/configOptions'
import LogoutButton from '../../../../components/logout_btn/LogoutBtn'
import Divider from '../../../../components/divider/Divider'
import LogoutTimer from '../../../../components/logout_timer/LogoutTimer'
import WelcomeMessage from './components/welcome_message/WelcomeMessage'
import LanguagesDropdown from '../../../../components/languages_dropdown/LanguagesDropdown'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'
import useCurrentTime from '../../../../hooks/useCurrentTime'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function AdminTopbar(props) {
  let {type} = props

  const {translate} = useTranslation()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const adminPage = window.location.pathname?.startsWith('/admin') ? true : false
  const {sessionEnding, setSessionEnding} = useSessionTimer()

  useKeycloakSession()
  const sessionTimeRemaining = useKeycloakSessionTime()

  const currentTime = useCurrentTime()

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English' ? 'AdminTopbar--spanish' : 'AdminTopbar'
      }
      id='Voter'
    >
      {adminPage && (
        <ModalWrapper
          isOpen={sessionEnding}
          onRequestClose={() => {
            setSessionEnding(false)
          }}
          style={customStyles}
          modalView={'ExtendSession'}
          headerLabel={translate('Session Time')}
          sessionTimeRemaining={sessionTimeRemaining}
        />
      )}
      <span className='adminTopbar__currentTimeTxt'>
        {translate(configOptions.currentTimeInString)}
        <LanguageSpecificColon />
        <span className='common__fw700'> {currentTime}</span>
      </span>

      <div className='adminTopbar__btnWrap'>
        <LogoutTimer sessionTimeRemaining={sessionTimeRemaining} />
        <Divider spacingLeft='20px' spacingRight='20px' />

        <LanguagesDropdown />

        <Divider spacingLeft='20px' spacingRight='20px' />
        <WelcomeMessage />
        <Divider spacingLeft='20px' spacingRight='20px' />

        <LogoutButton />
      </div>
    </div>
  )
}
