import React, {useContext, useState} from 'react'
import Select from 'react-select'
import {LanguageContext} from '../../contexts/LanguageContext'
import {useTranslation} from '../../hooks/useTranslation'
import './CustomDropdown.scss'

const ReactSelectView = (props) => {
  const {translate} = useTranslation()
  return (
    <Select
      className='customDropdown--react-select'
      styles={props.customStyles}
      noOptionsMessage={() => translate('No options')}
      {...props}
    />
  )
}

const CustomDropdown2 = (props) => {
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const {translate} = useTranslation()

  const [openSelect, setOpenSelect] = useState(false)
  const {customStyles, label} = props

  const toggleDropdown = () => {
    setOpenSelect(!openSelect)
  }

  const selectStyles = {
    control: (base) => ({
      ...base,
      ...customStyles,
    }),
    // Additional custom styles if necessary
  }

  return (
    <div className='CustomDropdownContainer' style={customStyles}>
      <span className={'customDropdownContainer__label'}>{translate(label)}</span>

      <ReactSelectView {...props} />
    </div>
  )
}

export default CustomDropdown2
