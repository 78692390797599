import React, {useState, useEffect, useContext, Suspense} from 'react'
import {useTranslation} from '../../../../hooks/useTranslation'
import {VoterUserContext} from '../../../../contexts/VoterUserContext'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import LanguageSpecificColon from '../../../language_specific_colon/LanguageSpecificColon'
import EventCountdownTimeDateContainer from '../../../countdown_timer/CountdownTimerDateContainer'

const EventStatus = React.lazy(() => import('../event_status/EventStatus'))
const EventDescription = React.lazy(() => import('./components/EventDescription'))
const EventFooter = React.lazy(() => import('./components/EventFooter'))

export const VoterEvent = ({title, event, closeDate, votingOpen}) => {
  const {translate, translateObj} = useTranslation()
  const VoterUser = useContext(VoterUserContext)
  const hasVoted = VoterUser.user?.votedInEvents?.includes(event?.tiviId)

  return (
    <div className='EventDisplayCard'>
      <div className='eventDisplayCard__body'>
        <img alt='ballot box icon' src={toAbsoluteUrl('/media/design/ballot-box.svg')} />

        <div className='eventDisplayCard__body__content'>
          <span className='eventDisplayCard__body__content__label'>
            {translate('You are entitled to vote in')}
            <LanguageSpecificColon />
          </span>

          <span className='eventDisplayCard__body__content__title'>{translateObj(title)}</span>

          {event && event.description && (
            <Suspense fallback={<div>Loading description...</div>}>
              <EventDescription description={event.description} showOneLine />
            </Suspense>
          )}

          {event && (
            <Suspense fallback={<div>Loading...</div>}>
              <EventStatus votingOpen={votingOpen} eventState={event.state} hasVoted={hasVoted} />
              <EventCountdownTimeDateContainer votingOpen={votingOpen} event={event} size='small' />
            </Suspense>
          )}
        </div>
      </div>

      {event && (
        <Suspense fallback={<div>Loading footer...</div>}>
          <EventFooter event={event} hasVoted={hasVoted} votingOpen={votingOpen} />
        </Suspense>
      )}
    </div>
  )
}
