/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import './EventInfo.scss'
import AdminVoterService from '../../../../services/admin/AdminVoterService'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import {useTranslation} from '../../../../hooks/useTranslation'
import {formatDateLocale} from '../../../../languages/datetimeformats'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import configOptions from '../../../../../utils/configOptions'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'

export function EventInfo(props) {
  const {translate, translateObj} = useTranslation()

  const {event} = props

  // note: used within multiple functions and calls to understand which langugage is currently selected
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const [downloadURL, setDownloadURL] = useState(null)
  const [downloading, setDownloading] = useState(false)

  if (!event) {
    return null
  }

  const getVoteReceiptReport = async () => {
    setDownloading(true)
    const res = await AdminVoterService.getVoteReceiptReport(
      event.tiviId,
      LANGUAGE_CONTEXT.currentLanguage
    )
    if (res?.success) {
      // @ts-ignore
      const href = URL.createObjectURL(res.data)
      setDownloadURL(href)
      const link = document.createElement('a')
      link.download = `Receipt-Report-${event.tiviId}`
      link.href = href
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setDownloading(false)
  }

  return (
    <div className='EventInfo'>
      {/* TIVI Event Id */}
      <div className='eventInfo__desc'>
        <span className='eventInfo__desc__label'>
          {translate('Identifier')}
          <LanguageSpecificColon />
        </span>
        <span className='eventInfo__desc__value'>{event.tiviId}</span>
      </div>

      {/* DESCRIPTION */}
      <div className='eventInfo__desc'>
        <span className='eventInfo__desc__label'>
          {translate('Description')}
          <LanguageSpecificColon />
        </span>
        <span className='eventInfo__desc__value'>
          {event.description && translateObj(event.description)}
        </span>
      </div>

      {/* TIME */}
      <div className='eventInfo__time'>
        <img
          alt='clock icon'
          className='eventInfo__time__icon'
          src={toAbsoluteUrl('/media/design/icon_clock_pink.svg')}
        />

        <div className='eventInfo__timeWrap'>
          <div className='eventInfo__time__opening'>
            <div className='eventInfo__time__txtWrap'>
              <span className='eventInfo__time__label'>
                {translate('Opening Time')}
                <LanguageSpecificColon />
              </span>
              <span className='eventInfo__time__value'>
                {' '}
                {formatDateLocale(event.openDate, LANGUAGE_CONTEXT)}
              </span>{' '}
            </div>
          </div>

          <div className='eventInfo__time__closing'>
            <div className='eventInfo__time__txtWrap'>
              <span className='eventInfo__time__label'>
                {translate('Closing Time')}
                <LanguageSpecificColon />
              </span>
              <span className='eventInfo__time__value'>
                {' '}
                {formatDateLocale(event.closeDate, LANGUAGE_CONTEXT)}
              </span>{' '}
            </div>
          </div>
        </div>
      </div>

      {/* Vote Receipt Report */}
      {configOptions.voteReceiptEnabled && (
        <div className='eventInfo__time eventInfo__district eventInfo__downloadBtnWrap'>
          {!event.multipleVotesEnabled && (
            <div>
              {downloading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                <button className='btn__downLoadList' onClick={getVoteReceiptReport}>
                  {' '}
                  {translate('Download Vote Receipt Report')}
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
