import {NUM_VOTERS_PER_PAGE} from './ElectorsListTable'
import {useTranslation} from '../../../../../hooks/useTranslation'
import ReactPaginate from 'react-paginate'
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi'

export const ElectorsListFooter = ({currentPageNum, numUsers, setCurrentPageNum}) => {
  const {translate} = useTranslation()

  return (
    <div className='voterAddrList__footer'>
      <span className='voterAddrList__footer__txt'>
        {translate('Showing')}{' '}
        <span className='voterAddrList__footer__txt__value'>
          {Math.min(currentPageNum * NUM_VOTERS_PER_PAGE + 1, numUsers)}
        </span>{' '}
        {translate('to')}{' '}
        <span className='voterAddrList__footer__txt__value'>
          {Math.min(currentPageNum * NUM_VOTERS_PER_PAGE + NUM_VOTERS_PER_PAGE, numUsers)}
        </span>{' '}
        {translate('of')} <span className='voterAddrList__footer__txt__value'>{numUsers}</span>{' '}
        {translate('electors')}
      </span>

      <ReactPaginate
        breakLabel='...'
        nextLabel={<FiChevronRight color='#000' size={18} />}
        onPageChange={(event) => {
          setCurrentPageNum(event.selected)
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(numUsers / NUM_VOTERS_PER_PAGE)}
        previousLabel={<FiChevronLeft color='#000' size={18} />}
        renderOnZeroPageCount={null}
        containerClassName='pagination-container pagination-bar'
        pageClassName='pagination-item'
        activeClassName='selected'
        previousClassName='voterAddrList__footer__pagination__chevron--left'
        nextClassName='voterAddrList__footer__pagination__chevron--right'
        breakClassName='pagination-item dots'
        forcePage={currentPageNum}
      />
    </div>
  )
}
export default ElectorsListFooter
