// paths reused often
const ROUTE_PATHS = {
  HOME: '/',
  LANDING: '/voting/voter-auth/landing-page',
  REGISTRATION_START: '/voting/voter-auth/register',
  REGISTRATION_VERIFY: '/voting/voter-auth/register-complete',
  LOGIN: '/voting/voter-login',
  // jumio
  // REGISTRATION_START: '/voting/voter-auth/register-start',

  /// old civik
  FEED: '/feed',
  // LOGIN: '/auth/login',
  MY_PROFILE: '/myprofile',
  VERIFY: '/auth/verify',
  PUBLIC_PROFILE: '/u/:handle',
  POLITICIAN_PROFILE: '/p/:handle',
  COUNCILLOR_PROFILE_START: '/auth/councilor-profile',
  COUNCILOR_SIGN_UP_PAGES: {
    1: '/auth/councilor-profile',
    2: '/auth/councilor-about',
    3: '/auth/councilor-staff',
    4: '/auth/councilor-boards',
    5: '/auth/councilor-improve-areas',
  },
}

export default ROUTE_PATHS
