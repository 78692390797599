/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

// import React, {FC} from 'react'
import {useState, useEffect, useContext} from 'react'
import {Switch, Route, useHistory, Redirect, useLocation} from 'react-router-dom'
import {VoterRegRoutes} from './VoterRegRoutes'
import {SuperAdminRoutes} from './SuperAdminRoutes'
import {SuperAdminUserProvider} from '../contexts/SuperAdminUserContext'
import {LanguageContext} from '../contexts/LanguageContext'
import {VoterStylesProvider} from '../contexts/VoterStylesContext'
import {SessionTimerProvider} from '../contexts/SessionTimerContext'
import KeycloakAdminService from '../../KeycloakAdminService'
import KeycloakService from '../../Keycloak'

const Routes = () => {
  const savedLanguage = localStorage.getItem('language')
  const [currentLanguage, setCurrentLangauge] = useState(savedLanguage || 'English')
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (KeycloakService?.isLoggedIn() && location.pathname.startsWith('/voting/voter-')) {
      history.replace('/voting/voter/dashboard')
    } else if (
      KeycloakAdminService?.isLoggedIn() &&
      (location.pathname.startsWith('/admin/auth') || location.pathname.startsWith('/admin/login'))
    ) {
      if (KeycloakAdminService?.hasRole('tivi-contactinfo')) {
        history.replace('/admin/electors-list')
      } else {
        history.replace('/admin/dashboard')
      }
    }
  }, [location, history])
  return (
    <div style={{maxWidth: '100%'}}>
      <LanguageContext.Provider value={{currentLanguage, setCurrentLangauge}}>
        <SuperAdminUserProvider>
          <VoterStylesProvider>
            <SessionTimerProvider>
              {/* NOTE: routing pattern swith -> route -> switch -> route never switch -> switch */}
              <Switch>
                <Route path='/admin'>
                  <SuperAdminRoutes />
                </Route>
                <VoterRegRoutes />

                {/* <Route path='/voting/*' component={VoterRegister}> */}
                {/* </Route> */}
                {/* <Route path='/admin/*' component={MEXICO__AdminLogin}> */}
                {/* <Redirect from='*' to='/voting/voter-login/' /> */}
                {/* </Route> */}
              </Switch>
            </SessionTimerProvider>
          </VoterStylesProvider>
        </SuperAdminUserProvider>
      </LanguageContext.Provider>
    </div>
  )
}
export {Routes}
