/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import * as Yup from 'yup'
import './pagination.scss'
import './EligibleElectorsList.scss'

import TextField from '../../../../../modules/auth/reusable/TextField'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
// import {FilterModal} from './FilterModal'
import {ModalWrapper} from '../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'

import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {EmailSendModal} from './EmailSendModal'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'

import {EditUserModal} from './edit_user_drodown/EditUserModal'
import ReactPaginate from 'react-paginate'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {districts} from '../../../../../portalData/Districts'

import '../../../../super_admin/mexico/pages/admin_users/MEXICO__SuperAdmin__Users.scss'
import '../../nova_scotia/address_list/AdminAddressList.scss'
import '../../nova_scotia/electors_list/AdminElectorsList.scss'
import {ViewIdModal} from './view_id_modal/ViewIdModal'
import {useHistory} from 'react-router-dom'
import {VoterDecisionModal} from './voter_decision_modal/VoterDecisionModal'
import {useFormik} from 'formik'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi'

const NUM_VOTERS_PER_PAGE = 20
export const initialValues = {}

export const loginSchema = Yup.object().shape({})

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}
export function EligibleElectorsList(props) {
  const {translate, translateObj} = useTranslation()

  const history = useHistory()

  const {currentLanguage} = useContext(LanguageContext)

  const [filterModal, setFilterModal] = useState(false)
  const [emailFilterModal, setEmailFilterModal] = useState(false)
  const [thanksModal, setThanksModal] = useState(false)
  const [downloadURL, setDownloadURL] = useState(null)
  const [downloadingList, setDownloadingList] = useState(false)
  const [districtFilter, setDistrictFilter] = useState('all')
  const [channelFilter, setChannelFilter] = useState('all')
  const [users, setUsers] = useState([])
  // total number of users
  const [numUsers, setNumUsers] = useState(0)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('all')
  const [currentPageNum, setCurrentPageNum] = useState(0)
  const [onlineEvents, setOnlineEvents] = useState([])
  const [editUserModal, setEditUserModal] = useState(false)
  const [userToEdit, setUserToEdit] = useState({email: ''})
  const [voterToEditId, setVoterToEditId] = useState(null)
  const [userToView, setUserToView] = useState({voterInfo: {identification: []}})
  const [editUserModalView, setEditUserModalView] = useState('Edit')
  const [decisionModalOpen, setDecisionModalOpen] = useState(false)
  const [decisionModalView, setDecisionModalView] = useState('decline')
  const [isViewIdModalOpen, setIsViewIdModalOpen] = useState(false)

  // const [fileToView, setFileToView] = useState(null)
  const [pdfViewURL, setPdfViewURL] = useState(null)

  const viewOnly =
    !KeycloakAdminService.hasRole('tivi-root-admin') &&
    !KeycloakAdminService.hasRole('tivi-event-admin')

  const getDisplayAdress = (address) => {
    if (address.formattedAddress) {
      return address.formattedAddress
    }
    if (address.streetAddress?.includes(',')) {
      return address.streetAddress
    }
    return `${address.unitNumberPOBox ? address.unitNumberPOBox + '-' : ''}${
      address.streetAddress
    }, ${address.city}, ${address.province}, ${address.postalCode}`
  }

  const getUsers = async () => {
    let resp = await AdminVoterService.fetchEligibleVoters(
      districtFilter,
      NUM_VOTERS_PER_PAGE,
      currentPageNum * NUM_VOTERS_PER_PAGE,
      search
    )
    if (resp?.users) {
      console.log('resp?.users', resp?.users)
      //@ts-ignore
      setUsers(resp.users)
      setNumUsers(resp.numUsers)
    }
  }
  useEffect(() => {
    setCurrentPageNum(0)
    getUsers()
  }, [districtFilter, channelFilter, search, status])

  useEffect(() => {
    getUsers()
  }, [currentPageNum])

  const dummyFormik = useFormik({
    initialValues: {},
    validationSchema: {},
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(async () => {
        try {
        } catch (error) {
          console.log(error)
        }
      }, 1000)
    },
  })
  const getDowloadUrl = async () => {
    const res = await AdminVoterService.fetchEligibleVotersCsv()
    if (res?.success) {
      // @ts-ignore
      const href = URL.createObjectURL(res.data)
      setDownloadURL(href)
      const link = document.createElement('a')
      link.download = `voterlist`
      link.href = href
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setDownloadingList(false)
  }

  // get online events for email
  useEffect(() => {
    const getOnlineEvents = async () => {
      const res = await AdminVoterService.getOnlineEventsList(districtFilter)
      if (res) {
        //@ts-ignore
        let events = res.map((e) => {
          return {...e, value: e.tiviId, label: translateObj(e.name)}
        })
        setOnlineEvents(events)
      }
    }
    getOnlineEvents()
  }, [districtFilter, channelFilter])

  const sendEmailsOnlineDistrict = async (tivId) => {
    const res = await AdminVoterService.sendEmailsOnlineVoters(tivId)
    if (res.success) {
      setEmailFilterModal(false)
      alert(translate('Success'))
    } else {
      alert('Error')
    }
  }

  // const handleApprove = async ({voter}) => {
  //   setVoterToEditId(voter.voterInfo?._id)
  //   await AdminVoterService.approveVoter(voter.voterInfo?._id)
  //   getUsers()
  //   // Show approval modal
  //   setDecisionModalView('approve')
  //   setDecisionModalOpen(true)
  // }
  const handleDecline = async ({voter}) => {
    setVoterToEditId(voter.voterInfo?._id)

    // Show approval modal
    setDecisionModalView('decline')
    setDecisionModalOpen(true)
  }

  const onViewPDF = async ({url}) => {
    const encodedPDFURL = encodeURIComponent(url)
    history.push(`/admin/view-pdf/${encodedPDFURL}`)
  }

  function hasPdfExtension(filename) {
    return filename.toLowerCase().endsWith('.pdf')
  }

  return (
    <div id='Voter'>
      {/* MODAL */}
      {/* <PropertyInfoModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      /> */}
      {/* <VoterInfoModal
        isOpen={voterInfoModal}
        onAfterOpen={afterOpenVoterModal}
        onRequestClose={() => {
          setVoterInfoModal(false)
        }}
        style={customStyles}
      /> */}

      <VoterDecisionModal
        onModalOpen={decisionModalOpen}
        onModalClose={() => {
          setDecisionModalOpen(false)
        }}
        modalView={decisionModalView}
        setModalView={setDecisionModalView}
        voterId={voterToEditId}
        onGetUsers={() => {
          getUsers()
        }}
      />

      <ViewIdModal
        modalOpen={isViewIdModalOpen}
        onClose={() => {
          setIsViewIdModalOpen(false)
          setUserToView({voterInfo: {identification: []}})
        }}
        identification={userToView.voterInfo?.identification}
      />
      <EditUserModal
        isOpen={editUserModal}
        onRequestClose={() => {
          setEditUserModal(false)
        }}
        style={customStyles}
        modalView={editUserModalView}
        userToEdit={userToEdit}
      />
      <ModalWrapper
        isOpen={thanksModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setThanksModal(false)
        }}
        style={customStyles}
        modalView={downloadingList ? 'Downloading' : 'Downloaded'}
        headerLabel={translate('Export Electors List')}
        downloadURL={downloadURL}
        districtFilter={districtFilter}
        channelFilter={channelFilter}
      />
      {/* <FilterModal
        isOpen={filterModal}
        // onAfterOpen={after}
        onRequestClose={() => {
          setFilterModal(false)
        }}
        style={customStyles}
        onUpdateFilter={(district, channel, status) => {
          setDistrictFilter(district)
          setChannelFilter(channel)
          setStatus(status)
        }}
        districtFilter={districtFilter}
        channelFilter={channelFilter}
        statusFilter={status}
      /> */}
      <EmailSendModal
        isOpen={emailFilterModal}
        // onAfterOpen={after}
        onRequestClose={() => {
          setEmailFilterModal(false)
        }}
        style={customStyles}
        onSend={sendEmailsOnlineDistrict}
        options={onlineEvents}
      />
      {/* CONSOLE */}
      <div className='voterAddrList__tableContanier'>
        {/* TABLE */}
        <div className='voterAddrList__table'>
          <div className='voterAddrList__table__actionBar'>
            <TextField
              formik={dummyFormik}
              type='text'
              field='search'
              placeholder=''
              label=''
              isRequired={false}
              noLabel={true}
              view='admin_searchbar-no-formik'
              customStyling={{width: 230, marginRight: 25}}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              // TODO
              value={search}
            />{' '}
            <div />
            <div>
              {/* <img
                onClick={() => {
                  setFilterModal(true)
                }}
                className='voterAddrList__table__actionBar__filterIcon'
                src={toAbsoluteUrl('/media/design/icon_funnel.svg')}
                alt='filter'
              /> */}

              <img
                onClick={() => {
                  setDownloadingList(true)
                  setThanksModal(true)
                  getDowloadUrl()
                }}
                className='voterAddrList__table__actionBar__downloadIcon'
                src={toAbsoluteUrl('/media/design/icon_file_download.svg')}
                alt='download'
              />

              {/* {!viewOnly && (
                <img
                  onClick={() => {
                    setEmailFilterModal(true)
                  }}
                  className='voterAddrList__table__actionBar__downloadIcon'
                  src={toAbsoluteUrl('/media/design/icon_email.svg')}
                  alt='email'
                />
              )} */}
            </div>
          </div>

          <div
            className={`voterAddrList__tableHeader ${
              currentLanguage == 'French' && 'eligibleElectorsList__tableHeader--french'
            }`}
          >
            <span
              className='table-header-label eligibleElectorsList__columnLabel--id'
              style={{
                width: currentLanguage == 'French' ? '15%' : '7%',
                maxWidth: currentLanguage == 'French' ? '15%' : '7%',
              }}
            >
              {translate('Elector Id')}
            </span>
            <span className='table-header-label eligibleElectorsList__columnLabel--firstName'>
              {translate('First Name')}
            </span>
            <span
              className='table-header-label eligibleElectorsList__columnLabel--middleName'
              style={{
                width: currentLanguage == 'French' ? '15%' : '9%',
                maxWidth: currentLanguage == 'French' ? '15%' : '9%',
              }}
            >
              {translate('Middle Name')}
            </span>
            <span
              className='table-header-label eligibleElectorsList__columnLabel--lastName'
              style={{
                marginLeft: currentLanguage == 'French' ? -20 : '0px',
                width: currentLanguage == 'French' ? '12%' : '9%',
                maxWidth: currentLanguage == 'French' ? '12%' : '9%',
              }}
            >
              {translate('Last Name')}
            </span>
            <span className='table-header-label eligibleElectorsList__columnLabel--district'>
              {/* {translate('Phone')} */}
              {translate('District')}
            </span>

            <span className='table-header-label eligibleElectorsList__columnLabel--addr'>
              {translate('Address')}
            </span>

            {/* <span className='table-header-label electorListTable__columnLabel--6'>
              {translate('Channel')}
            </span> */}

            <span className='table-header-label eligibleElectorsList__columnLabel--channel'>
              {translate('Registered By')}
            </span>

            <span className='table-header-label eligibleElectorsList__columnLabel--voted'>
              {translate('Voted')}
            </span>
            <span className='table-header-label eligibleElectorsList__columnLabel--dob'>
              {translate('DOB')}
            </span>

            {/* <span className='table-header-label electorListTable__columnLabel--7'>
              Status
            </span> */}
            {/* {(KeycloakAdminService.hasRole('tivi-root-admin') ||
              KeycloakAdminService.hasRole('tivi-event-admin') ||
              KeycloakAdminService.hasRole('tivi-contactinfo')) && (
              <span className='table-header-label electorListTable__columnLabel--8'>
                {translate('Action')}
              </span>
            )} */}
          </div>

          <div className='voterAddrList__tableBody'>
            {users.map((user) => (
              <div className='voterAddrList__tableBody__row'>
                {/* <span
                  className='electorListTable__rowItem--1 '
                  onClick={() => {
                    setVoterInfoModal(true)
                  }}
                >
                  123455
                </span> */}
                <span
                  className='eligibleElectorsList__rowItem--id'
                  style={{
                    width: currentLanguage == 'French' ? '15%' : '9%',
                    maxWidth: currentLanguage == 'French' ? '15%' : '9%',
                  }}
                >
                  {user.electorId}
                </span>
                <span className='eligibleElectorsList__rowItem--firstName'>{user.firstName}</span>
                <span
                  className='eligibleElectorsList__rowItem--middleName'
                  style={{
                    width: currentLanguage == 'French' ? '15%' : '9%',
                    maxWidth: currentLanguage == 'French' ? '15%' : '9%',
                  }}
                >
                  {user.middleName}
                </span>
                <span className='eligibleElectorsList__rowItem--lastName'>{user.lastName}</span>
                <span className='eligibleElectorsList__rowItem--district'>
                  {' '}
                  {translate(
                    districts.find((o) => o.value === user.district)?.label || user.district
                  )}
                </span>

                <span className='eligibleElectorsList__rowItem--addr'>
                  {getDisplayAdress(user.address)}
                </span>

                <span className='eligibleElectorsList__rowItem--channel'>
                  <span
                    className={
                      user.registered && user.votingChannel
                        ? `electorsListTable__rowValue__channel electorsListTable__rowValue__channel--${user?.votingChannel}`
                        : user.registered
                        ? `electorsListTable__rowValue__channel electorsListTable__rowValue__channel--online`
                        : ''
                    }
                  >
                    {user.registered && user.votingChannel
                      ? translate(user.votingChannel)
                      : user.registered
                      ? translate('online')
                      : ''}
                  </span>
                </span>
                <span className='eligibleElectorsList__rowItem--voted'>
                  <span
                    className={`electorsListTable__rowValue__voted electorsListTable__rowValue__voted--${
                      user.disabled && user.votingChannel === 'online'
                        ? 'disabled'
                        : user.voted
                        ? 'voted'
                        : ''
                    }`}
                  >
                    {user.disabled && user.votingChannel === 'online'
                      ? translate('Disabled')
                      : user.voted
                      ? translate('Voted')
                      : ''}
                  </span>
                </span>
                <span className='eligibleElectorsList__rowItem--dob'>{user.dateOfBirth}</span>
                {/* <div className='electorListTable__rowItem--7'>
                  <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
                    Voted
                  </span>
                </div> */}

                {/* {(KeycloakAdminService.hasRole('tivi-root-admin') ||
                  KeycloakAdminService.hasRole('tivi-event-admin') ||
                  KeycloakAdminService.hasRole('tivi-contactinfo')) && (
                  <div className='column-width-5 column-width-5--adminUserDropdown'>
                    <AdminDropdown
                      view='electorDecision'
                      user={user}
                      onCallback={(value) => {
                        if (value == 'Approve') {
                          handleApprove({voter: user})
                        }

                        if (value == 'Decline') {
                          handleDecline({voter: user})
                        }

                        if (value == 'Edit') {
                          setUserToEdit(user)
                          setEditUserModalView(value)
                          setEditUserModal(true)
                        }

                        if (value == 'View ID') {
                          console.log('user to view', user)

                          let photoIdURL = user.voterInfo?.identification[0]

                          if (hasPdfExtension(photoIdURL)) {
                            onViewPDF({url: photoIdURL})
                          } else {
                            setUserToView(user)
                            setIsViewIdModalOpen(true)
                          }
                        }

                        if (value == 'View') {
                          if (!user?.voterInfo?._id) {
                            alert('User not viewable')
                          } else {
                            setUserToView(user)
                            history.push(`/admin/voters/${user?.voterInfo?._id}`)
                          }
                        }
                      }}
                    />{' '} 
                  </div>
                )}*/}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* footer */}
      <div className='voterAddrList__footer'>
        <span className='voterAddrList__footer__txt'>
          {translate('Showing')}{' '}
          <span className='voterAddrList__footer__txt__value'>
            {Math.min(currentPageNum * NUM_VOTERS_PER_PAGE + 1, numUsers)}
          </span>{' '}
          {translate('to')}{' '}
          <span className='voterAddrList__footer__txt__value'>
            {Math.min(currentPageNum * NUM_VOTERS_PER_PAGE + NUM_VOTERS_PER_PAGE, numUsers)}
          </span>{' '}
          {translate('of')} <span className='voterAddrList__footer__txt__value'>{numUsers}</span>{' '}
          {translate('electors')}
        </span>

        <ReactPaginate
          breakLabel='...'
          nextLabel={<FiChevronRight color='#000' size={18} />}
          onPageChange={(event) => {
            setCurrentPageNum(event.selected)
          }}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={Math.ceil(numUsers / NUM_VOTERS_PER_PAGE)}
          previousLabel={<FiChevronLeft color='#000' size={18} />}
          renderOnZeroPageCount={null}
          containerClassName='pagination-container pagination-bar'
          pageClassName='pagination-item'
          activeClassName='selected'
          previousClassName='voterAddrList__footer__pagination__chevron--left'
          nextClassName='voterAddrList__footer__pagination__chevron--right'
          breakClassName='pagination-item dots'
          forcePage={currentPageNum}
        />
      </div>
    </div>
  )
}

export default EligibleElectorsList
