/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import {useHistory} from 'react-router-dom'
import {VoterUserContext} from '../../../../contexts/VoterUserContext'

import './MEXICO__MyProfile.scss'
import '../dashboard/MEXICO__Dashboard.scss'
import '../common.scss'

import {ModalWrapper} from '../../../voter/reusable/modals/modal_wrapper/ModalWrapper'
import {useTranslation} from '../../../../hooks/useTranslation'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'
import {addPeriodToText} from '../../../common/utility_functions/TextUtility'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function MEXICO__MyProfile(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const VoterUser = useContext(VoterUserContext)

  const [changeQRSuccess, setChangeQRSuccess] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [modalView, setModalView] = useState('ChangePasswordSuccess')
  const [modalHeader, setModalHeader] = useState('Change Password')

  useEffect(() => {
    // if qr code change was successful
    if (
      props.history.location.state &&
      props.history.location.state.status == 'verificationSuccess--changePassword'
    ) {
      setModalHeader('Change Password')
      setModalView('ChangePasswordSuccess')
      setOpenSuccessModal(true)
    }

    if (
      props.history.location.state &&
      props.history.location.state.status == 'verificationSuccess--qrCodeChange'
    ) {
      setModalHeader('Setup/Change QR Code')
      setModalView('QRCodeChangeSuccess')
      setOpenSuccessModal(true)
    }
  }, [])

  return (
    <div className='MEXICO__MyProfile '>
      <ModalWrapper
        isOpen={openSuccessModal}
        onRequestClose={() => {
          setOpenSuccessModal(false)
        }}
        style={customStyles}
        modalView={modalView}
        headerLabel={translate(modalHeader)}
      />

      <span className='myProfiletM__title'> {translate('My Account')}</span>

      <div className='common__pg__header'>
        <span className='common__pg__header__title'>{translate('My Account')}</span>
      </div>

      <div className='myProfile__body'>
        <div className='myProfile__body__details'>
          {/* USERNAME SECTION */}
          <div className='myProfile__body__details__usernameSection'>
            <span className='myProfile__body__details__txt__label'>
              {translate('Membership Number')}:
            </span>
            <span className='myProfile__body__details__txt__value'>
              {VoterUser?.user?.username}
            </span>
          </div>
          {/* PASSWORD SECTION */}
          <div className='myProfile__body__details__passwordSection'>
            <span className='myProfile__body__details__txt__label'>{translate('Password')}</span>
            <div className='myProfile__body__details__actionLinkWrap'>
              <span className='myProfile__body__details__txt__value'>******</span>
              <span
                className='myProfile__body__details__actionLink'
                onClick={() => {
                  history.push('/voting/voter/change-password')
                }}
              >
                {translate('Change')}
              </span>{' '}
            </div>
          </div>
          {/* REGISTERED PHONE NUMBER */}
          <div className='myProfile__body__details__passwordSection'>
            <span className='myProfile__body__details__txt__label'>
              {translate('Registered Phone number')}
              <LanguageSpecificColon />
            </span>
            <span className='myProfile__body__details__txt__value'>
              {VoterUser?.user?.phoneNumber}
            </span>
          </div>
          {/* AUTHENTICATION METHOD */}
          {/* <div className='myProfile__body__details__authMethodSection'>
            <div className='myProfile__body__details__txtIconWrap'>
              <span className='myProfile__body__details__txt__label'>Authentication method</span>
              <img
                className='myProfile__body__details__infoIcon'
                src={toAbsoluteUrl('/media/design/ine_pink_info_icon.svg')}
                alt='info icon'
              />
            </div>
            <div className='myProfile__body__details__actionLinkWrap'>
              <span className='myProfile__body__details__txt__value'>
                SMS code sent to my phone{' '}
              </span>
              <span className='myProfile__body__details__actionLink'>Change</span>{' '}
            </div>
          </div> */}
          {/* QR CODE */}
          <div className='myProfile__body__details__qrCodeSection'>
            <div className='myProfile__body__details__qrCode__txtWrap'>
              <span
                className='myProfile__body__details__txt__value myProfile__body__details__qrCode__label myProfile__body__details__actionLink'
                onClick={() => history.push('/voting/voter/regen-qr-code')}
              >
                {translate('Setup/Change QR Code')}
              </span>

              <div className='myProfile__body__details__qrCode__txtBanner'>
                <img
                  className='myProfile__body__details__qrCode__txtBanner__icon'
                  src={toAbsoluteUrl('/media/design/icon_qrCode_scan_pink.svg')}
                  alt=''
                />
                <span className='myProfile__body__details__txt__label '>
                  {addPeriodToText(
                    translate(
                      'You will need to scan this QR code with any available authentication app'
                    )
                  )}
                </span>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
