/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import Modal from 'react-modal'

import './ModalWrapper.scss'
import '../VoterAdminModals.scss'

import {ModalHeader} from '../modal_header/ModalHeader'
import {ModalFooter} from '../modal_footer/ModalFooter'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'

import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {ModalBtn} from '../../../../modals/voter_modal/ModalBtn'
import {useSessionTimer} from '../../../../../contexts/SessionTimerContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {registerLocale} from 'react-datepicker'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import {languageValueToKey} from '../../../../../languages/languagekey'
import {useTranslation} from '../../../../../hooks/useTranslation'
import ModalFooterWrapper from '../../../../../resuable/modal_footer_wrapper/ModalFooterWrapper'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import LanguageSpecificColon from '../../../../../components/language_specific_colon/LanguageSpecificColon'
import {addPeriodToText} from '../../../../common/utility_functions/TextUtility'
registerLocale('es', es)
registerLocale('fr', fr)

// NOTE: this is not a modal wrapper, a wrapper would accept chidlren props. this file has many things combined that shouldn't be

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}

// TODO: break this file into seperate files and components

export function ModalWrapper(props) {
  const {translate} = useTranslation()

  const {modalView, headerLabel, onClick} = props

  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const {customBtnClass, customTxtClass} = useVoterStyles()
  const {doExtendSession, endSession} = useSessionTimer()
  const [downloadReportStartDate, setDownloadReportStartDate] = useState(null)
  const [downloadReportEndDate, setDownloadReportEndDate] = useState(null)

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  const UpdateAddressList = (
    <>
      {' '}
      <div className='voterAdminModals__thankYou'>
        <img
          className='voterAdminModals__thankYou__img'
          src={toAbsoluteUrl('/media/design/thumbs_up_green_icon.svg')}
        />

        <span className='voterAdminModals__thankYou__label'>{translate('Thank You!')}</span>
        <span className='voterAdminModals__thankYou__subtitle'>
          {translate('Electors list database has been updated successfully')}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const ChangePasswordSuccess = (
    <>
      <div className='changePasswordSuccess'>
        <img
          alt='circle check icon'
          className='changePasswordSuccess__icon'
          src={toAbsoluteUrl('/media/design/icon_check_circle_neon.svg')}
        />

        <span className='changePasswordSuccess__txt'>
          {addPeriodToText(translate('Password has been changed successfully'))}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const QRCodeChangeSuccess = (
    <>
      <div className='QRCodeChangeSuccess'>
        <img
          alt='circle check icon'
          className='QRCodeChangeSuccess__icon'
          src={toAbsoluteUrl('/media/design/icon_check_circle_neon.svg')}
        />

        <span className='QRCodeChangeSuccess__txt'>
          {translate('New QR code has been generated successfully')}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const SecurityDisclaimer = (
    <>
      {' '}
      <div className='voterRegModals__securityDisclaimer'>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='voterRegModals__securityDisc__icon'
        >
          <path
            d='M5 14.3375V7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H26C26.2652 6 26.5196 6.10536 26.7071 6.29289C26.8946 6.48043 27 6.73478 27 7V14.3375C27 24.8375 18.0875 28.3125 16.3125 28.9C16.1109 28.9747 15.8891 28.9747 15.6875 28.9C13.9125 28.3125 5 24.8375 5 14.3375Z'
            stroke={customTxtClass ? '#0065A4' : '#3699FF'} //temporary FIX
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M21.5 13L14.1625 20L10.5 16.5'
            stroke={customTxtClass ? '#0065A4' : '#3699FF'} //temporary FIX
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>

        <span className='voterRegModals__securityDisc__txt'>
          The security of the voter registration and overall voting process under the controlled by
          Neuvote Elections Team.
        </span>
      </div>{' '}
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const ThankYou = (
    <>
      {' '}
      <div className='voterRegModals__thankYou'>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='voterRegModals__securityDisc__icon'
        >
          <path
            d='M5 14.3375V7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H26C26.2652 6 26.5196 6.10536 26.7071 6.29289C26.8946 6.48043 27 6.73478 27 7V14.3375C27 24.8375 18.0875 28.3125 16.3125 28.9C16.1109 28.9747 15.8891 28.9747 15.6875 28.9C13.9125 28.3125 5 24.8375 5 14.3375Z'
            stroke={customTxtClass ? '#0065A4' : '#3699FF'} //temporary FIX
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M21.5 13L14.1625 20L10.5 16.5'
            stroke={customTxtClass ? '#0065A4' : '#3699FF'} //temporary FIX
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
        <span className='voterRegModals__thankYou__label'>Thank You</span>
        <span className='voterRegModals__securityDisc__txt'>
          Your registration has been submitted successfuly. we will send a confirmation email soon
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const Downloading = (
    <div className='voterAdminModals__thankYou'>
      <span className='voterAdminModals__thankYou__subtitle'> {translate('Downloading')}...</span>
      <span className='voterAdminModals__thankYou__subtitle'>
        {translate('Electors List is downloading, please wait a few minutes.')}
      </span>
    </div>
  )

  const Downloaded = ({downloadURL, districtFilter, channelFilter}) => {
    return (
      <>
        <div className='voterAdminModals__thankYou'>
          <img
            alt='thank you icon'
            className='voterAdminModals__thankYou__img'
            src={toAbsoluteUrl('/media/design/thumbs_up_green_icon.svg')}
          />

          <span className='voterAdminModals__thankYou__label'> {translate('Thank You!')}</span>
          <span className='voterAdminModals__thankYou__subtitle'>
            {translate('Electors List has been downloaded successfully')}
          </span>
        </div>
        <ModalFooterWrapper>
          <CustomBtn
            view='ActionBtn'
            text='OK'
            height={47}
            onClick={() => {
              props.onRequestClose()
            }}
          />
        </ModalFooterWrapper>
      </>
    )
  }

  const PhotoID = (
    <>
      <div className='voterRegModals__photoID'>
        <div className='voterRegModals__photoID__imgWrap'>
          <div className='voterRegModals__photoID__driversWrap'>
            <img
              className='voterRegModals__photoID__drivers'
              src={toAbsoluteUrl('/media/design/sample_drivers_license.png')}
            />
            <span className='voterRegModals__photoID__imgLabel'>Driver Licence</span>
            <hr></hr>
          </div>
          <div className='voterRegModals__photoID__passportWrap'>
            <img
              className='voterRegModals__photoID__passport'
              src={toAbsoluteUrl('/media/design/sample_passport.png')}
            />
            <span className='voterRegModals__photoID__imgLabel'>Canadian Passport</span>
            <hr></hr>
          </div>
        </div>
        <div className='voterRegModals__photoID__pointsWrap'>
          <li className='voterRegModals__photoID__pointTxt'>Your driver's licence</li>
          <li className='voterRegModals__photoID__pointTxt'>Your Canadian passport</li>
          <li className='voterRegModals__photoID__pointTxt'>
            Any other card issued by a Canadian government (federal, provincial/territorial or
            local) with your photo, name and current address
          </li>
        </div>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const AddressDisclaimer = (
    <>
      <div className='voterRegModals__addressDisclaimer'>
        <div className='voterRegModals__addressDisclaimer__titleWrap'>
          <span className='voterRegModals__addressDisclaimer__titleTxt'>
            If your Address is not existed in our list.
          </span>
          <span className='voterRegModals__addressDisclaimer__subTitleTxt'>
            Please call us to help you registering.
          </span>
        </div>

        <div className='voterRegModals__addressDisclaimer__numbersWrap'>
          <span
            className={
              customTxtClass
                ? 'voterRegModals__addressDisclaimer__number1Txt' + ' ' + customTxtClass
                : 'voterRegModals__addressDisclaimer__number1Txt'
            }
          >
            902-424-8584
          </span>
          <span
            className={
              customTxtClass
                ? 'voterRegModals__addressDisclaimer__number2Txt' + ' ' + customTxtClass
                : 'voterRegModals__addressDisclaimer__number2Txt'
            }
          >
            1-800-565-1504
          </span>
        </div>

        <span className='voterRegModals__addressDisclaimer__contactUsTxt'>or Contact us</span>

        <button
          className={
            customBtnClass
              ? 'voterRegModals__addressDisclaimer__helpBtn' + ' ' + customBtnClass
              : 'voterRegModals__addressDisclaimer__helpBtn'
          }
        >
          Online Help
        </button>
      </div>{' '}
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const INE__ExampleBallot = (
    <>
      <div className='modalWrapper__INE__exampleBallot'>
        <img
          className='modalWrapper__INE__exampleBallot__img'
          src={
            props.ballotUrl
              ? props.ballotUrl
              : toAbsoluteUrl('/media/design/ine_example_ballot.png')
          }
        />
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const ConfirmQrRequestAdmin = (
    <>
      <div className='confirmQrReqAdmin'>
        <img
          className='confirmQrReqAdmin__icon'
          src={toAbsoluteUrl('/media/design/icon_warning_pink.svg')}
        />

        <span className='confirmQrReqAdmin__txt'>
          {translate('Are you sure you want to request a new QR code?')}
        </span>
      </div>
      <div className='ModalFooter'>
        <ModalBtn
          view='CancelBtn'
          text={translate('Cancel')}
          onClick={() => {
            props.onRequestClose()
          }}
        />

        <ModalBtn
          view='ActionBtn'
          text={translate('OK')}
          onClick={() => {
            props.onClick()
          }}
        />
      </div>
    </>
  )

  const ReportDownload__loading = (
    <>
      {' '}
      <div className='ReportDownload__loading'>
        <img
          className='reportDownloaded__icon'
          src={toAbsoluteUrl('/media/design/Spinning_neuvote.gif')}
        />
      </div>
    </>
  )

  const ReportDownloaded = (
    <>
      {' '}
      <div className='ReportDownloaded'>
        <img
          className='reportDownloaded__icon'
          src={toAbsoluteUrl('/media/design/thumbs_up_green_icon.svg')}
        />

        <span className='reportDownloaded__icon__title'>{translate('Great!')}</span>
        <span className='reportDownloaded__icon__subtitle'>
          {addPeriodToText(translate('Report has been downloaded successfully'))}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const ReportDownload__dateSelect = (
    <>
      {' '}
      <div className='ReportDownload__dateSelect'>
        <div className='ReportDownload__dateSelect__inputWrap'>
          <span className='ReportDownload__dateSelect__input__label'>
            {translate('Select a Date')}
          </span>
          <DatePicker
            selected={downloadReportStartDate}
            onChange={(dates) => {
              const [start, end] = dates
              setDownloadReportStartDate(start)
              setDownloadReportEndDate(end)
            }}
            className='eventEditModal__input'
            locale={languageValueToKey[LANGUAGE_CONTEXT.currentLanguage]}
            maxDate={new Date()}
            startDate={downloadReportStartDate}
            endDate={downloadReportEndDate}
            selectsRange
          />
        </div>
      </div>
      <div className='ModalFooter'>
        <ModalBtn
          view='CancelBtn'
          text='Cancel'
          onClick={() => {
            props.onRequestClose()
          }}
        />

        <ModalBtn
          view='ActionBtn'
          text='Download'
          onClick={() => {
            props.onCallback(downloadReportStartDate, downloadReportEndDate)
          }}
        />
      </div>
    </>
  )

  const ExtendSession = (
    <div className='ExtendSession'>
      <div className='extendSession__body'>
        <div className='extendSession__icons'>
          <img
            alt='clock progress icon'
            className='extendSession__icons--clockProgress'
            src={toAbsoluteUrl('/media/design/icon_clock_progress_pink.svg')}
          />
          <img
            alt='right arrow icon'
            className='extendSession__icons--rightArrow'
            src={toAbsoluteUrl('/media/design/icon_right_arrow_grey.svg')}
          />
          <img
            alt='circle right icon'
            className='extendSession__icons--circleRight'
            src={toAbsoluteUrl('/media/design/icon_circle_right_pink.svg')}
          />
        </div>

        <div className='extendSession__body__sessionTime'>
          {props.sessionTimeRemaining && !props.sessionTimeRemaining.includes('NaN') ? (
            <span className='adminTopbar__sessionTime'>
              <span className='adminTopbar__sessionTime__label'>
                {' '}
                {translate('Time left in session')}
                <LanguageSpecificColon />
              </span>

              <div className='adminTopbar__sessionTimeWrap'>
                <span className='adminTopbar__sessionTime__value'>
                  {props.sessionTimeRemaining}
                </span>
              </div>
            </span>
          ) : null}
        </div>

        <span className='extendSession__body__txt'>
          {translate('Do you want to extend the session for another 30 minutes?')}
        </span>
      </div>

      <div className='ModalFooter'>
        <button
          type='submit'
          className={'modalFooter__cancelBtn'}
          onClick={() => {
            endSession()
            props.onRequestClose()
          }}
        >
          {translate('Logout')}
        </button>

        <button
          type='submit'
          className={
            customBtnClass
              ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
              : 'modalFooter__primaryBtn'
          }
          onClick={() => {
            doExtendSession()
            props.onRequestClose()
          }}
        >
          {translate('Extend Session')}
        </button>
      </div>
    </div>
  )

  const renderView = (modalView, props) => {
    if (modalView == 'UpdateAddressList') {
      return UpdateAddressList
    }
    if (modalView == 'SecurityDisclaimer') {
      return SecurityDisclaimer
    }
    if (modalView == 'ThankYou') {
      return ThankYou
    }
    if (modalView == 'PhotoID') {
      return PhotoID
    }
    if (modalView == 'AddressDisclaimer') {
      return AddressDisclaimer
    }
    if (modalView == 'INE__ExampleBallot') {
      return INE__ExampleBallot
    }
    if (modalView == 'Downloading') {
      return Downloading
    }
    if (modalView == 'Downloaded') {
      return (
        <Downloaded
          downloadURL={props.downloadURL}
          districtFilter={props.districtFilter}
          channelFilter={props.channelFilter}
        />
      )
    }
    if (modalView == 'ExtendSession') {
      return ExtendSession
    }
    if (modalView == 'ConfirmQrRequestAdmin') {
      return ConfirmQrRequestAdmin
    }
    if (modalView == 'ReportDownloaded') {
      return ReportDownloaded
    }
    if (modalView == 'ReportDownload__loading') {
      return ReportDownload__loading
    }
    if (modalView == 'ReportDownload__dateSelect') {
      return ReportDownload__dateSelect
    }
    if (modalView == 'ChangePasswordSuccess') {
      return ChangePasswordSuccess
    }
    if (modalView == 'QRCodeChangeSuccess') {
      return QRCodeChangeSuccess
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        <ModalHeader onRequestClose={props.onRequestClose} headerLabel={headerLabel} />

        {/* BODY CONTENT */}
        {renderView(modalView, props)}
      </div>
    </Modal>
  )
}
