import {useTranslation} from '../../hooks/useTranslation'
import './CustomBtn.scss'

export function CustomBtn(props) {
  const {translate} = useTranslation()

  let {view, text, height, width, disabled, customStyling} = props

  const ActionBtn = (
    <button
      type='submit'
      style={{height, width, ...customStyling}}
      className={disabled ? 'CustomBtn disabled' : 'CustomBtn'}
      disabled={disabled}
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const PrimaryBtn = (
    <button
      type='submit'
      style={{height, width, ...customStyling}}
      className={'CustomBtn--primary'}
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const PrimaryBtnGrey = (
    <button
      type='submit'
      style={{height, width, ...customStyling}}
      className={'CustomBtn--primaryGrey'}
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const SecondaryBtn = (
    <button
      type='submit'
      style={{height, width, ...customStyling}}
      className={'CustomBtn--cancel'}
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const renderView = () => {
    if (view == 'ActionBtn') {
      return ActionBtn
    }
    if (view == 'SecondaryBtn') {
      return SecondaryBtn
    }
    if (view == 'PrimaryBtn') {
      return PrimaryBtn
    }

    if (view == 'PrimaryBtn--grey') {
      return PrimaryBtnGrey
    }
  }

  return renderView()
}
