import React, {useContext} from 'react'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {AdminSVG} from '../../../../voter_admin/reusable/admin_svg/AdminSVG'
import {languageValueToKey} from '../../../../../languages/languagekey'
import {beforeUnload} from '../../../../../App'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import {FiPlus} from 'react-icons/fi'

const EventHeader = ({detailedView, setDetailedView, setCurrentView, currentView}) => {
  const {translate} = useTranslation()
  const {tiviLink} = useSuperAdminUserContext()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  return (
    <div className='superAdmin__events__header'>
      {detailedView ? (
        <>
          <span
            className='common__backBtn'
            onClick={() => {
              setDetailedView(false)
            }}
          >
            {`< ${translate('Back')}`}
          </span>
          <span className='superAdmin__events__header__txt'>{translate('Event Details')}</span>
        </>
      ) : (
        <div className='EventHeader'>
          <div className='superAdmin__events__header__tabs'>
            <span className='superAdmin__events__header__txt'>{translate('Elections')}</span>

            <div className='superAdmin__events__header__tabs__icons'>
              <div
                className='superAdmin__events__header__tabs--calendar'
                onClick={() => {
                  setCurrentView('EventsCalendar')
                }}
              >
                <AdminSVG icon='Calendar' inactive={currentView !== 'EventsCalendar'} />
              </div>
              <div
                className='superAdmin__events__header__tabs--list'
                onClick={() => {
                  setCurrentView('EventsList')
                }}
              >
                <AdminSVG icon='List' inactive={currentView !== 'EventsList'} />{' '}
              </div>
            </div>
          </div>

          <div className='adminUsers__eventComponent__btnOuter'>
            <div
              className='adminUsers__eventComponent__btn'
              onClick={() => {
                window.removeEventListener('beforeunload', beforeUnload)
                window.location.href = `${tiviLink}?locale=${
                  languageValueToKey[LANGUAGE_CONTEXT.currentLanguage] || 'en'
                }`
              }}
            >
              <FiPlus color='#000' style={{marginRight: 10}} size={20} />

              <span className='adminUsers__eventComponent__btn__txt'>{translate('Add Vote')} </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EventHeader
