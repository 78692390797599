import {CustomBtn} from '../../../../resuable/custom_btn/CustomBtn'
import ModalFooterWrapper from '../../../../resuable/modal_footer_wrapper/ModalFooterWrapper'
import Modal from 'react-modal'
import './AuthAppModal.scss'
import {ModalHeaderWithLabel} from '../../../../../layout/ModalHeaderWithLabel'
import {useTranslation} from '../../../../hooks/useTranslation'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    minWidth: '500px',
    minHeight: '500px',
    maxWidth: '600px',
    width: 'fit-content',
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',

    padding: 0,
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const AuthAppModal: React.FC<{modalOpen: boolean; onClose: () => void}> = ({
  modalOpen,
  onClose,
}) => {
  const {translate} = useTranslation()

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOpen}
      //   onAfterOpen={props.onAfterOpen}
      onRequestClose={onClose}
      className='auth-app-modal'
      style={customStyles}
    >
      <ModalHeaderWithLabel label={translate('Auth App')} onClose={onClose} />
      <div className='auth-app-modal__body'>
        <div className='auth-app-modal__body__heading'>
          <span className='auth-app-modal__body__heading__title'>
            {translate(
              'You can find authenticator apps on the respective app stores for your device.'
            )}
          </span>
          <span className='auth-app-modal__body__heading__subtitle'>
            {translate("Here's where you can typically find them:")}
          </span>
        </div>

        <div className='auth-app-modal__body__examples'>
          <span className='auth-app-modal__body__examples__title'>Google Authenticator:</span>
          <span className='auth-app-modal__body__examples__subtitle'>
            {translate(
              'Available on the Google Play Store for Android devices and the App Store for iOS devices.'
            )}
          </span>
        </div>

        <div className='auth-app-modal__body__examples'>
          <span className='auth-app-modal__body__examples__title'>Microsoft Authenticator:</span>
          <span className='auth-app-modal__body__examples__subtitle'>
            {translate('Available on both the Google Play Store and the App Store.')}
          </span>
        </div>

        {/* <div className='auth-app-modal__body__examples'>
          <span className='auth-app-modal__body__examples__title'>Authy:</span>
          <span className='auth-app-modal__body__examples__subtitle'>
            {translate('You can find Authy on the Google Play Store and the App Store as well.')}
          </span>
        </div> */}

        <div className='auth-app-modal__body__examples auth-app-modal__body__examples--other-apps'>
          <span className='auth-app-modal__body__examples__title'>
            {translate('Other authenticator apps')}
            <LanguageSpecificColon />
          </span>
          <span className='auth-app-modal__body__examples__subtitle'>
            {translate(
              'There are various other authenticator applications available on different platforms. You can search for them directly in the App Store relevant to your device. We recommend using a trusted platform such as Google and Microsoft.'
            )}
          </span>
        </div>

        <span className='auth-app-modal__body__bottom-note'>
          {translate(
            "Open your device's app store, search for the name of the authenticator app you wish to use, and follow the instructions to download and install it on your device. Once installed, you can use it to set up two-factor authentication for your accounts that support it."
          )}
        </span>
      </div>
      <ModalFooterWrapper>
        <CustomBtn
          view='ActionBtn'
          text='OK'
          height={47}
          onClick={() => {
            onClose()
          }}
        />
      </ModalFooterWrapper>
    </Modal>
  )
}
export default AuthAppModal
