import React, {useContext} from 'react'
import LanguageSpecificColon from '../language_specific_colon/LanguageSpecificColon'
import {useTranslation} from '../../hooks/useTranslation'
import {formatDateLocale} from '../../languages/datetimeformats'
import {LanguageContext} from '../../contexts/LanguageContext'

const FormattedDate = ({date, size, title}) => {
  const {translate} = useTranslation()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  if (size === 'big') {
    return (
      <div className='eventVoteCard__footer__contentWrap eventVoteCard__footer__contentWrap-vertical'>
        <span
          className='eventVoteCard__footer__title eventVoteCard__footer__title-vertical'
          style={{color: '#a1a5b7'}}
        >
          {translate(title)}
          <LanguageSpecificColon />
        </span>
        <span className='eventVoteCard__footer__title eventVoteCard__footer__title-vertical'>
          {formatDateLocale(date, LANGUAGE_CONTEXT)}
        </span>
      </div>
    )
  }
  return (
    <div>
      <div className='eventDisplayCard__body__content__vtClosed__label'>
        {translate(title)}
        <LanguageSpecificColon />
      </div>
      <div className='eventDisplayCard__body__content__vtClosed__value'>
        {formatDateLocale(date, LANGUAGE_CONTEXT)}
      </div>
    </div>
  )
}

export default FormattedDate
