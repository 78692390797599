import React from 'react'
import {useTranslation} from '../../../../hooks/useTranslation'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import {isViewOnlyHelpManagement} from '../../../common/utility_functions/AuthUtility'

const HelpAndSupportHeader = ({setModalOpen}) => {
  const {translate} = useTranslation()
  return (
    <div className='common__pg__header'>
      <span className='common__pg__header__title'>{translate('Support')}</span>
      {!isViewOnlyHelpManagement() && (
        <div
          className='adminUsers__newUser__btn helpSupport__header__btn'
          onClick={() => {
            setModalOpen(true)
          }}
        >
          <img
            alt='add document icon'
            className=''
            src={toAbsoluteUrl('/media/design/icon_add_user_grey.svg')}
          />
          <span className='adminUsers__newUser__btn__txt helpSupport__header__btn__txt'>
            {translate('Add Document')}
          </span>
        </div>
      )}
    </div>
  )
}

export default HelpAndSupportHeader
