/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Modal from 'react-modal'
import {useTranslation} from '../../../hooks/useTranslation'
import {ModalHeader} from '../../modals/ModalHeader'
import {CustomBtn} from '../custom_btn/CustomBtn'
import FormikError from '../../../resuable/formik_error/FormikError'
import './AddLinkModal.scss'
import TextField from '../../../modules/auth/reusable/TextField'
import {addPeriodToText} from '../../common/utility_functions/TextUtility'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const initialValues = {
  englishTitle: '',
  frenchTitle: '',
  url: '',
}

// Each view should be in a seperate file
export function AddLinkModal({modalOpen, onRequestClose = () => {}}) {
  const {translate} = useTranslation()

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      englishTitle: Yup.string().required('English or French title is required '),
      frenchTitle: Yup.string().required('English or French title is required'),
      url: Yup.string()
        .required('URL is required')
        .matches(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/, 'Invalid URL format')
        .url('Invalid URL format'),
    })
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(async () => {
        console.log('values', values)
      }, 1000)
    },
  })

  const updateSchema = (newSchema) => {
    setValidationSchema(newSchema)
  }

  const onClose = () => {
    onRequestClose()
  }

  useEffect(() => {
    // Clear errors if english and url or french and url is filled in
    if (
      (formik.values['englishTitle'] && formik.values['url'] && !formik.errors['url']) ||
      (formik.values['frenchTitle'] && formik.values['url'] && !formik.errors['url'])
    ) {
      updateSchema(Yup.object().shape({}))
    }

    console.log('formik.errors', formik.errors)
  }, [formik.errors])

  return (
    <Modal ariaHideApp={false} isOpen={modalOpen} onRequestClose={onClose} style={customStyles}>
      <div className='ModalWrapper' style={{flexGrow: 1, overflowY: 'visible'}}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader
            customStyles={{padding: '23px 25px'}}
            onRequestClose={onClose}
            title={translate('Add Link')}
          />
          <div className='AddDocument'>
            <TextField
              formik={formik}
              type='text'
              field={'englishTitle'}
              placeholder={translate('English Title')}
              label={translate('English Title')}
              view='voter'
              onChange={formik.handleChange}
            />

            <TextField
              formik={formik}
              type='text'
              field={'frenchTitle'}
              placeholder={translate('French Title')}
              label={translate('French Title')}
              view='voter'
              onChange={formik.handleChange}
              customStyling={{marginTop: 25}}
            />

            <TextField
              formik={formik}
              type='text'
              field={'url'}
              placeholder={translate('URL')}
              label={translate('URL')}
              view='voter'
              onChange={formik.handleChange}
              customStyling={{marginTop: 25}}
            />
            {/* Display Formik error  */}
            {formik.errors['englishTitle'] && formik.errors['frenchTitle'] && (
              <FormikError
                formik={formik}
                fieldName='englishTitle'
                errorLabel={addPeriodToText(translate('English or French title is required'))}
              />
            )}
            {/* Display Formik error  */}
            {formik.errors['url'] && (
              <FormikError
                formik={formik}
                fieldName='url'
                errorLabel={translate(formik.errors['url'])}
              />
            )}
          </div>
          <div className='ModalFooter'>
            <CustomBtn
              view='SecondaryBtn'
              text={translate('Cancel')}
              type='button'
              height={47}
              onClick={onClose}
            />

            <CustomBtn
              view='ActionBtn'
              text={translate('Add')}
              type='submit'
              height={47}
              // onClick={onClose}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}
