export const themeVariables = {
  // variables.scss
  primaryColor: '#0065a4',
  primaryLightColor: '#e7f5fd',
  secondaryColor: '#181c32',

  successColor: '#26A660',
  warningColor: '#E46D00',
  errorColor: '#E30000',
}
export default themeVariables
