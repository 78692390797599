/**
 * Shortens the text for the provided text and number of characters to
 * shorten it till.
 * @param {string} text
 * @param {Number} maxChars
 * @returns {string} shortened string
 */
export function truncateText(text, maxChars) {
  /**
   * if the text's length is greater than 10 characters then,
   * return the subtring of the first maxChars characters. else,
   * return the text itself.
   * */
  return text?.length > 10 ? text?.substring(0, maxChars) + '...' : text
}

/**
 * Adds period to the end of the text
 * @param {string} text
 * @returns {string}
 */
export function addPeriodToText(text) {
  /**
   * if the text ends with a period then just return
   * the text as is, else return the text with a period
   * at the end of it.
   **/
  return text?.endsWith('.') ? text : text + '.'
}
