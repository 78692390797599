/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import VerificationInput from 'react-verification-input'

import './QRCode.scss'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {addPeriodToText} from '../../../../../common/utility_functions/TextUtility'

function QRCodeRegen(props) {
  const {translate} = useTranslation()

  const {view, qrCodeDataUrl, code, setCode} = props

  return (
    <div>
      <div
        className={`authAppSetup__form__body__methodWrap ${
          view == 'Voter__MyProfile' && 'qrCode__body'
        }`}
      >
        <div className='authMethod__form__body__methodWrap__txtWrap'>
          <span className='authMethod__form__body__methodWrap__txtWrap__label'>
            {translate('Scan Me!')}
          </span>
        </div>
        {view == 'Voter__MyProfile' ? (
          <div className='qrCode__body__point'>
            <div className='qrCode__body__point__1'>
              <span className='qrCode__body__point__1__txt'>1</span>
            </div>
            <span className='authAppSetup__form__body__method__subtitle'>
              {translate(
                'To set up your login method you will need to scan this QR code with any available authentication app'
              )}
            </span>
          </div>
        ) : (
          <div className='authAppSetup__form__body__method__subtitleWrap'>
            {' '}
            <span className='authAppSetup__form__body__method__subtitle'>
              {translate(
                'To set up your login method you will need to scan this QR code with any available authentication app'
              )}
            </span>
          </div>
        )}
        <img
          className={`authAppSetup__form__body__method__qrCode ${
            view == 'Voter__MyProfile' && 'qrCode__img'
          }`}
          src={qrCodeDataUrl}
          alt='qrcode'
        />
        {view == 'Voter__MyProfile' ? (
          <div className='qrCode__body__point' style={{paddingTop: 27.5}}>
            <div className='qrCode__body__point__2'>
              <span className='qrCode__body__point__2__txt'>2</span>
            </div>
            <span className='authAppSetup__form__body__method__subtitle'>
              {addPeriodToText(
                translate(
                  'Enter the 6 digit verification code shown in the authentication app installed on your phone'
                )
              )}
            </span>
          </div>
        ) : (
          <div
            className='authAppSetup__form__body__method__subtitleWrap'
            style={{paddingTop: 27.5}}
          >
            {' '}
            <span className='authAppSetup__form__body__method__subtitle'>
              {addPeriodToText(
                translate(
                  'Enter the 6 digit verification code shown in the authentication app installed on your phone'
                )
              )}
            </span>
          </div>
        )}

        <div className={view == 'Voter__MyProfile' ? 'qrCode__verifyInput' : ''}>
          <VerificationInput
            autoFocus={true}
            inputProps={{autoComplete: 'one-time-code'}}
            value={code}
            onChange={setCode}
            validChars='0-9'
            length={6}
            classNames={{
              container: 'TextField__confirmCode__container--VoterMyProfile',
              character: 'TextField__confirmCode',
              characterInactive: 'TextField__confirmCode__inactive',
              characterSelected: 'TextField__confirmCode__selected__MEXICO',
            }}
          />
        </div>
      </div>

      {/*  */}
      <div className='phoneVerify__form__actionBar'>
        <button
          className='authMethodLogin__nextBtn'
          onClick={() => {
            props.onSubmit()
          }}
        >
          {translate('Next')}
        </button>
      </div>
    </div>
  )
}

export default QRCodeRegen
