/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import './MEXICO__SuperAdmin__Events.scss'
// NOT using this component so commenting the scss import
// import '../../../pages/voter_admin/pages/nova_scotia/dashboard/NOVASCOTIA__Dashboard.scss'
import {Redirect} from 'react-router-dom'
import EventHeader from './event_header/EventHeader'
import {Calendar} from './calendar/Calendar'
import EventCard from './event_card/EventCard'
import EventDetail from './event_detail/EventDetail'
import KeycloakAdminService from '../../../../../KeycloakAdminService'
import AdminVoterService from '../../../../services/admin/AdminVoterService'
import EventDisplaySection from '../../voter/dashboard/components/events_display_section/EventsDisplaySection'

export function MEXICO__SuperAdmin__Events(props) {
  const [detailedView, setDetailedView] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [events, setEvents] = useState([])
  const [calendarEvents, setCalendarEvents] = useState([])
  const contactInfoRole = KeycloakAdminService.hasRole('tivi-contactinfo')
  const [currentView, setCurrentView] = useState('EventsCalendar')

  useEffect(() => {
    const getEvents = async () => {
      let resp = await AdminVoterService.syncEvents()

      if (resp?.events) {
        //@ts-ignore
        setEvents(resp.events)
      }
    }
    const getCalendarEvents = async () => {
      let resp = await AdminVoterService.syncCalendarEvents()

      if (resp?.events) {
        //@ts-ignore
        setCalendarEvents(resp.events)
      }
    }

    getCalendarEvents()
    getEvents()
  }, [])

  const onSelectEvent = (event) => {
    setDetailedView(true)
    setCurrentEvent(event)
  }

  const renderView = () => {
    if (detailedView && currentEvent?._id) {
      return <EventDetail currentEvent={currentEvent} setCurrentEvent={setCurrentEvent} />
    } else if (currentView === 'EventsList') {
      return (
        <div className='superAdmin__events__list'>
          <EventDisplaySection events={events} onSelectEvent={onSelectEvent} />
        </div>
      )
    } else if (currentView === 'EventsCalendar') {
      return (
        <div className='superAdmin__events__calendar'>
          <Calendar events={calendarEvents} onSelectEvent={onSelectEvent} />
        </div>
      )
    }
  }

  if (contactInfoRole) {
    return <Redirect to='/admin/electors-list' />
  }
  return (
    <div className='SuperAdmin__Events'>
      <EventHeader
        detailedView={detailedView}
        setDetailedView={setDetailedView}
        setCurrentView={setCurrentView}
        currentView={currentView}
      />

      {renderView()}
    </div>
  )
}
