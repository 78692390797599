/**
 * Generates a Google Docs Viewer URL for the provided file URL.
 * @param {string} fileUrl - The URL of the file to be viewed in Google Docs Viewer.
 * @returns {string} The Google Docs Viewer URL with the embedded file URL.
 */
export function generateGoogleDocsViewerUrl(fileUrl) {
  return `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl || '')}&embedded=true`
}

/**
 * Determines the file type based on the extension of the given URL.
 * @param {string} url The URL of the file.
 * @returns {string} The file type ('image', 'document', or 'unsupported').
 */
export function getFileTypeFromName(url) {
  // Extract the extension from the URL
  const extension = url.split('.').pop()?.toLowerCase()

  // Determine the file type based on the extension
  if (extension) {
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
      return 'image'
    } else if (['pdf', 'doc', 'docx'].includes(extension)) {
      return 'document'
    } else {
      return 'unsupported'
    }
  }

  // Default to unsupported if no extension is found
  return 'unsupported'
}
