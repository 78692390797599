/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Modal from 'react-modal'
import './EventAttachmentsModal.scss'

import {Spinner} from 'react-bootstrap-v5'
import {useTranslation} from '../../../../../../../hooks/useTranslation'
import AdminVoterAuthService from '../../../../../../../services/auth/AdminVoterAuthService'
import {ModalHeader} from '../../../../../../modals/ModalHeader'
import DocumentInputUpload from '../../../../../../super_admin/help_and_support/add_doc_modals/doc_input_upload/DocumentInputUpload'
import FormikError from '../../../../../../reusable/formik_error/FormikError'
import {CustomBtn} from '../../../../../../reusable/custom_btn/CustomBtn'
import {toAbsoluteUrl} from '../../../../../../../helperfunctions/AssetHelpers'
import {ModalFooter} from '../../../../../../voter/reusable/modals/modal_footer/ModalFooter'
import {addPeriodToText} from '../../../../../../common/utility_functions/TextUtility'

const allowedFiles = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'mp3', 'mp4', 'mov', 'wav']

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export const initialValues = {
  englishTitle: '',
  frenchTitle: '',
  englishDocument: null,
  frenchDocument: null,
}

// Each view should be in a seperate file
export function EventAttachModal({
  view,
  modalOpen,
  onRequestClose = () => {},
  eventId,
  getAttachments,
}) {
  const {translate} = useTranslation()

  const [currentView, setCurrentView] = useState(view)

  const [uploadErr, setUploadErr] = useState('')

  const onClose = () => {
    setCurrentView('Add Document')
    onRequestClose()
  }

  const AddDocument = () => {
    const [validationSchema, setValidationSchema] = useState(
      Yup.object().shape({
        englishTitle: Yup.string().required('English or French title is required'),
        frenchTitle: Yup.string().required('English or French title is required'),
        englishDocument: Yup.mixed().required('English document is required'),
        frenchDocument: Yup.mixed().required('French document is required'),
      })
    )

    const updateSchema = (newSchema) => {
      setValidationSchema(newSchema)
    }

    const initialValues = {
      englishTitle: '',
      englishDocument: null,
      frenchTitle: '',
      frenchDocument: null,
    }
    const formik = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values, {setStatus, setSubmitting}) => {
        try {
          setCurrentView('LoadingScreen')

          const formData = new FormData()
          formData.append('en-helpfile', values.englishDocument?.file)
          formData.set('en-title', values.englishTitle)
          formData.append('fr-helpfile', values.frenchDocument?.file)
          formData.set('fr-title', values.frenchTitle)
          if (eventId) {
            await AdminVoterAuthService.createHelpDocEvent(formData, eventId)
          } else {
            await AdminVoterAuthService.createHelpDoc(formData)
          }
          onClose()
          getAttachments()
        } catch (error) {
          setUploadErr(translate('Something went wrong, please contact support'))
          setCurrentView('uploadError')
          console.log(error)
        }
      },
    })

    useEffect(() => {
      // Clear errors for French title and document
      if (
        (formik.values['englishTitle'] && formik.values['englishDocument']) ||
        (formik.values['frenchTitle'] && formik.values['frenchDocument'])
      ) {
        updateSchema(Yup.object().shape({}))
      }

      console.log('formik.errors', formik.errors)
    }, [formik.errors])

    return (
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader
          customStyles={{padding: '23px 25px'}}
          onRequestClose={onClose}
          title={translate('Add Document')}
        />
        <div className='AddDocument'>
          <DocumentInputUpload
            formik={formik}
            textFieldName='englishTitle'
            documentFieldName='englishDocument'
            label={translate('English Title')}
          />

          <DocumentInputUpload
            formik={formik}
            textFieldName='frenchTitle'
            documentFieldName='frenchDocument'
            label={translate('French Title')}
            style={{marginTop: 40}}
          />

          {/* Display Formik error for the field */}
          {formik.errors['englishTitle'] && formik.errors['frenchTitle'] && (
            <FormikError
              formik={formik}
              fieldName='englishTitle'
              errorLabel={addPeriodToText(translate('English or French title is required'))}
            />
          )}

          {formik.values['englishTitle'] && formik.errors['englishDocument'] && (
            <FormikError
              formik={formik}
              fieldName='englishDocument'
              errorLabel={addPeriodToText(translate(formik.errors['englishDocument']))}
            />
          )}

          {formik.values['frenchTitle'] && formik.errors['frenchDocument'] && (
            <FormikError
              formik={formik}
              fieldName='frenchDocument'
              errorLabel={addPeriodToText(translate(formik.errors['frenchDocument']))}
            />
          )}
        </div>
        <div className='ModalFooter'>
          <CustomBtn
            view='SecondaryBtn'
            text={translate('Cancel')}
            type='button'
            height={47}
            onClick={onClose}
          />

          <CustomBtn view='ActionBtn' text={translate('Add')} type='submit' height={47} />
        </div>
      </form>
    )
  }

  const errorView = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={onClose} headerLabel={translate('Uploading Error')} />
      <div className='importAddrListM__wrongFileTypeErr'>
        <img
          className='importAddrListM__wrongFileTypeErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
          alt='error'
        />
        <span className='importAddrListM__wrongFileTypeErr__txt' style={{whiteSpace: 'pre-wrap'}}>
          {uploadErr}
        </span>
      </div>
      <ModalFooter onRequestClose={onClose} />
    </>
  )

  const wrongFileTypeErr = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={onClose} headerLabel={translate('Uploading Error')} />
      <div className='importAddrListM__wrongFileTypeErr'>
        <img
          className='importAddrListM__wrongFileTypeErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
          alt='error'
        />
        <span className='importAddrListM__wrongFileTypeErr__txt'>
          {translate(
            'The file couldn’t be uploaded because it isn’t in the supported format or the file might be corrupted'
          )}
        </span>

        <span className='importAddrListM__wrongFileTypeErr__subTxt'>
          {translate('Please try again using supported file type. Accepted file format is')}
          <span className='civik-primary-blue'> ({allowedFiles.join(', ')})</span>.{' '}
        </span>
      </div>
      <ModalFooter onRequestClose={onClose} />
    </>
  )

  const renderView = () => {
    if (currentView === 'Add Document') {
      return <AddDocument />
    }

    if (currentView === 'uploadError') {
      return errorView
    }

    // }
    if (currentView === 'wrongFileTypeErr') {
      return wrongFileTypeErr
    }
    if (currentView === 'LoadingScreen') {
      return LoadingScreen
    }
  }

  return (
    <Modal ariaHideApp={false} isOpen={modalOpen} onRequestClose={onClose} style={customStyles}>
      <div className='ModalWrapper'>
        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </Modal>
  )
}

export const LoadingScreen = (
  <>
    <div className='ReportDownload__loading'>
      <Spinner animation='border' />
    </div>
  </>
)

export default EventAttachModal
