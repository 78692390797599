import React, {createContext, useContext, useState} from 'react'

// Create a context
const VoterListContext = createContext()

// Create a provider component
export const VoterListContextProvider = ({children}) => {
  // Define all your states here
  const [regionLoading, setRegionLoading] = useState(false)
  const [regionOp, setRegionOp] = useState([])
  const [branchOp, setBranchOp] = useState({})
  const [isGroupOptionsLoading, setIsGroupOptionsLoading] = useState(false)
  const [isSubGroupOptionsLoading, setIsSubGroupOptionsLoading] = useState(null)
  const [groupOptions, setGroupOptions] = useState([])
  const [isSubClassOpLoading, setIsSubClassOpLoading] = useState(null)
  const [isChapterOpLoading, setIsChapterOpLoading] = useState(false)
  const [chapterOptions, setChapterOptions] = useState([])
  const [isClassificationLoading, setIsClassificationLoading] = useState(false)
  const [classificationOp, setClassificationOp] = useState([])
  const [isEmployerLoading, setIsEmployerLoading] = useState(false)
  const [employerOptions, setEmployerOptions] = useState([])
  const [departmentOpLoading, setDepartmenOpLoading] = useState(false)
  const [branchOpLoading, setBranchOpLoading] = useState(false)
  const [employerLoading, setEmployerLoading] = useState(false)
  const [subClassOp, setSubClassOp] = useState({})
  const [departmentOptions, setDepartmentOptions] = useState({})
  const [subGroupOptions, setSubGroupOptions] = useState({})

  // Create the context value object
  const contextValue = {
    regionLoading,
    setRegionLoading,
    regionOp,
    setRegionOp,
    branchOp,
    setBranchOp,
    isGroupOptionsLoading,
    setIsGroupOptionsLoading,
    isSubGroupOptionsLoading,
    setIsSubGroupOptionsLoading,
    groupOptions,
    setGroupOptions,
    isSubClassOpLoading,
    setIsSubClassOpLoading,
    isChapterOpLoading,
    setIsChapterOpLoading,
    chapterOptions,
    setChapterOptions,
    isClassificationLoading,
    setIsClassificationLoading,
    classificationOp,
    setClassificationOp,
    isEmployerLoading,
    setIsEmployerLoading,
    employerOptions,
    setEmployerOptions,
    departmentOpLoading,
    setDepartmenOpLoading,
    branchOpLoading,
    setBranchOpLoading,
    employerLoading,
    setEmployerLoading,
    subClassOp,
    setSubClassOp,
    departmentOptions,
    setDepartmentOptions,
    subGroupOptions,
    setSubGroupOptions,
  }

  return <VoterListContext.Provider value={contextValue}>{children}</VoterListContext.Provider>
}

// Custom hook to use the context
export const useVoterListContext = () => {
  return useContext(VoterListContext)
}
