/* eslint-disable jsx-a11y/anchor-is-valid */
import './EventDisplayCard.scss'
import {VoterEvent} from './components/voter_event/VoterEvent'
import {AdminEvent} from './components/admin_event/AdminEvent'
import useCheckVotingOpenStateChange from '../../hooks/useCheckVotingOpenStateChange'

import CustomSpinner from '../spinner/Spinner'

export function EventDisplayCard({
  title,
  event: propsEvent,
  closeDate,
  view,
  onSelectEvent = (event: any) => {},
}) {
  const {votingOpen, loading} = useCheckVotingOpenStateChange({
    event: propsEvent,
    closeDate: propsEvent.closeDate,
  })

  if (loading) {
    return <CustomSpinner />
  }

  const renderView = () => {
    if (view === 'voter') {
      return (
        <VoterEvent
          event={propsEvent}
          title={title}
          votingOpen={votingOpen}
          closeDate={closeDate}
        />
      )
    }

    if (view === 'admin') {
      return (
        <AdminEvent
          event={propsEvent}
          title={title}
          votingOpen={votingOpen}
          onSelectEvent={onSelectEvent}
        />
      )
    }
  }

  return renderView()
}
