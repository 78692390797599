import FRENCH_TRANSLATION from '../pages/translations/nwt/EnglishToFrench.json'
import SPANISH_TRANSLATION from '../pages/translations/mexico/EnglishToSpanish.json'

export const translate = (string, LANGUAGE_CONTEXT) => {
  if (LANGUAGE_CONTEXT.currentLanguage === 'English') {
    return string
  }
  if (LANGUAGE_CONTEXT.currentLanguage === 'French') {
    return FRENCH_TRANSLATION[string] ? FRENCH_TRANSLATION[string] : string
  }
  if (LANGUAGE_CONTEXT.currentLanguage === 'Spanish') {
    return SPANISH_TRANSLATION[string] ? SPANISH_TRANSLATION[string] : string
  }
}

export const translateEventName = (name, LANGUAGE_CONTEXT) => {
  if (LANGUAGE_CONTEXT.currentLanguage === 'French') {
    return name.fr
  }
  if (LANGUAGE_CONTEXT.currentLanguage === 'English') {
    return name.en
  }
  if (LANGUAGE_CONTEXT.currentLanguage === 'Spanish') {
    return name.es
  }
}
