import {useTranslation} from '../../../../../hooks/useTranslation'
import {isContactInfoRoleAdmin, isSuperAdmin} from '../../../../../../KeycloakAdminService'
import {AdminDropdown} from '../../../reusable/admin_dropdown/AdminDropdown'
import {useContext, useEffect, useState} from 'react'
import USER_TYPE_COLORS from '../../../../common/constants/UserTypeConstant'
import styled from 'styled-components'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {formatDateLocaleShort} from '../../../../../languages/datetimeformats'

// Define the styled div component for UserTypeBadge
const StyledUserTypeBadge = styled.div<{userType: string}>`
  width: 90%;
  height: 31px;
  border-radius: 6px;
  text-align: center;
  line-height: 31px; /* Center vertically */
  color: ${(props) => USER_TYPE_COLORS[props.userType]?.color};
  background-color: ${(props) => USER_TYPE_COLORS[props.userType]?.backgroundColor};

  /* Text styles */
  font-size: 12px;
  font-weight: 700;
`

const ElectorsListRow = ({user, setUserToEdit, setEditUserModal, setEditUserModalView}) => {
  const {translateObj} = useTranslation()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const userFullName = user.firstName + ' ' + user.lastName
  const userType = user.userType

  function getAbbreviationByAccountType(accountType) {
    const groupDetails = user.userGroupsDetails.find(
      (obj) => obj && obj.accountType === accountType
    )
    return groupDetails ? translateObj(groupDetails.abbreviation) : null
  }

  return (
    <div className='voterAddrList__tableBody__row'>
      {/* MEMBER ID */}
      <span className='electorListTable__item--1'>{user.username}</span>
      {/* FULL NAME */}
      <span className='electorListTable__item--2'>{userFullName}</span>
      {/* EMAIL */}
      <span className='electorListTable__item--3'>{user.email}</span>
      <span className='electorListTable__item--4'>{getAbbreviationByAccountType('Group')}</span>
      <span className='electorListTable__item--5'>{getAbbreviationByAccountType('Subgroup')}</span>
      <span className='electorListTable__item--6'>{getAbbreviationByAccountType('Region')}</span>
      <span className='electorListTable__item--7'>{getAbbreviationByAccountType('Branch')}</span>

      {/* TYPE */}
      <span className='electorListTable__item--w5'>
        <StyledUserTypeBadge userType={userType}>{userType}</StyledUserTypeBadge>
      </span>

      <span className='electorListTable__item--10'>
        {user.lastSignIn ? formatDateLocaleShort(user.lastSignIn, LANGUAGE_CONTEXT) : ''}
      </span>

      {/* AdminDropdown component */}
      {(isSuperAdmin() || isContactInfoRoleAdmin()) && (
        <div className='electorListTable__item--9 row-item-ellipsis'>
          <AdminDropdown
            view='electorDecision'
            user={user}
            onCallback={(value) => {
              setUserToEdit(user)
              setEditUserModalView(value) // Edit, Resend access email, Reset password
              setEditUserModal(true)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ElectorsListRow
