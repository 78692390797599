import {OnlineVoterAuthServiceAxiosInstance as instance} from '../OnlineVoterAuthService'

export const JumioOnboarding = {
  startFacedIdOnboarding: async () => {
    try {
      let res = await instance.post('/auth/jumio/mfa/onboard/initiate')
      return {success: true, iframeUrl: res.iframeUrl}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  onFinishFacedIdOnboarding: async ({accountId, workflowId}) => {
    try {
      await instance.post(
        `/auth/jumio/mfa/onboard/verify/accounts/${accountId}/flows/${workflowId}`
      )
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
}
