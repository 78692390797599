import moment from 'moment'
import configOptions from '../../../../utils/configOptions'

/**
 * Converts a date to a moment object and adjusts to the specified time zone if provided.
 * @param {string|Date|moment.Moment} date - The date to convert.
 * @returns {moment.Moment} The converted moment object.
 */
export const convertToMomentWithTimeZone = (date) => {
  let momentDate = moment(date)
  const timeZone = configOptions.timeZoneDateString

  if (timeZone) {
    momentDate = momentDate.tz(timeZone)
  }

  return momentDate
}
