import {toAbsoluteUrl} from '../../../../../../../helperfunctions/AssetHelpers'
import {useTranslation} from '../../../../../../../hooks/useTranslation'

const EventAttachmentsHeader = ({
  setDocModal,
  setModalView,
  setAddLinkModal, // TODO: will use later
  viewOnly,
  listOfDocs, // TODO: will use later
}) => {
  const {translate} = useTranslation()
  const handleBtnPress = (view) => {
    setModalView(view)
    setDocModal(true)
  }

  return (
    <div className='event-attachments__header'>
      {/* TITLE  */}
      <div className='event-attachments__header__title-and-search'>
        <span className='event-attachments__header__title-and-search__title-label'>
          {translate('Attachments')}
        </span>
        {/* TODO: will use this later */}
        {/* <SearchTextFieldNoFormik customStyling={{width: 200}} /> */}
      </div>

      {/* ACTION BUTTONS */}
      {!viewOnly && (
        <div className='event-attachments__header__btns'>
          {/* TODO: will use this later */}
          {/* <div
            className='event-attachments__header__btns__btn event-attachments__header__btns__btn--add-link'
            onClick={() => {
              setAddLinkModal(true)
            }}
          >
            <img alt='link icon' src={toAbsoluteUrl('/media/design/link-icon-grey.svg')} />
            <span className='event-attachments__header__btns__btn__label'>
              {translate('Add Link')}
            </span>
          </div> */}

          <div
            className='event-attachments__header__btns__btn'
            onClick={() => {
              handleBtnPress('upload')
            }}
          >
            <img alt='file icon' src={toAbsoluteUrl('/media/design/file-icon-grey.svg')} />
            <span className='event-attachments__header__btns__btn__label'>
              {translate('Add Document')}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default EventAttachmentsHeader
