import axios from 'axios'
import environment from '../../../utils/environment'
import Cookies from 'universal-cookie'
import VoterAuthService from './VoterAuthService'
import {languageValueToKey} from '../../languages/languagekey'

const cookies = new Cookies()
export const ONBOARDING_COOKIE = 'onboardingAccessToken'

const axiosInstance = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}`,
  withCredentials: true,
  headers: {},
})

// attach access token
axiosInstance.interceptors.request.use(
  async (config) => {
    const access_token = cookies.get(ONBOARDING_COOKIE)
    if (access_token) {
      config.headers = {
        Authorization: `Bearer ${access_token}`,
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(ONBOARDING_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
    }
    return res
  },
  (err) => {
    if (err.status === 403 || err.response?.status === 403) {
      cookies.remove(ONBOARDING_COOKIE)
    }
    const error = {
      ...err,
      ...err.response?.data?.error,
    }

    // console.error(error);
    return Promise.reject(error)
  }
)

const OnlineVoterAuthService = {
  validateAccessLink: async (accessCode) => {
    try {
      await axiosInstance.post('/online-voter-onboarding/validate/access_link', {accessCode})
      return {success: true}
    } catch (error) {
      return {error: true}
    }
  },
  validateSecurityQuestion: async (accessCode, securityAnswer, recaptchaToken) => {
    try {
      let result = await axiosInstance.post('/online-voter-onboarding/validate/security_question', {
        accessCode,
        securityAnswer,
        recaptchaToken,
      })
      return {success: true, user: result.user}
    } catch (error) {
      return {error}
    }
  },
  createNewPassword: async (password) => {
    // try {
    await axiosInstance.post('/online-voter-onboarding/change_password', {
      password,
    })
    let user = VoterAuthService.getUserInfoKeycloakToken()
    return {success: true, user}
    // } catch (error) {
    //   return {error: true}
    // }
  },
  saveMobilePhone: async (phone) => {
    try {
      await axiosInstance.put('/online-voter-onboarding/cellphone', {phone})
      return {success: true}
    } catch (error) {
      // console.error(error)
      return {error}
    }
  },
  sendSMSCode: async (language) => {
    try {
      language = languageValueToKey[language]
      await axiosInstance.post(`/online-voter-onboarding/mfa/initiate/sms?locale=${language}`)
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  sendEmailCode: async () => {
    try {
      await axiosInstance.post('/online-voter-onboarding/mfa/initiate/email')
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifySMSCode: async (verificationCode) => {
    try {
      await axiosInstance.post('/online-voter-onboarding/mfa/verify/sms', {
        verificationCode,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyEmailCode: async (verificationCode) => {
    try {
      await axiosInstance.post('/online-voter-onboarding/mfa/verify/email', {
        verificationCode,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  getAuthAppQRCode: async () => {
    try {
      const resp = await axiosInstance.post('/online-voter-onboarding/mfa/setup/authapp')
      let qrCodeDataUrl = resp.qrCodeDataUrl
      return {success: true, qrCodeDataUrl}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
  verifyAuthAppQRCode: async (code) => {
    try {
      await axiosInstance.post('/online-voter-onboarding/mfa/setup/verify/authapp', {
        code,
      })
      // next create passowrd
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error}
    }
  },
}

export default OnlineVoterAuthService
export const OnlineVoterAuthServiceAxiosInstance = axiosInstance
