/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useHistory, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import '@djthoms/pretty-checkbox'
import TextField from '../../../../../modules/auth/reusable/TextField'

import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import {VoterAuthContext} from '../../../../../contexts/VoterAuthContext'

import './Common.scss'
import './MEXICO__UserLogin.scss'
import VoterAuthService from '../../../../../services/auth/VoterAuthService'
import {VoterUserContext} from '../../../../../contexts/VoterUserContext'

import useQuery from '../../../../../routing/UseQuery'

import {VoterLoginFlowContext} from '../../../../../contexts/VoterLoginFlowContext'

import VoterRegisterBanner from '../../../../../resuable/layout/MainLayoutBanner'
import {useTranslation} from '../../../../../hooks/useTranslation'
import RecapatchaDisclaimer from '../../../../../resuable/recapatcha/RecapatchaDisclaimer'
import MainLayoutBanner from '../../../../../resuable/layout/MainLayoutBanner'
import {addPeriodToText} from '../../../../common/utility_functions/TextUtility'
import {LanguageContext} from '../../../../../contexts/LanguageContext'

const initialValues = {
  username: '',
  password: '',
}

export function MEXICO__UserLogin() {
  const {translate} = useTranslation()

  const history = useHistory()

  const {executeRecaptcha} = useGoogleReCaptcha()

  const query = useQuery()
  // @ts-ignore
  const {eventId} = useParams()

  const queryUsername = query?.get('username')
  const queryPassword = query?.get('password')

  const [checkedOption, setCheckedOption] = useState(null)
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState(null)
  const [codeError, setCodeError] = useState(null)
  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const VOTER_AUTH_CONTEXT = useContext(VoterAuthContext)
  const VoterUser = useContext(VoterUserContext)
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  const {currentLanguage} = useContext(LanguageContext)

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(translate('Membership number is required')),
    password: Yup.string().required(translate('Password is required')),
  })

  useEffect(() => {
    if (eventId && queryUsername && queryPassword) {
      formik.setFieldValue('username', queryUsername)
      formik.setFieldValue('password', queryPassword)
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            setErrorMessage('reCAPTCHA error. Please refresh the page and try again.')
            return
          }
          const token = await executeRecaptcha('voter_login')

          const userInput = {
            username: values.username,
            password: values.password,
          }
          let resp = await VoterAuthService.login(
            userInput.username,
            userInput.password,
            eventId,
            token
          )
          // console.log('userInput', userInput)

          if (resp?.success) {
            if (resp.user) {
              VoterUser.setUser(resp.user)
            }
            if (!resp.link) {
              if (resp.passwordResetRequired) {
                VOTER_LOGIN_CONTEXT.setLoginFlow('Forgot Password')
              }
              history.push('/voting/voter-verify/select-verifier')
            }

            setLoading(false)
            setError(false)
          } else {
            setError(true)
            // Invalid user credentials
            // session error: only one session per user
            console.error(resp.error?.message)
            switch (resp.error?.message) {
              case 'Invalid user credentials':
                setErrorMessage('Invalid password or membership number')
                break
              case 'session error: only one session per user':
                setErrorMessage(
                  'Only one session is allowed per user, please log out of your other session to continue'
                )
                break
              case 'Account disabled':
                setErrorMessage('Error: Please contact elections@pipsc.ca.')
                break
              case 'Already voted':
                setErrorMessage('Authentication failed. User has already voted.')
                break
              case 'Event ended':
                setErrorMessage('The voting period has ended./nThank you for your participation!')
                break
              case 'Event not started':
                setErrorMessage('The voting period has not started yet.')
                break
              case 'recaptcha error':
                setErrorMessage('reCAPTCHA error. Please refresh the page and try again.')
                break
              default:
                setErrorMessage('Authentication failed')
                break
            }
          }

          setLoading(false)
        } catch (error) {
          setError(true)
        }
      }, 10)
    },
  })

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('')
    }
  }, [formik.values.password])

  return (
    <div className='VerifyMethod voter__userLogin' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <MainLayoutBanner />

        <div className='common__form userLogin__form'>
          {/* BODY */}
          <span className='userLogin__form__header__welcomeTxt'>{translate('Login')}</span>
          {/* Password section */}
          <form
            className='userLogin__form__body'
            onSubmit={(e) => {
              e.preventDefault()
              formik.submitForm()
            }}
          >
            <div>
              <div className='mb-2'>
                <TextField
                  formik={formik}
                  type='text'
                  field='username'
                  placeholder={translate('Membership Number')}
                  label={translate('Membership Number')}
                  isRequired={true}
                  view='voter'
                />
              </div>

              <span className='userLogin__form__body__inputBottomTxt'>
                <a
                  // TODO: Clean up code
                  href={
                    currentLanguage === 'English'
                      ? 'https://pipsc.ca/member-tools/find-your-membership-number'
                      : 'https://pipsc.ca/fr/outils-des-membres/numero-de-membre'
                  }
                  className='common__link userLogin__form__body__inputBottomLink'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {translate('Find your Membership Number?')}
                </a>
              </span>

              {/* {!eventId && (
                <span className='userLogin__form__body__inputBottomTxt'>
                  {translate('Forgot username')}
                  <span className='common__link userLogin__form__body__inputBottomLink'>
                    {translate('click here')}
                  </span>
                </span>
              )} */}

              {formik.touched['username'] && formik.errors['username'] && (
                <div className='TextField__voterErrWrap'>
                  <span className='TextField__voterErrTxt' role='alert'>
                    {addPeriodToText(formik.errors['username'])}
                  </span>
                </div>
              )}

              <div className='createPassword__form__body__password__confirmInput'>
                <div className='mb-2'>
                  <TextField
                    formik={formik}
                    type='password'
                    field='password'
                    placeholder={translate('Password')}
                    label={translate('Password')}
                    isRequired={true}
                    view='password'
                  />
                </div>
                {!eventId && (
                  <span className='userLogin__form__body__inputBottomTxt'>
                    {translate('Forgot password')}
                    {'? '}
                    <span
                      className='common__link userLogin__form__body__inputBottomLink'
                      onClick={() => {
                        history.push('/voting/voter-auth/reset-password')
                      }}
                    >
                      {translate('click here')}
                    </span>
                  </span>
                )}

                {formik.touched['password'] && formik.errors['password'] && (
                  <div className='TextField__voterErrWrap'>
                    <span className='TextField__voterErrTxt' role='alert'>
                      {addPeriodToText(formik.errors['password'])}
                    </span>
                  </div>
                )}
              </div>
            </div>

            {error && (
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {addPeriodToText(translate(errorMessage))}
                </span>
              </div>
            )}
          </form>

          {/* FOOTER  */}
          <div className='userLogin__form__footer'>
            <button
              type='button'
              onClick={() => {
                formik.submitForm()
              }}
              className='userLogin__form__footer__btn'
            >
              {translate('Next')}
            </button>
          </div>
        </div>
        <div className='userLogin__help'>
          {translate('Need help?')}
          <div className='userLogin__contact'>
            <a href='mailto:elections@pipsc.ca' target='_blank' rel='noopener noreferrer'>
              {translate('Contact Us')}
            </a>
          </div>
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt400' view='mobile' />
    </div>
  )
}
