/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import dateFormat from 'dateformat'

import './Calendar.scss'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import allLocales from '@fullcalendar/core/locales-all'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {languageValueToKey} from '../../../../../languages/languagekey'

export function Calendar(props) {
  let {events} = props
  let {currentLanguage} = useContext(LanguageContext)
  const {translateObj} = useTranslation()
  const [eventsFormat, setFormatEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const formatEvents = () => {
    setLoading(true)
    console.log('formatting events..')
    let result = []
    // loop through events
    for (let i = 0; i < events.length; i++) {
      let event = events[i]
      // set a new field title with translated event name
      const translatedTitle = translateObj(event.name)
      // set a new field start date
      const startDate = dateFormat(event.openDate, 'yyyy-mm-dd')
      // set a new field end date
      const endDate = dateFormat(event.closeDate, 'yyyy-mm-dd')

      result.push({
        ...event,
        title: translatedTitle,
        start: startDate,
        end: endDate,
      })
    }
    console.log('result', result)
    setFormatEvents(result) // Update the state variable
    setLoading(false)
  }

  useEffect(() => {
    formatEvents()
  }, [events, currentLanguage])

  if (loading) {
    return (
      <div>
        <span> Loading...</span>
      </div>
    )
  }
  return (
    <div className='Calendar'>
      <FullCalendar
        locales={allLocales}
        locale={languageValueToKey[currentLanguage]}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridDay,timeGridWeek,listWeek',
        }}
        initialView='dayGridMonth'
        editable={false}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        // initialEvents={events} // alternatively, use the `events` setting to fetch from a feed
        // select={this.handleDateSelect}
        events={eventsFormat}
        // eventContent={renderEventContent} // custom render function
        eventClick={(e) => props.onSelectEvent(e.event._def.extendedProps)}
        // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
      />
    </div>
  )
}
