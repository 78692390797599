/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import './MEXICO__SuperAdmin__ElectorsList.scss'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
import {ElectorsListTable} from '../../../../voter_admin/pages/nova_scotia/electors_list/ElectorsListTable'
import {ImportAddressList} from '../../../../voter_admin/pages/nova_scotia/address_list/ImportAddressList'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'
import {beforeUnload} from '../../../../../App'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {languageValueToKey} from '../../../../../languages/languagekey'
import {FiPlus} from 'react-icons/fi'

export const initialValues = {}

export const schema = Yup.object().shape({})

export function MEXICO__SuperAdmin__ElectorsList(props) {
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('Electors List')
  const {tiviLink} = useSuperAdminUserContext()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const viewOnly =
    !KeycloakAdminService.hasRole('tivi-root-admin') &&
    !KeycloakAdminService.hasRole('tivi-event-admin')

  const contactInfoRole = KeycloakAdminService.hasRole('tivi-contactinfo')

  const renderView = () => {
    if (currentTab == 'Import List') {
      return <ImportAddressList />
    }
    if (currentTab == 'Electors List') {
      return <ElectorsListTable />
    }
  }

  useEffect(() => {
    renderView()
  }, [currentTab])

  return (
    <>
      <div className='super-admin-elector-list' id='Voter'>
        {/* CONTENT */}

        {/* TABS */}
        <div className='super-admin-elector-list__header'>
          <span className='superAdmin__events__header__txt'>{translate('Members List')}</span>

          {!contactInfoRole && (
            <div className='adminUsers__eventComponent__btnOuter'>
              <div
                className='adminUsers__eventComponent__btn'
                onClick={() => {
                  window.removeEventListener('beforeunload', beforeUnload)
                  window.location.href = `${tiviLink}?locale=${
                    languageValueToKey[LANGUAGE_CONTEXT.currentLanguage] || 'en'
                  }`
                }}
              >
                <FiPlus color='#000' style={{marginRight: 10}} size={20} />
                <span className='adminUsers__eventComponent__btn__txt'>
                  {translate('Add Vote')}{' '}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </>
  )
}
