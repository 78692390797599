import {JumioRegistration} from './Registration'
import {JumioOnboarding} from './Onboarding'
import {JumioLogin} from './Login'

const JumioAuthService = {
  ...JumioRegistration,
  ...JumioOnboarding,
  ...JumioLogin,
}
export default JumioAuthService
