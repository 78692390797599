/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'

import '@djthoms/pretty-checkbox'
import {useTranslation} from '../../../../hooks/useTranslation'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {VoterOnboardingContext} from '../../../../contexts/VoterOnboardingContext'
import {VoterUserContext} from '../../../../contexts/VoterUserContext'
import {VoterLoginFlowContext} from '../../../../contexts/VoterLoginFlowContext'
import VoterAuthService from '../../../../services/auth/VoterAuthService'
import JumioAuthService from '../../../../services/auth/jumio/JumioService'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import RecapatchaDisclaimer from '../../../../resuable/recapatcha/RecapatchaDisclaimer'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'
import {addPeriodToText} from '../../../common/utility_functions/TextUtility'

export function MEXICO__AuthMethodLogin(props) {
  const {translate} = useTranslation()
  const {currentLanguage} = useContext(LanguageContext)

  const history = useHistory()
  const {customBannerImg} = useVoterStyles()
  const {authMethod, setAuthMethod} = useContext(VoterOnboardingContext)
  const [activeSelection, setActiveSelection] = useState(null)
  const [notValid, setNotValid] = useState(true)
  const VoterUser = useContext(VoterUserContext)

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  useEffect(() => {
    // console.log('props', props.history.location.state)
    // setFlow(props.history.location.state)
    // disabled button handler
    if (activeSelection) {
      setNotValid(false)
    }
  }, [activeSelection])

  const onClickNext = async () => {
    let sent
    if (authMethod == 'AuthApp') {
      // forward to that screen
      history.push('/voting/voter-verify/verify')
      return
    } else if (authMethod === 'Email') {
      if (VOTER_LOGIN_CONTEXT.loginFlow === 'Forgot Password') {
        sent = await VoterAuthService.sendEmailCodePasswordResetFlow()
      } else {
        sent = await VoterAuthService.sendEmailCode()
      }
      if (sent.success) {
        history.push('/voting/voter-verify/verify')
      }
    } else if (authMethod === 'FaceId') {
      let sent = await JumioAuthService.startFacedIdLogin()
      if (sent.success) {
        history.push('/voting/voter-auth/jumio', {iframeUrl: sent.iframeUrl, view: 'Login'})
      } else {
        // TODO display error
        return
      }
    } else {
      if (VOTER_LOGIN_CONTEXT.loginFlow === 'Forgot Password') {
        sent = await VoterAuthService.sendSMSCodePasswordResetFlow(currentLanguage)
      } else {
        sent = await VoterAuthService.sendSMSCode(currentLanguage)
      }
      if (sent.success) {
        history.push('/voting/voter-verify/verify')
      }
    }
  }

  return (
    <div className='' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div
          className={
            VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding'
              ? ' authMethod__form'
              : ' authMethodLogin__form'
          }
        >
          {/*  */}

          {/* <div className='authMethod__form__body'></div> */}

          {/* USERNAME INFO SECTION */}
          <div className='authMethod__form__body__usernameWrap' style={{paddingTop: 58}}>
            <div className='authMethod__form__body__usernameWrap__txtWrap'>
              <span className='authMethod__form__body__usernameWrap__txtWrap__label'>
                {translate('Your membership number')}
                {/* if the current language is french then add a space before the colon */}
                <LanguageSpecificColon />
              </span>
              <span className='authMethod__form__body__usernameWrap__txtWrap__value'>
                {VoterUser?.user?.username}
              </span>
            </div>

            {/* <div className='authMethod__form__body__usernameWrap__banner'>
              <img className='' src={toAbsoluteUrl('/media/design/pink_hand.svg')} />
              <span className='authMethod__form__body__usernameWrap__banner__txt'>
                {translate(
                  'you will be asked for this username every time you login to the user dashboard, please write it down and keep in safe place'
                )}
              </span>
            </div> */}
          </div>

          {/* AUTH METHOD SECTION */}
          <div className='authMethod__form__body__methodWrap'>
            <div className='authMethod__form__body__methodWrap__txtWrap'>
              <span className='authMethod__form__body__methodWrap__txtWrap__label'>
                {translate('Choose an authentication method')}
              </span>
            </div>

            <div className='authMethod__form__body__methodWrap__selectWrap'>
              {/* <div
                className='common__radioBtnContainer authMethod__form__body__methodWrap__select--sms'
                onClick={() => {
                  setAuthMethod('FaceId')
                  setActiveSelection('FaceId')
                }}
              >
                <div
                  className={
                    activeSelection == 'FaceId'
                      ? 'common__radioBtnActive'
                      : 'common__radioBtnDefault'
                  }
                ></div>
                <span className='authMethod__form__body__methodWrap__selectTxt'>
                  {translate('I want to login using facial recognition')}
                </span>
              </div> */}
              <div
                className='common__radioBtnContainer authMethod__form__body__methodWrap__select--sms'
                onClick={() => {
                  setAuthMethod('AuthApp')
                  setActiveSelection('AuthApp')
                }}
              >
                <div
                  className={
                    activeSelection == 'AuthApp'
                      ? 'common__radioBtnActive'
                      : 'common__radioBtnDefault'
                  }
                ></div>
                <span className='authMethod__form__body__methodWrap__selectTxt'>
                  {addPeriodToText(
                    translate('I want to login using the Auth App installed on my phone')
                  )}
                </span>
              </div>

              <div
                className='common__radioBtnContainer authMethod__form__body__methodWrap__select--sms'
                onClick={() => {
                  setAuthMethod('Email')
                  setActiveSelection('Email')
                }}
              >
                <div
                  className={
                    activeSelection == 'Email'
                      ? 'common__radioBtnActive'
                      : 'common__radioBtnDefault'
                  }
                ></div>
                <span className='authMethod__form__body__methodWrap__selectTxt'>
                  {addPeriodToText(
                    translate('I want to login using a code sent to my registered email address')
                  )}
                </span>
              </div>
              <div
                className='common__radioBtnContainer authMethod__form__body__methodWrap__select--sms'
                onClick={() => {
                  setAuthMethod('SMS')
                  setActiveSelection('SMS')
                }}
              >
                <div
                  className={
                    activeSelection == 'SMS' ? 'common__radioBtnActive' : 'common__radioBtnDefault'
                  }
                ></div>
                <span className='authMethod__form__body__methodWrap__selectTxt'>
                  {translate('I want to login using an SMS code sent to my phone')}
                </span>
              </div>
            </div>
          </div>

          {/* DISCLAIMER SECTION */}
          <div className='authMethod__form__body__disclaimBannerOuter'>
            <div className='authMethod__form__body__disclaimBanner'>
              <img className='' src={toAbsoluteUrl('/media/design/pink_security_icon.svg')} />
              <span className='authMethod__form__body__disclaimBanner__txt'>
                {translate(
                  "Two-factor authentication adds an extra layer of security to your account. To log-in you'll need to provide a 6 digit security code."
                )}
              </span>
            </div>
          </div>
          {/*  */}
          <div className='phoneVerify__form__actionBar'>
            <button
              className={
                notValid ? 'authMethodLogin__nextBtn disabled' : 'authMethodLogin__nextBtn'
              }
              onClick={onClickNext}
              disabled={notValid}
            >
              {translate('Next')}
            </button>
          </div>
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt800' view='mobile' />
    </div>
  )
}
