import {EventInfo} from '../EventInfo'

import EventDetailHeader from './event_detail_header/EventDetailHeader'
import EventAttachments from './event_attachments/EventAttachments'

const EventDetail = ({currentEvent, setCurrentEvent}) => {
  return (
    <div className='superAdmin__eventDetail'>
      <EventDetailHeader currentEvent={currentEvent} />
      <EventInfo event={currentEvent} updateEvent={setCurrentEvent} />

      <EventAttachments eventId={currentEvent._id} />
    </div>
  )
}

export default EventDetail
