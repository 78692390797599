/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useCallback, useRef} from 'react'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../../helperfunctions/AssetHelpers'
import Modal from 'react-modal'
import './ImportAddressListModal.scss'

// Import FilePond styles
// import 'filepond/dist/filepond.min.css'

import {ModalHeader} from '../../../../../voter/reusable/modals/modal_header/ModalHeader'
import {ModalFooter} from '../../../../../voter/reusable/modals/modal_footer/ModalFooter'
import AdminVoterService from '../../../../../../services/admin/AdminVoterService'
import '../../../../../../pages/modals/AddImageModal.scss'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {districts} from '../../../../../../portalData/Districts'
import {useLocation} from 'react-router-dom'
import {listTypes} from '../../../../../super_admin/mexico/pages/electors_list/EligibleElectorsListTabsContainer'
import LanguageSpecificColon from '../../../../../../components/language_specific_colon/LanguageSpecificColon'
import {addPeriodToText} from '../../../../../common/utility_functions/TextUtility'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
    width: '100%',
    maxWidth: 600,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 20px)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}

const profileDetailsSchema = Yup.object().shape({})

export function ImportAddressListModal(props) {
  const {translate} = useTranslation()
  const location = useLocation()

  const [picPath, setPicturePath] = useState('')
  const [picUrl, setPictureUrl] = useState('')
  const [localFileData, setLocalFileData] = useState(null)

  const [currentView, setCurrentView] = useState('UploadFile')
  const [uploadResponse, setUploadResponse] = useState(null)
  const [uploadErr, setUploadErr] = useState('')
  const [startingCountUsersInDb, setStartingCountUsersInDb] = useState(0)
  const [countUsersInDb, setCountUsersInDb] = useState(0)
  const listType = props.listType

  const uploadRef = useRef(null)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const countUsers = async () => {
    let count
    if (listType === listTypes.eligibleVoters) {
      count = await AdminVoterService.getEligibleVoterCount()
    } else if (listType === listTypes.voterStrikeOff || listType === listTypes.voterDelete) {
      count = 0
    } else {
      count = await AdminVoterService.getVoterCount()
    }
    // @ts-ignore,
    let uploadedCount = count - startingCountUsersInDb
    setCountUsersInDb(uploadedCount)
  }

  const onFileUpload = async (e) => {
    try {
      // here
      //   setIsUploading(true)
      e.persist()
      setUploadResponse(null)

      const file = e.target.files[0]
      setLocalFileData(file)
      console.log('file', file)

      //    If the file size (calculated in byte form) is than 60 MB
      if (file.size > 60 * 1024 * 1024) {
        setUploadErr(translate('IMAGE SIZE MUST BE LESS THAN 60 MB'))
        setCurrentView('fileTooBigErr')
        return console.log('IMAGE SIZE MUST BE LESS THAN 60 MB')
      }
      if (file.type !== 'text/csv') {
        setCurrentView('wrongFileTypeErr')
        return console.log('IMAGE TYPE MUST BE CSV')
      }

      setCurrentView('UploadInProgress')
      let startingCount
      if (listType === listTypes.eligibleVoters) {
        startingCount = await AdminVoterService.getEligibleVoterCount()
      } else if (listType === listTypes.voterStrikeOff || listType === listTypes.voterDelete) {
        startingCount = 0
      } else {
        startingCount = await AdminVoterService.getVoterCount()
      }

      // @ts-ignore
      setStartingCountUsersInDb(startingCount)

      console.log('uploading image now!....')

      // let usr_civik_id = PROFILE_CONTEXT.profile._id

      console.log('starting UPLOAD ========')

      try {
        const formData = new FormData()
        formData.set('voterlist', file)
        let res
        if (listType === listTypes.eligibleVoters) {
          res = await AdminVoterService.uploadEligibleVoterList(formData)
        } else if (listType === listTypes.voterStrikeOff) {
          res = await AdminVoterService.uploadStrikeOffVoterList(formData)
        } else if (listType === listTypes.voterDelete) {
          res = await AdminVoterService.removeIneligibleVoters(formData)
        } else {
          res = await AdminVoterService.uploadVoterList(formData)
        }
        if (res.success) {
          setCurrentView('UploadSuccess')
          setUploadResponse(res)
        } else {
          //TODO
          let errorData = res.error
          let errorMsg = translate(errorData.message)
          if (errorData.data?.duplicates) {
            let dups = errorData.data?.duplicates
            dups = dups.map((u) => `${u.username} ${u.usernameHash}`)

            errorMsg += '\n\n' + dups.join('\n')
          }
          setUploadErr(errorMsg)
          setCurrentView('uploadError')
        }
      } catch (error) {
        setUploadErr(addPeriodToText(translate('Something went wrong, please contact support')))
        setCurrentView('uploadError')

        return console.log(error)
      }

      console.log(URL.createObjectURL(file))

      console.log('done')

      //   setIsUploading(false)
    } catch (error) {
      console.error(error)
      setUploadResponse(null)
    }
  }

  // INE voters uploaded
  const successView = (
    <>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        headerLabel={
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>
              <img
                className='voterAdminModals__thankYou__img__header'
                src={toAbsoluteUrl('/media/design/thumbs_up_green_icon.svg')}
              />
            </div>
            <div style={{marginLeft: 15}}>
              <span className='voterAdminModals__thankYou____header__title__usersAdded'>
                {addPeriodToText(translate('Electors list database has been updated successfully'))}
              </span>
            </div>
          </div>
        }
      />
      <div style={{margin: 30, fontSize: 18, fontWeight: 500}}>
        <div>
          <img
            style={{width: 30, height: 34}}
            src={toAbsoluteUrl('/media/design/filefingerprint.png')}
          />
          <span style={{marginLeft: 20}}>{translate('File Fingerprint (SHA-256)')}</span>
        </div>

        <div
          style={{
            backgroundColor: '#F2F3F7',
            display: 'flex',
            wordBreak: 'break-word',
            margin: '20px 0',
            padding: 15,
          }}
        >
          {uploadResponse?.listUploaded?.SHA256 || uploadResponse?.deleteListUploaded?.SHA256}
        </div>
        {/* {uploadResponse?.listUploaded?.votersAddedMap &&
          Object.keys(uploadResponse?.listUploaded?.votersAddedMap).map((district) => (
            <div style={{borderTop: '1px solid #eef2f5', margin: '30px -30px'}}>
              <div style={{margin: '20px 30px'}}>
                {translate('Total voters uploaded for')}{' '}
                {districts.find((o) => o.value === district)?.label || district}:
                <div
                  style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}
                >
                  {uploadResponse?.listUploaded?.votersAddedMap &&
                    Object.keys(uploadResponse?.listUploaded?.votersAddedMap[district]).map(
                      (channel) => (
                        <div style={{flex: 1, marginTop: 12}}>
                          <span style={{color: '#6C7289'}}>{translate(channel)}:</span>{' '}
                          <span
                            style={{
                              color: channel === 'online' ? '#3699FF' : '#F1416C',
                              textTransform: 'capitalize',
                            }}
                          >
                            {uploadResponse?.listUploaded?.votersAddedMap[district][channel]}
                          </span>
                          <div></div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          ))} */}

        {uploadResponse?.totalUsersAdded ? (
          <div style={{borderTop: '1px solid #eef2f5', margin: '30px -30px'}}>
            <div style={{margin: '20px 30px'}}>
              <span className='voterAdminModals__thankYou__subtitle__usersAdded'>
                {translate('Total voters uploaded')}
                {': '}
                {uploadResponse?.totalUsersAdded}
              </span>
            </div>
          </div>
        ) : uploadResponse?.totalUsersDeleted ? (
          <div style={{borderTop: '1px solid #eef2f5', margin: '30px -30px'}}>
            <div style={{margin: '20px 30px'}}>
              <span className='voterAdminModals__thankYou__subtitle__usersAdded'>
                {translate('Total voters removed')}
                {': '}
                {uploadResponse?.totalUsersDeleted}
              </span>
            </div>
          </div>
        ) : null}

        {/* {uploadResponse?.errors && uploadResponse?.errors.length ? (
          <div style={{borderTop: '1px solid #eef2f5', margin: '30px -30px'}}>
            <div style={{margin: '20px 30px'}}>
              {uploadResponse?.errors.map((usersError) => (
                <span className='voterAdminModals__thankYou__subtitle__usersMessages'>
                  {translate('Error adding users from rows')} {usersError.errorRows}.
                </span>
              ))}
              <span
                className='voterAdminModals__thankYou__subtitle__usersMessages'
                style={{color: 'red'}}
              >
                {translate('Please try uploading those users again')}.
              </span>
            </div>
          </div>
        ) : null} */}
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  /* VIEW #1:UPLOAD FILE  */
  const uploadFileView = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={props.onRequestClose} headerLabel={translate('Electors List')} />
      <div>
        <div className='addImageModal__uploadContainer' onClick={onImageClick}>
          <img src={toAbsoluteUrl('/media/design/icon_pink_upload.svg')} />
          <h4 className='addImageModal__uploadTxt'>{translate('File Uploader')}</h4>
          <span className='addImageModal__uploadSubTxt'>
            {/* Drag & Drop files here 5MB Max (jpg, gif, png) */}
            {/* Note temp until drag and drop */}

            {translate('Click to upload file. 60 MB Max (csv)')}
          </span>
        </div>

        <input type='file' onChange={onFileUpload} ref={uploadRef} style={{display: 'none'}} />
      </div>
    </>
  )

  /* VIEW #2: UPLOAD IN PROGRESS   */
  const inProgressView = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={props.onRequestClose} headerLabel={translate('Electors List')} />
      <div className='importAddrListM__inProgress'>
        <span className='importAddrListM__inProgress__title'>
          {translate('Uploading in progress...')}
        </span>
        <span className='importAddrListM__inProgress__subtitle'>
          {translate('Total voters uploaded')}
          <LanguageSpecificColon /> {countUsersInDb}
          {/* {translate('Please wait.. do not close this window!')} */}
        </span>

        <div style={{marginTop: 20, cursor: 'pointer'}}>
          <div className='adminUsers__newUser__btn' style={{width: 'auto'}} onClick={countUsers}>
            <span className='adminUsers__newUser__btn__txt'>{translate('Refresh')}</span>
          </div>
        </div>
      </div>
    </>
  )

  /* VIEW #3: UPLOADED SUCCESS (CONFIRM SCREEN) */
  // const uploadSuccessView = (
  //   <>
  //     {/* HEADER */}
  //     <ModalHeader onRequestClose={props.onRequestClose} headerLabel={'Electors List'} />
  //     <div className='importAddrListM__uploadSuccess'>
  //       <span className='importAddrListM__uploadSuccess__title'>
  //         Electors list has been uploaded successfuly
  //       </span>
  //       <span className='importAddrListM__uploadSuccess__subtitle'>
  //         Do you want to update the registration portal with the new imported list?
  //       </span>

  //       <div className='importAddrListM__uploadSuccess__btnWrap'>
  //         <button
  //           onClick={() => {
  //             // SAVE FILE
  //             // close modal
  //             props.onRequestClose()
  //           }}
  //           type='button'
  //           className='importAddrListM__uploadSuccess__btn importAddrListM__uploadSuccess__noBtn'
  //         >
  //           NO
  //         </button>
  //         <button
  //           onClick={() => {
  //             props.onRequestClose()
  //             props.onUpdateAddressModal()
  //           }}
  //           type='button'
  //           className='importAddrListM__uploadSuccess__btn importAddrListM__uploadSuccess__yesBtn'
  //         >
  //           YES
  //         </button>
  //       </div>
  //     </div>
  //   </>
  // )

  const fileTooBigErr = (
    <>
      {/* HEADER */}
      <ModalHeader
        onRequestClose={props.onRequestClose}
        headerLabel={translate('Uploading Error')}
      />
      <div className='importAddrListM__fileTooBigErr'>
        <img
          className='importAddrListM__fileTooBigErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
        />
        <span className='importAddrListM__fileTooBigErr__txt'>
          {translate('The file is too large to upload. The maximum supported file size is 60 MB')}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const wrongFileTypeErr = (
    <>
      {/* HEADER */}
      <ModalHeader
        onRequestClose={props.onRequestClose}
        headerLabel={translate('Uploading Error')}
      />
      <div className='importAddrListM__wrongFileTypeErr'>
        <img
          className='importAddrListM__wrongFileTypeErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
        />
        <span className='importAddrListM__wrongFileTypeErr__txt'>
          {translate(
            'The file couldn’t be uploaded because it isn’t in the supported format or the file might be corrupted'
          )}
        </span>

        <span className='importAddrListM__wrongFileTypeErr__subTxt'>
          {translate('Please try again using supported file type. Accepted file format is')}
          <span className='civik-primary-blue'> CSV</span>.{' '}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  /* VIEW #2: UPLOAD IN PROGRESS   */
  const errorView = (
    <>
      {/* HEADER */}
      <ModalHeader
        onRequestClose={props.onRequestClose}
        headerLabel={translate('Uploading Error')}
      />
      <div className='importAddrListM__wrongFileTypeErr'>
        <img
          className='importAddrListM__wrongFileTypeErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
        />
        <span className='importAddrListM__wrongFileTypeErr__txt' style={{whiteSpace: 'pre-wrap'}}>
          {uploadErr}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const resetModal = () => {
    try {
      setPictureUrl(null)
      setPicturePath(null)
      setCurrentView('UploadFile')
      setStartingCountUsersInDb(0)
      setCountUsersInDb(0)
    } catch (error) {
      console.log(error)
    }
  }

  const renderView = () => {
    if (currentView == 'UploadFile') {
      return uploadFileView
    }
    if (currentView == 'UploadInProgress') {
      return inProgressView
    }
    if (currentView == 'UploadSuccess') {
      return successView
    }
    // ERRORS
    if (currentView == 'fileTooBigErr') {
      return fileTooBigErr
    }
    if (currentView == 'wrongFileTypeErr') {
      return wrongFileTypeErr
    }
    if (currentView === 'uploadError') {
      return errorView
    }
  }

  //   useEffect(() => {
  //     renderView()
  //   }, [currentView])

  useEffect(() => {
    resetModal()
  }, [props.onRequestClose])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#ImportAddressList')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form className=''>{renderView()}</form>
    </Modal>
  )
}
