import {useState} from 'react'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../helperfunctions/AssetHelpers'
import _ from 'lodash' // Import lodash library
import {useTranslation} from '../../hooks/useTranslation'

const Banner = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e7ed;
  margin-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 6px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const Content = styled.div`
  margin-top: 25px;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`

const ChevronIcon = styled.img<{isOpen: boolean}>`
  transition: transform 0.3s ease; /* Add transition for smooth rotation */
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`

const DropdownBanner = ({status, children, isOpenByDefault}) => {
  const {translate} = useTranslation()
  const [isOpen, setIsOpen] = useState(isOpenByDefault)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='dropdown-banner'>
      <Banner onClick={toggleDropdown}>
        <div className='dropdown-banner__banner__t-wrap'>
          {/* indicator */}
          <div
            className={`dropdown-banner__banner__t-wrap__indicator dropdown-banner__banner__t-wrap__indicator--${status.toLowerCase()}`}
          />
          {/* status */}
          <div className='dropdown-banner__banner__t-wrap__title'>{translate(status)}</div>
        </div>

        <ChevronIcon
          alt='chevron down icon'
          src={toAbsoluteUrl('/media/design/chevron-down.svg')}
          isOpen={isOpen}
        />
      </Banner>
      {isOpen && <Content>{children}</Content>}
    </div>
  )
}

export default DropdownBanner
