import React from 'react'
import {toAbsoluteUrl} from '../_metronic/helpers'
import './ModalHeaderWithLabel.scss'

interface ModalHeaderWithLabelProps {
  label: string
  onClose?: () => void // Making onClose prop optional
}

export const ModalHeaderWithLabel: React.FC<ModalHeaderWithLabelProps> = ({label, onClose}) => {
  return (
    <div className='ModalHeaderWithLabel'>
      <span className='modalHeaderWithLabel__label'>{label}</span>

      {onClose && typeof onClose === 'function' && (
        <img
          alt='Close'
          src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
          className='civik-modal-x-btn'
          onClick={onClose}
        />
      )}
    </div>
  )
}
