import {useState, useEffect} from 'react'
import moment from 'moment'

// check if the event state has changed to started
// or if the event end date has passed
// show & hide vote now button accordingly
// returns boolean of event open
const useCheckVotingOpenStateChange = ({event, fetchEvent = null, closeDate}) => {
  const [loading, setLoading] = useState(true)
  const [votingOpen, setVotingOpen] = useState(false)

  // check if event state has changed to started at scheduled event open time
  const checkVoteStarted = async () => {
    if (!fetchEvent) {
      return
    }
    if (event.state === 'finalized') {
      let now = moment()
      let open = moment(event.openDate)
      let dif = open.diff(now, 'seconds')
      // 9 seconds seems to be how long it takes for the api to update the event state
      if (!votingOpen && dif <= -9 && dif > -12) {
        await fetchEvent()
      }
    }
  }

  const checkEventState = () => {
    let totalSecsLeftInEvent = moment(closeDate).diff(moment(), 'seconds')
    // event has ended, voting is now closed,
    // event state may not be updated yet, but vote now button should be hidden
    if (totalSecsLeftInEvent < 0 && votingOpen) {
      setVotingOpen(false)
      // the vote now button is not visible and the event has started and their is time left in the event
      // show the vote now button, set voting to open
    } else if (event.state === 'started' && !votingOpen && totalSecsLeftInEvent > 0) {
      setVotingOpen(true)
      // if the event state is not started, set voting to not open, hide vote now button
    } else if (event.state !== 'started' && votingOpen) {
      setVotingOpen(false)
    }
    checkVoteStarted()
    setLoading(false)
  }

  useEffect(() => {
    const interval = setInterval(checkEventState, 1000)
    return () => clearInterval(interval)
  }, [])

  return {loading, votingOpen}
}

export default useCheckVotingOpenStateChange
