export const STATE_STATUSES = {
  active: 'active',
  upcoming: 'upcoming',
  closed: 'closed',
}

export const EVENT_STATE_STATUS_LABEL = {
  active: 'Voting is open',
  upcoming: 'Voting is not open',
  closed: 'Voting is closed',
}

export const COUNTDOWN_LABEL = {
  active: 'Voting closes in',
  upcoming: 'Voting opens in',
  closed: 'Voting closed on',
}

export const getEventStateStatus = (eventState, votingOpen) => {
  if (votingOpen == null) {
    return
  }

  if (votingOpen) {
    return STATE_STATUSES.active
  } else if (eventState === 'started' && !votingOpen) {
    return STATE_STATUSES.closed
  } else if (['created', 'finalized'].includes(eventState)) {
    return STATE_STATUSES.upcoming
  } else if (['ended', 'decrypting', 'decrypted', 'tallied'].includes(eventState)) {
    return STATE_STATUSES.closed
  }
}
