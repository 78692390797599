/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from 'react-modal'
import './DeleteFileModal.scss'
import {toAbsoluteUrl} from '../../../helperfunctions/AssetHelpers'
import {ModalHeader} from '../../modals/ModalHeader'
import {useTranslation} from '../../../hooks/useTranslation'
import {CustomBtn} from '../custom_btn/CustomBtn'

const modalStyling = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

// Each view should be in a seperate file
export function DeleteFileModal({modalOpen, onClose, onDelete}) {
  const {translate} = useTranslation()
  return (
    <Modal ariaHideApp={false} isOpen={modalOpen} onRequestClose={onClose} style={modalStyling}>
      <div className='ModalWrapper'>
        <ModalHeader
          customStyles={{padding: '23px 25px'}}
          onRequestClose={onClose}
          title={translate('Delete File')}
        />
        <div className='delete-file-modal'>
          <img
            className='delete-file-modal__warning-icon'
            alt='warning icon red'
            src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
          />
          <span className='delete-file-modal__title'>
            {translate('Are you sure you want to delete this file?')}
          </span>
        </div>

        <div className='ModalFooter'>
          <CustomBtn
            view='SecondaryBtn'
            text={translate('Cancel')}
            type='button'
            height={47}
            onClick={onClose}
          />

          <CustomBtn
            view='ActionBtn'
            text={translate('Delete')}
            type='button'
            height={47}
            onClick={onDelete}
          />
        </div>
      </div>
    </Modal>
  )
}
