import {Redirect, Route, Switch} from 'react-router-dom'

import {NOVASCOTIA__Dashboard} from '../pages/voter_admin/pages/nova_scotia/dashboard/NOVASCOTIA__Dashboard'
import {AdminAddressList} from '../pages/voter_admin/pages/nova_scotia/address_list/AdminAddressList'
import {AdminElectorsList} from '../pages/voter_admin/pages/nova_scotia/electors_list/AdminElectorsList'

import {MEXICO__Dashboard} from '../pages/private/voter/dashboard/MEXICO__Dashboard'
import {MEXICO__MyProfile} from '../pages/private/voter/my_profile/MEXICO__MyProfile'
import {MEXICO__ChangePassword} from '../pages/private/voter/change_password/MEXICO__ChangePassword'
import {useVoterStyles} from '../contexts/VoterStylesContext'
import RegenQRCodeVoter from '../pages/private/voter/my_profile/components/regenQRcode/RegenQRCodeVoter'
import {MEXICO__SuperAdmin__HelpAndSupport} from '../../app/pages/super_admin/help_and_support/MEXICO__SuperAdmin__HelpAndSupport'
import {beforeUnload} from '../App'
import {useEffect} from 'react'

export function VoterAdminRoutes() {
  const {voterStyleView} = useVoterStyles()

  const MEXICO_ROUTES = (
    <Route path='/voting/voter'>
      <Switch>
        <Route path='/voting/voter/dashboard' component={MEXICO__Dashboard} />
        <Route path='/voting/voter/my-profile' component={MEXICO__MyProfile} />
        <Route path='/voting/voter/change-password' component={MEXICO__ChangePassword} />
        <Route path='/voting/voter/regen-qr-code' component={RegenQRCodeVoter} />
        <Route path='/voting/voter/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route path='*' render={() => <Redirect to='/voting/voter/dashboard' />} />
      </Switch>
    </Route>
  )

  const NEUVOTE_ROUTES = (
    <Route path='/voting/voter'>
      <Switch>
        <Route path='/voting/voter/dashboard' component={MEXICO__Dashboard} />
        <Route path='/voting/voter/my-profile' component={MEXICO__MyProfile} />
        <Route path='/voting/voter/change-password' component={MEXICO__ChangePassword} />
        <Route path='/voting/voter/regen-qr-code' component={RegenQRCodeVoter} />
        <Route path='/voting/voter/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route path='*' render={() => <Redirect to='/voting/voter/dashboard' />} />
      </Switch>
    </Route>
  )

  const NWT_ROUTES = (
    <Route path='/voting/voter'>
      <Switch>
        <Route path='/voting/voter/dashboard' component={MEXICO__Dashboard} />
        <Route path='/voting/voter/my-profile' component={MEXICO__MyProfile} />
        <Route path='/voting/voter/change-password' component={MEXICO__ChangePassword} />
        <Route path='/voting/voter/regen-qr-code' component={RegenQRCodeVoter} />
        <Route path='/voting/voter/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route path='*' render={() => <Redirect to='/voting/voter/dashboard' />} />
      </Switch>
    </Route>
  )

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
  }, [])

  const renderRoutes = () => {
    console.log('voterStyleView', voterStyleView)
    if (voterStyleView == 'mexico') {
      return MEXICO_ROUTES
    } else if (voterStyleView == 'nwt') {
      return NWT_ROUTES
    } else {
      //else, render nova scotia routes
      return NEUVOTE_ROUTES
    }
  }

  return <Switch>{renderRoutes()}</Switch>
}
