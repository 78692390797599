import moment from 'moment-timezone'
import 'moment/locale/fr-ca'
import configOptions from '../../utils/configOptions'
import {DateTime} from 'luxon'

export const formatDateLocale = (datetime, LANGUAGE_CONTEXT) => {
  datetime = moment(datetime)
  if (configOptions.timeZoneDateString) {
    datetime = moment(datetime).tz(configOptions.timeZoneDateString)
  }
  if (LANGUAGE_CONTEXT.currentLanguage === 'French') {
    moment.locale('fr-ca')
    let dateFormatted = moment(datetime).locale('fr-ca').format('lll')
    return dateFormatted.replace(':', ' h ') //Aug 16, 2018 8h02 PM
  }
  if (LANGUAGE_CONTEXT.currentLanguage === 'English') {
    return moment(datetime).locale('en').format('lll') //Aug 16, 2018 8:02 PM
  }
}

export const getFormattedCurrentTime = (currentLanguage) => {
  let currentTime = DateTime.now()
  if (configOptions.timeZoneDateString) {
    currentTime = DateTime.now().setZone(configOptions.timeZoneDateString)
  }
  let hours = currentTime.hour
  let minutes = currentTime.minute
  let full_time = hours + ':' + (minutes < 10 ? '0' + minutes : minutes)
  if (currentLanguage === 'French') {
    full_time = full_time.replace(':', ' h ')
  }
  return full_time
}

export const formatDateLocaleShort = (datetime, LANGUAGE_CONTEXT) => {
  if (configOptions.timeZoneDateString) {
    datetime = moment(datetime).tz(configOptions.timeZoneDateString)
  }
  datetime = moment(datetime).format('DD-MM-YY HH:mm') //24-05-24 22:33
  if (LANGUAGE_CONTEXT.currentLanguage === 'French') {
    return datetime.replace(':', ' h ') //24-05-24 22h33
  }
  return datetime
}
