import {useEffect} from 'react'
import {useTranslation} from '../../hooks/useTranslation'

const RecapatchaDisclaimer = (props) => {
  let {view, addClassName} = props

  const {translate} = useTranslation()

  if (view == 'mobile') {
    return (
      <span className={'recaptcha__disclaimerMobile' + ' ' + addClassName}>
        {translate('This site is protected by reCAPATCHA and the Google')}{' '}
        <a href='https://policies.google.com/privacy' className='civik-primary-blue'>
          {translate('Privacy Policy')}
        </a>{' '}
        {translate('and')}{' '}
        <a href='https://policies.google.com/terms' className='civik-primary-blue'>
          {translate('Terms of Service')}
        </a>{' '}
        {translate('apply')}.
      </span>
    )
  }

  return (
    <span className='recaptcha__disclaimer'>
      {translate('This site is protected by reCAPATCHA and the Google')}{' '}
      <a href='https://policies.google.com/privacy' className='civik-primary-blue'>
        {translate('Privacy Policy')}
      </a>{' '}
      {translate('and')}{' '}
      <a href='https://policies.google.com/terms' className='civik-primary-blue'>
        {translate('Terms of Service')}
      </a>{' '}
      {translate('apply')}.
    </span>
  )
}

export default RecapatchaDisclaimer
