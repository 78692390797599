import {useState} from 'react'
import '../AddDocumentModals.scss'
import './DocumentInputUpload.scss'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
import TextField from '../../../../../modules/auth/reusable/TextField'
import UploadedDocument from '../uploaded_doc/UploadedDocument'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import FileUploaderModal from '../../../../reusable/modals/FileUploaderModal'

const DocumentInputUpload = ({
  formik,
  textFieldName,
  documentFieldName = null,
  label,
  style = {},
  onCallback = (data) => {},
}) => {
  const {translate} = useTranslation()
  const [localFileData, setLocalFileData] = useState(null)
  const [openModal, setModalOpen] = useState(false)

  const addDocBtn = (
    <div
      className='addDocument__upload'
      onClick={() => {
        setModalOpen(true)
      }}
    >
      <div className='addDocument__upload__iconWrap'>
        <img src={toAbsoluteUrl('/media/design/icon_add_img_pink.svg')} alt='upload' />
        <span className='addDocument__upload__txt'>{translate('Add Document')}</span>
      </div>
      <span>25MB {translate('Max')}</span>
    </div>
  )

  return (
    <>
      <div style={style}>
        <TextField
          formik={formik}
          type='text'
          field={textFieldName}
          placeholder={translate(label)}
          label={translate(label)}
          view='voter'
          onChange={formik.handleChange} // Use formik.handleChange to update the formik state
        />

        {localFileData ? (
          <UploadedDocument
            localFile={localFileData}
            onChangeFile={() => {
              console.log('openning modal')
              setLocalFileData(null)
              setModalOpen(true)
            }}
          />
        ) : (
          <>
            {openModal ? (
              <>
                <div className={`doc-input-upload__uploader-header`}>
                  <CustomBtn
                    view='PrimaryBtn--grey'
                    text='Close'
                    height={30}
                    width={100}
                    customStyling={{}}
                    onClick={() => {
                      setModalOpen(false)
                    }}
                  />
                </div>

                <FileUploaderModal
                  onClose={() => {}}
                  setUploadErr={() => {}}
                  customStyling={{height: 200, paddingTop: 20, paddingLeft: 50, paddingRight: 50}}
                  onCallback={(res) => {
                    setLocalFileData({name: res.data.name, file: res.data.file})

                    formik.setFieldValue(documentFieldName, {
                      name: res.data.name,
                      file: res.data.file,
                    })
                  }}
                />
              </>
            ) : (
              addDocBtn
            )}{' '}
          </>
        )}

        {/* <FormikError formik={formik} fieldName={field} errorLabel='Error' /> */}
      </div>
    </>
  )
}
export default DocumentInputUpload
