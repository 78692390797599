import React from 'react'
import EventList from '../../../../../../components/event_list/EventList'
import {toAbsoluteUrl} from '../../../../../../helperfunctions/AssetHelpers'
import {useTranslation} from '../../../../../../hooks/useTranslation'

const EventsDisplaySection = ({events, onSelectEvent = (event: any) => {}}) => {
  const {translate} = useTranslation()
  const eventsPresent = events.active?.length || events.ended?.length || events.upcoming?.length

  return (
    <>
      <div className='dashboard__body'>
        {eventsPresent ? (
          <div className='w-100'>
            <EventList events={events?.active} status={'Active'} onSelectEvent={onSelectEvent} />
            <EventList
              events={events?.upcoming}
              status={'Upcoming'}
              onSelectEvent={onSelectEvent}
            />
            <EventList events={events?.ended} status={'Closed'} onSelectEvent={onSelectEvent} />
          </div>
        ) : (
          <div className='superAdmin__events__content'>
            <div className='superAdmin__events__content__noEvent'>
              <img
                alt='x calendar icon'
                className='superAdmin__events__content__noEvent__icon'
                src={toAbsoluteUrl('/media/design/icon_pink_x_calendar.svg')}
              />
              <span className='superAdmin__events__content__noEvent__txt'>
                {translate('No events yet')}.
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default EventsDisplaySection
