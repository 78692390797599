import React from 'react'
import styled from 'styled-components'
import {useVoterStyles} from '../../contexts/VoterStylesContext'
import {useTranslation} from '../../hooks/useTranslation'
import LanguageSpecificColon from '../language_specific_colon/LanguageSpecificColon'

const TimerContainer = styled.span`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const TimerLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #6c7289;
`

const TimerWrap = styled.div`
  display: flex;
  flex-flow: row;
  align-items: baseline;
`

const TimerValue = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.color}; /* Use color prop */
`

const TimerMinTxt = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.color}; /* Use color prop */
  margin: 0 5px;
`

const TimerSecTxt = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.color}; /* Use color prop */
  margin-left: 5px;
`

interface LogoutTimerProps {
  sessionTimeRemaining: string
}

const LogoutTimer: React.FC<LogoutTimerProps> = ({sessionTimeRemaining}) => {
  const {primaryColor} = useVoterStyles()
  const {translate} = useTranslation()

  if (!sessionTimeRemaining || sessionTimeRemaining.includes('NaN')) {
    return null
  }

  const minutes =
    sessionTimeRemaining.substring(0, 1) !== '0'
      ? sessionTimeRemaining.substring(0, 2)
      : sessionTimeRemaining.substring(1, 2)

  const minText = sessionTimeRemaining.substring(2, 6)
  const seconds = sessionTimeRemaining.substring(6, 9)
  const secText = sessionTimeRemaining.substring(9, 13)

  return (
    <TimerContainer>
      <TimerLabel>
        {translate('Time left in session')}
        <LanguageSpecificColon />
      </TimerLabel>
      <TimerWrap>
        <TimerValue color={primaryColor}>{minutes}</TimerValue>
        <TimerMinTxt color={primaryColor}>{minText}</TimerMinTxt>
        <TimerValue color={primaryColor}>{seconds}</TimerValue>
        <TimerSecTxt color={primaryColor}>{secText}</TimerSecTxt>
      </TimerWrap>
    </TimerContainer>
  )
}

export default LogoutTimer
