const removeFromList = (list, indexToRemove, setErrorList) => {
  list.splice(indexToRemove, 1)
  setErrorList(list)
}

export const passwordValidateFunction = (
  password,
  setErrorList,
  MIN_LENGTH_PW,
  errorList,
  passwordReqs
) => {
  let list = errorList

  // ERROR #1 - Minimum 8 characters in length -----------------
  if (password.length >= MIN_LENGTH_PW) {
    let indexToRemove = list.indexOf(passwordReqs.minLength)
    // if the value is in the list
    if (indexToRemove !== -1) {
      removeFromList(list, indexToRemove, setErrorList)
    }
  }

  if (password.length < MIN_LENGTH_PW) {
    //check if it not there already
    if (!list.includes(passwordReqs.minLength)) {
      list.push(passwordReqs.minLength)
      setErrorList(list)
    }
  }

  // ERROR #2 - At least one uppercase letter -----------------
  let upperCaseRegex = /[A-Z]/g
  if (password.match(upperCaseRegex)) {
    let indexToRemove = list.indexOf(passwordReqs.upperCase)
    // if the value is in the list
    if (indexToRemove !== -1) {
      list.splice(indexToRemove, 1)
      setErrorList(list)
    }
  }

  if (!password.match(upperCaseRegex)) {
    //check if it not there already
    if (!list.includes(passwordReqs.upperCase)) {
      list.push(passwordReqs.upperCase)
      setErrorList(list)
    }
  }

  // ERROR #3 - At least one lowercase letter -----------------
  let lowerCaseRegex = /[a-z]/g
  if (password.match(lowerCaseRegex)) {
    let indexToRemove = list.indexOf(passwordReqs.lowerCase)
    // if the value is in the list
    if (indexToRemove !== -1) {
      list.splice(indexToRemove, 1)
      setErrorList(list)
    }
  }

  if (!password.match(lowerCaseRegex)) {
    //check if it not there already
    if (!list.includes(passwordReqs.lowerCase)) {
      list.push(passwordReqs.lowerCase)
      setErrorList(list)
    }
  }

  // ERROR #4 - At least one number letter -----------------
  let numberRegex = /\d/

  if (numberRegex.test(password)) {
    let indexToRemove = list.indexOf(passwordReqs.digit)
    // if the value is in the list
    if (indexToRemove !== -1) {
      list.splice(indexToRemove, 1)
      setErrorList(list)
    }
  }

  if (!numberRegex.test(password)) {
    //check if it not there already
    if (!list.includes(passwordReqs.digit)) {
      list.push(passwordReqs.digit)
      setErrorList(list)
    }
  }

  let specialCharRegex = /\W/

  if (specialCharRegex.test(password)) {
    let indexToRemove = list.indexOf(passwordReqs.specialChar)
    // if the value is in the list
    if (indexToRemove !== -1) {
      list.splice(indexToRemove, 1)
      setErrorList(list)
    }
  }

  if (!specialCharRegex.test(password)) {
    //check if it not there already
    if (!list.includes(passwordReqs.specialChar)) {
      list.push(passwordReqs.specialChar)
      setErrorList(list)
    }
  }
}
