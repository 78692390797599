import React, {useContext} from 'react'
import {LanguageContext} from '../../contexts/LanguageContext'

const LanguageSpecificColon = () => {
  const {currentLanguage} = useContext(LanguageContext)

  return <span>{currentLanguage === 'French' ? ' :' : ':'}</span>
}

export default LanguageSpecificColon
