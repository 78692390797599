import React from 'react'
import {toAbsoluteUrl} from '../../helperfunctions/AssetHelpers'
import {useVoterStyles} from '../../contexts/VoterStylesContext'

const MainLayoutBanner = () => {
  const {customBannerImg} = useVoterStyles()

  return (
    <img
      alt='banner img'
      className='voterRegister__topBanner'
      src={toAbsoluteUrl(customBannerImg)}
    />
  )
}

export default MainLayoutBanner
