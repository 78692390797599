import {useEffect, useState} from 'react'
import './EventAttachments.scss'
import EventAttachmentsHeader from './components/EventAttachmentsHeader'
import {EventAttachModal} from './components/EventAttachModal'
import KeycloakAdminService from '../../../../../../../KeycloakAdminService'
import AdminVoterAuthService from '../../../../../../services/auth/AdminVoterAuthService'
import VoterAuthService from '../../../../../../services/auth/VoterAuthService'
import {AddLinkModal} from '../../../../../reusable/add_link_modal/AddLinkModal'
import Attachments from '../../../../../../components/attachments/Attachments'

import {
  isAdmin,
  isViewOnlyEventHelpManagement,
} from '../../../../../common/utility_functions/AuthUtility'
import CustomSpinner from '../../../../../../components/spinner/Spinner'

const EventAttachments = ({eventId}) => {
  const [modalView, setModalView] = useState('Add Document')
  const [addDocModal, setDocModal] = useState(false)
  const [addLinkModal, setAddLinkModal] = useState(false)
  const [listOfDocs, setListOfDocs] = useState([])
  const [loading, setLoading] = useState(true)

  const viewOnly = isViewOnlyEventHelpManagement()

  const getAttachments = async () => {
    try {
      let attachments
      // TODO: LOADING
      if (isAdmin()) {
        attachments = await AdminVoterAuthService.getHelpDocsEvent(eventId)
      } else {
        attachments = await VoterAuthService.getHelpDocsEvent(eventId)
      }
      if (attachments) {
        console.log(attachments)
        setListOfDocs(attachments)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getAttachments()
  }, [eventId])

  if (loading) {
    return <CustomSpinner />
  }

  return (
    <div id='event-attachment-modal' className='event-attachments'>
      {!viewOnly && (
        <EventAttachModal
          modalOpen={addDocModal}
          onRequestClose={() => {
            setDocModal(false)
          }}
          view={modalView}
          eventId={eventId}
          getAttachments={getAttachments}
        />
      )}

      {!viewOnly && (
        <AddLinkModal
          modalOpen={addLinkModal}
          onRequestClose={() => {
            setAddLinkModal(false)
          }}
        />
      )}

      <EventAttachmentsHeader
        setModalView={setModalView}
        setDocModal={setDocModal}
        setAddLinkModal={setAddLinkModal}
        viewOnly={viewOnly}
        listOfDocs={listOfDocs}
      />

      {/* Attachments */}
      <Attachments listOfDocs={listOfDocs} getAttachments={getAttachments} viewOnly={viewOnly} />
    </div>
  )
}

export default EventAttachments
