import {createContext, useContext, useEffect, useState} from 'react'
import {LanguageContext} from './LanguageContext'

export const VoterStylesContext = createContext()

export const VoterStylesProvider = ({children}) => {
  const [customBtnClass, setCustomBtnClass] = useState(null)
  const [customTxtClass, setCustomTxtClass] = useState(null)
  const [customLogoImg, setCustomLogoImg] = useState(null)
  const [customMobileLogoImg, setCustomMobileLogoImg] = useState(null)
  const [customAdminLogoImg, setCustomAdminLogoImg] = useState(null)
  const [customVoterLogoImg, setCustomVoterLogoImg] = useState(null)
  const [customBannerImg, setCustomBannerImg] = useState(null)
  const [voterStyleView, setVoterStyleView] = useState('pipsc')
  const [primaryColor, setPrimaryColor] = useState('#0065A4')

  const {currentLanguage} = useContext(LanguageContext)

  /** OPTIONS:
   * '' = default(Nova Scotia)
   * 'mexico' = INE
   * 'neuvote'  = neuvote
   * */

  // update voter styling
  useEffect(() => {
    if (voterStyleView == 'pipsc') {
      let isEnglish = currentLanguage == 'English'

      setCustomBtnClass('voterStylesContext__btn')
      setCustomTxtClass('voterStylesContext__txt')
      setCustomLogoImg(
        `${
          isEnglish
            ? '/media/design/pipsc-logo-blue_fl-e.png'
            : '/media/design/pipsc-logo-blue_fl-f.png'
        }`
      )
      setCustomVoterLogoImg(
        `${
          isEnglish
            ? '/media/design/pipsc-logo-blue_fl-e.png'
            : '/media/design/pipsc-logo-blue_fl-f.png'
        }`
      )
      setCustomMobileLogoImg(
        `${
          isEnglish
            ? '/media/design/pipsc-logo-blue_fl-e.png'
            : '/media/design/pipsc-logo-blue_fl-f.png'
        }`
      )
      setCustomAdminLogoImg(
        `${
          isEnglish
            ? '/media/design/pipsc_admin_logo.svg'
            : '/media/design/PIPSC_admin_logo_FRENCH.svg'
        }`
      )
      setCustomBannerImg('/media/design/voter_banner_pipsc.png')
    }
  }, [voterStyleView, currentLanguage])

  const clearVoterStylesContext = () => {
    setCustomTxtClass(null)
    setCustomBtnClass(null)
  }

  return (
    <VoterStylesContext.Provider
      value={{
        customAdminLogoImg,
        primaryColor,
        voterStyleView,
        setVoterStyleView,
        customBtnClass,
        customTxtClass,
        customLogoImg,
        customMobileLogoImg,
        customBannerImg,
        customVoterLogoImg,
        setCustomTxtClass,
        setCustomBtnClass,
        setCustomLogoImg,
        setCustomVoterLogoImg,
        setCustomMobileLogoImg,
        clearVoterStylesContext,
      }}
    >
      {children}
    </VoterStylesContext.Provider>
  )
}

const PROVIDER_ERROR = 'useVoterStyles must be used within a VoterStylesProvider'

export const useVoterStyles = () => {
  const context = useContext(VoterStylesContext)
  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default VoterStylesContext
