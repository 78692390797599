import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import './AdminSidebarMobile.scss'
import '../Common.scss'
import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import Modal from 'react-modal'
import {VoterLoginFlowContext} from '../../../../contexts/VoterLoginFlowContext'
import KeycloakService from '../../../../../Keycloak'
import {VoterUserContext} from '../../../../contexts/VoterUserContext'
import KeycloakAdminService from '../../../../../KeycloakAdminService'
import VoterAuthService from '../../../../services/auth/VoterAuthService'
import AdminVoterAuthService from '../../../../services/auth/AdminVoterAuthService'
import {useTranslation} from '../../../../hooks/useTranslation'
import '../../../voter_admin/reusable/admin_sidebar/AdminSidebar.scss'
import configOptions from '../../../../../utils/configOptions'
import LanguagesDropdown from '../../../../components/languages_dropdown/LanguagesDropdown'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'
import useCurrentTime from '../../../../hooks/useCurrentTime'
import SideBarItem from '../sidebar_item/SideBarItem'

const customStyles = {
  content: {
    borderRadius: '4px',
    border: 'none',
    width: 299,
    height: '100%',
    top: 0,
    left: 0,
    padding: 0,
    maxWidth: 400,
  },

  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99999999,
  },
}

export function AdminSidebarMobile(props) {
  const {translate} = useTranslation()

  let {sideNavbarOpen, setSideNavbarOpen} = props

  const history = useHistory()
  const [currentTab, setCurrentTab] = useState('Dashboard')

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  const VoterUser = useContext(VoterUserContext)
  const {customMobileLogoImg} = useVoterStyles()

  const currentTime = useCurrentTime()

  const TABS = {
    elections: {
      path: '/voting/voter/dashboard',
      tabValue: 'Dashboard',
      tabLabel: 'Elections',
      iconName: 'Elections',
      hasPermissionToView: true,
    },

    help: {
      path: '/voting/voter/help-support',
      tabValue: 'Help & Support',
      tabLabel: 'Support',
      iconName: 'Help & Support',
      hasPermissionToView: true,
    },

    myAccount: {
      path: '/voting/voter/my-profile',
      tabValue: 'My Account',
      tabLabel: 'My Account',
      iconName: 'My Account',
      hasPermissionToView: true,
    },
  }

  useEffect(() => {
    // if the currentTab changes then close the sidebar
    if (currentTab) {
      setSideNavbarOpen(false)
    }
  }, [currentTab])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={sideNavbarOpen}
      onRequestClose={() => {
        setSideNavbarOpen(false)
      }}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <div className='AdminSidebarMobile'>
        {/* HEADER */}
        <div className='adminSidebarM__header'>
          <img className='adminSidebarM__header__logo' src={customMobileLogoImg} alt='logo' />

          <img
            alt='x icon'
            onClick={() => {
              setSideNavbarOpen(false)
            }}
            className='adminSidebarM__header__xIcon'
            src={toAbsoluteUrl('/media/design/icon_x.svg')}
          />
        </div>

        <div className='adminSidebarM__bodyFooterContainer'>
          {/* BODY */}
          <div className='adminSidebarM__body'>
            <span className='adminSidebarM__body__title'>
              {translate('Hello')},{' '}
              <span className='common__fw700'>
                {VoterUser?.user?.firstName} {VoterUser?.user?.lastName}
              </span>
            </span>
            <div className='adminSideBar__tabContainer adminSidebarM__body__tabContainer'>
              {Object.entries(TABS).map(([tabkey, tabValue]) => {
                return (
                  <SideBarItem
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    {...tabValue}
                  />
                )
              })}
            </div>
            <div className='adminSidebarM__adminDropdownWrapper--languages'>
              <LanguagesDropdown />
            </div>
          </div>

          <div>
            <span className='adminSidebarM__currentTimeTxt'>
              <span>
                {' '}
                {translate(configOptions.currentTimeInString)}
                <LanguageSpecificColon />
              </span>
              <span className='common__fw700'>{currentTime}</span>
            </span>

            {/* FOOTER */}
            <div className='adminSidebarM__footer'>
              <button
                className='adminSidebarM__footer__logoutBtn'
                onClick={async () => {
                  try {
                    if (KeycloakService.isLoggedIn()) {
                      VOTER_LOGIN_CONTEXT.setLoginFlow('Login')
                      await VoterAuthService.logOut(history)
                    }
                    if (KeycloakAdminService.isLoggedIn()) {
                      VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Login')
                      await AdminVoterAuthService.logOut(history)
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                {translate('Logout')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
