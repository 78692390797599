import {useContext, useEffect, useState} from 'react'
// import {WorkerProxy, WorkerProxyConf} from '@tivi/core-ts'
/**
 * To use tivi add    "@tivi/core-ts": "file:src/vendor/tivi-core-ts-0.0.1.tgz", as a dependency in package.json
 * and add this script under scripts     "postinstall": "sh scripts/pherom.sh",
 */

import {LanguageContext} from '../../contexts/LanguageContext'
import {useHistory, useLocation} from 'react-router-dom'
import KeycloakService from '../../../Keycloak'
import {useTranslation} from '../../hooks/useTranslation'

import {EventVoteCard} from '../private/voter/dashboard/components/event_vote_card/EventVoteCard'
import EventAttachments from '../private/super_admin/events/event_detail/event_attachments/EventAttachments'
import {BackBtn} from '../reusable/back_btn/BackBtn'
import useMobileView from '../../hooks/UseMobileView'

type StateProps = {
  event: any
  hasVoted: boolean
  receipt?: String
}

export const VoteReceipt = () => {
  const {translate} = useTranslation()
  const {currentLanguage} = useContext(LanguageContext)
  const isMobileView = useMobileView()

  // const [workerProxy, setWorkerProxy] = useState<WorkerProxy | null>(null)
  const {state} = useLocation<StateProps>()
  const event = state?.event ? state.event : null

  const history = useHistory()

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  // useEffect(() => {
  //   try {
  //     const workerProxyConf: WorkerProxyConf = {
  //       workerLocation: './workers/worker.js',
  //       oneKeyIdURL: '',
  //       oneKeyAuthURL: '',
  //       clsURLTemplate: '',
  //       pbbURLTemplate: '',
  //       randomURL: '',
  //       sessionURL: '',
  //       submitURLTemplate: '',
  //       elections: {},
  //     }
  //     let newWorkerProxy = new WorkerProxy(workerProxyConf)
  //     setWorkerProxy(newWorkerProxy)
  //   } catch (error) {}
  // }, [])

  async function checkReceipt(receipt, contestIndex = 0) {
    try {
      const verifyURL =
        event?.questions && event?.questions.length
          ? event.questions[contestIndex]?.voteVerifyLink
          : event?.voteVerifyLink

      // let data = await workerProxy.isVoteVerified(receipt, verifyURL)
      // if (data) {
      //   // console.log(data)
      //   // data ? alert('valid') : alert('invalid')
      //   return true
      // }
      return false
    } catch (err) {
      console.log('error validating', err)
      // alert('error')
      return false
    }
  }
  if (!event || !KeycloakService.isLoggedIn) {
    history.replace('/voting/voter-login')
  }

  const Header = (
    <div className={'flex-row-center'}>
      <BackBtn
        customStyling={
          isMobileView && LANGUAGE_CONTEXT.currentLanguage == 'English'
            ? {position: 'absolute', left: '10px'}
            : {}
        }
      />

      <span
        className={`common__pg__header__title ml-10 ${isMobileView && 'shared-mobile-pg-title'}`}
      >
        {translate('Event Details')}
      </span>
    </div>
  )

  return (
    <div
      className={
        // TODO: fix, not compatible with multiple languages
        LANGUAGE_CONTEXT.currentLanguage !== 'English'
          ? 'MEXICO__Dashboard--spanish'
          : 'MEXICO__Dashboard'
      }
    >
      <div className='common__pg__header'>{Header}</div>

      <div
        className={
          LANGUAGE_CONTEXT.currentLanguage !== 'English'
            ? 'dashboard__eventVoteCard--spanish'
            : 'dashboard__eventVoteCard'
        }
      >
        {isMobileView && Header}
        {event && (
          <EventVoteCard
            event={event}
            hasVoted={state.hasVoted}
            checkReceipt={checkReceipt}
            receiptParam={state.receipt}
          />
        )}
        {event && <EventAttachments eventId={event.id} />}
      </div>
    </div>
  )
}
export default VoteReceipt
