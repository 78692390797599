import React, {Component} from 'react'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helperfunctions/AssetHelpers'
import './TextField.scss'

class SearchTextFieldNoFormik extends Component {
  render() {
    const {
      formik,
      type,
      field,
      placeholder,
      label,
      isRequired,
      noLabel,
      view,
      disabled,
      customStyling,
      value,
    } = this.props

    return (
      <div className='TextField__voter TextField__adminSearchBar' style={customStyling}>
        <img
          className='TextField__adminSearchBarImg'
          src={toAbsoluteUrl('/media/design/admin_search_icon.svg')}
          alt='search'
        />
        <input
          placeholder={placeholder}
          className={clsx('TextField__adminSearchBar__inner')}
          type={'text'}
          name={field}
          autoComplete='off'
          disabled={disabled}
          onChange={this.props.onChange}
          value={value}
        />
      </div>
    )
  }
}

export default SearchTextFieldNoFormik
