import {useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
import {useTranslation} from '../../../../../hooks/useTranslation'

const UploadedDocument = ({localFile, onChangeFile}) => {
  const {translate} = useTranslation()

  const getFileType = (filename) => {
    let extension = filename.split('.').pop()
    return extension
  }

  useEffect(() => {
    console.log('localFile', localFile)
  }, [localFile])

  return (
    <>
      <div
        className='addDocument__changeDoc'
        onClick={() => {
          console.log('change file')
          onChangeFile()
        }}
      >
        <img src={toAbsoluteUrl('/media/design/icon_removeImage_pink.svg')} alt='remove' />
        <span className='addDocument__upload__txt'>{translate('Change Document')}</span>
      </div>
      <div className='addDocument__upload addDocument__changeDoc__upload'>
        <div className='addDocument__upload__iconWrap'>
          {getFileType(localFile.name) === 'pdf' && (
            <img className='' src={toAbsoluteUrl('/media/design/icon_pdf.svg')} alt='pdf' />
          )}
          {getFileType(localFile.name) === 'jpg' ||
            (getFileType(localFile.name) === 'jpeg' && (
              <img className='' src={toAbsoluteUrl('/media/design/icon_jpg.svg')} alt='jpg' />
            ))}
          {getFileType(localFile.name) === 'png' && (
            <img className='' src={toAbsoluteUrl('/media/design/icon_png.svg')} alt='png' />
          )}
          {getFileType(localFile.name) === 'doc' ||
            (getFileType(localFile.name) === 'docx' && (
              <img className='' src={toAbsoluteUrl('/media/design/icon_doc.svg')} alt='doc, docx' />
            ))}
          {['mov', 'mp4'].includes(getFileType(localFile.name)) && (
            <img className='' src={toAbsoluteUrl('/media/design/icon_video.svg')} alt='mov, mp4' />
          )}
          {['mp3', 'wav'].includes(getFileType(localFile.name)) && (
            <img className='' src={toAbsoluteUrl('/media/design/icon_audio.svg')} alt='mp3, wav' />
          )}
          <span className='addDocument__upload__txt'>{localFile.name}</span>
        </div>{' '}
      </div>
    </>
  )
}

export default UploadedDocument
