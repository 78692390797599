// LanguagesDropdown.js
import React, {useState, useRef, useEffect, useContext} from 'react'
import {LanguageContext} from '../../contexts/LanguageContext'
import LANGUAGES from '../../pages/common/constants/LanguagesConstant'
import {toAbsoluteUrl} from '../../helperfunctions/AssetHelpers'
import KeycloakService from '../../../Keycloak'
import VoterAuthService from '../../services/auth/VoterAuthService'
import KeycloakAdminService from '../../../KeycloakAdminService'
import AdminVoterService from '../../services/admin/AdminVoterService'
import {FiChevronDown} from 'react-icons/fi'
import {useTranslation} from '../../hooks/useTranslation'

const LanguagesDropdown = ({customStyling = {}}) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const dropdownOptionsRef = useRef(null)
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const {translate} = useTranslation()

  const DropdownOptions = () => (
    <div className='navbar__dropdown adminDropdown__options--languages' ref={dropdownOptionsRef}>
      <div className='civik-flex-column dropdown__txtWrap'>
        <div className='civik-flex-column'>
          {LANGUAGES.map((topic, index) => (
            <div
              key={index}
              className='adminDropdown__optionWrap'
              onClick={() => {
                LANGUAGE_CONTEXT.setCurrentLangauge(topic.value)
                localStorage.setItem('language', topic.value)
                setOpenDropdown(false)
                if (KeycloakService.isLoggedIn()) {
                  VoterAuthService.updateUserLanguage(topic.value)
                }
                if (KeycloakAdminService.isLoggedIn()) {
                  AdminVoterService.updateUserLanguage(topic.value)
                }
              }}
            >
              <span className='adminDropdown__option__txt'>{topic.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  const DropdownValue = () => (
    <div
      style={customStyling}
      className='adminDropdown--languages'
      onClick={() => setOpenDropdown(true)}
    >
      <img
        src={toAbsoluteUrl('/media/design/language-icon.svg')}
        alt='language icon'
        style={{marginRight: 5}}
      />

      <span className='adminDropdown__option__txt'>{translate('Language')}</span>

      <FiChevronDown style={{marginLeft: 5}} size={20} />
    </div>
  )

  const handleClickOutside = (event) => {
    if (dropdownOptionsRef.current && !dropdownOptionsRef.current.contains(event.target)) {
      setOpenDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className='adminDropdown__container--languages'>
      <DropdownValue />
      {openDropdown && <DropdownOptions />}
    </div>
  )
}

export default LanguagesDropdown
