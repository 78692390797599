/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useContext} from 'react'

import Modal from 'react-modal'
import * as Yup from 'yup'

import './VotingReceiptModals.scss'
import {useFormik} from 'formik'
import useMobileView from '../../../../../../hooks/UseMobileView'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import TextField from '../../../../../../modules/auth/reusable/TextField'
import {ModalBtn} from '../../../../../modals/voter_modal/ModalBtn'
import {toAbsoluteUrl} from '../../../../../../helperfunctions/AssetHelpers'
import {VoterModalHeader} from '../../../../../modals/voter_modal/VoterModalHeader'
import SelectField from '../../../../../../modules/auth/reusable/SelectField'
import customStyles from '../../../../../../constants/ModalStyles'
import {addPeriodToText} from '../../../../../common/utility_functions/TextUtility'

const initialValues = {
  verifyCode: '',
  contest: 0,
}

const loginSchema = Yup.object().shape({
  verifyCode: Yup.string().required('Password is required'),
})

export function VotingReceiptModals(props) {
  const isMobileView = useMobileView()

  const {translate, translateObj} = useTranslation()

  const {modalView, setModalView, headerLabel, setHeaderLabel, receiptParam, questions} = props

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      // setLoading(true)
      setTimeout(async () => {
        try {
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  useEffect(() => {
    if (!receiptParam) {
      formik.setFieldValue('verifyCode', '')
    }
  }, [props.isOpen])

  useEffect(() => {
    if (receiptParam) {
      formik.setFieldValue('verifyCode', receiptParam)
    }
  }, [receiptParam])

  const questionsSelect = questions.map((c, i) => {
    return {label: translateObj(c.texts), value: i}
  })

  const VotingReceipt = (
    <>
      <div className='VotingReceiptM__receipt'>
        <span className='votingReceiptM__receipt__txt'>
          {translate(
            `Select the desired contest, enter the verification code for the selected contest, and then click Verify`
          )}
        </span>
        {/* TODO: styling */}
        <div className='votingReceiptM__input'>
          <SelectField
            label={translate('Select contest')}
            formik={formik}
            type='text'
            field='contest'
            isRequired={false}
            options={questionsSelect || []}
            noPlaceholderValue={true}
            view='voter'
            customStyling={{width: isMobileView ? 250 : 370}}
          />
        </div>
        <div className='votingReceiptM__input votingReceiptM__input--verifyCode'>
          <TextField
            label={translate('Verify Code')}
            formik={formik}
            type='text'
            field='verifyCode'
            placeholder={translate('Verify Code')}
            isRequired={false}
            noLabel={true}
            view='voter'
            customStyling={{width: isMobileView ? 250 : 370}}
          />{' '}
        </div>
      </div>

      <div className='ModalFooter'>
        <ModalBtn
          view='CancelBtn'
          text='Close'
          onClick={() => {
            props.onRequestClose()
            formik.setFieldValue('verifyCode', '')
            setHeaderLabel(translate('Verify Vote Receipt'))
          }}
        />

        <ModalBtn
          view='ActionBtn'
          text='Verify'
          onClick={async () => {
            if (formik.values.verifyCode) {
              setHeaderLabel(translate('Voting verification'))
              // SET this VotingValid (if valid) or VotingInvalid (if invalid)
              let valid = await props.checkReceipt(formik.values.verifyCode, formik.values.contest)
              if (valid) {
                setModalView('VotingValid')
              } else {
                setModalView('VotingInvalid')
              }
              if (receiptParam) {
                sessionStorage.removeItem('receipt')
                sessionStorage.removeItem('eventId')
              }
            }
          }}
        />
      </div>
    </>
  )

  const VotingValid = (
    <>
      <div className='VotingReceiptM__valid'>
        <div className='votingReceiptM__valid__code'>
          <span className='votingReceiptM__valid__code__label'>
            {' '}
            {translate('Verification code')}{' '}
          </span>
          <div className='votingReceiptM__valid__code__content'>
            <span className='votingReceiptM__valid__code__content__codeVal'>
              {formik.values?.verifyCode?.substring(0, 18)}...
            </span>
            <div className='votingReceiptM__valid__code__content__outcome'>
              <img src={toAbsoluteUrl('/media/design/icon_circleCheck_green.svg')} />
              <span className='votingReceiptM__valid__code__content__outcome__txt'>
                {' '}
                {translate('Valid')}
              </span>
            </div>
          </div>
        </div>

        <div className='votingReceiptM__valid__status__content'>
          <span className='votingReceiptM__valid__status__content__label'>
            {' '}
            {translate('Voting status')}
          </span>
          <div className='votingReceiptM__valid__status__content__outcome'>
            <img
              alt='circle check icon'
              src={toAbsoluteUrl('/media/design/icon_check_circle_green.svg')}
            />
            <span className='votingReceiptM__valid__status__content__outcome__txt'>
              {addPeriodToText(translate('This vote was cast successfully'))}{' '}
            </span>
          </div>
        </div>
        {/* <div className='votingReceiptM__valid__status__date'>
          <span className='votingReceiptM__valid__status__date__label'>Issue Date and time:</span>
          <span className='votingReceiptM__valid__status__date__value'>
            March 23, 2023 4:32 p.m.
          </span>
        </div> */}
      </div>
      <div className='ModalFooter'>
        <ModalBtn
          view='CancelBtn'
          text='Close'
          onClick={() => {
            props.onRequestClose()
            formik.setFieldValue('verifyCode', '')
            setHeaderLabel(translate('Verify Vote Receipt'))
          }}
        />

        <ModalBtn
          view='ActionBtn'
          text='OK'
          onClick={() => {
            props.onRequestClose()
            formik.setFieldValue('verifyCode', '')
            setHeaderLabel(translate('Verify Vote Receipt'))
          }}
        />
      </div>
    </>
  )

  const VotingInvalid = (
    <>
      <div className='VotingReceiptM__valid'>
        <div className='votingReceiptM__valid__code'>
          <span className='votingReceiptM__valid__code__label'>
            {' '}
            {translate('Verification code')}
          </span>
          <div className='votingReceiptM__valid__code__content'>
            <span className='votingReceiptM__valid__code__content__codeVal'>
              {formik.values?.verifyCode?.substring(0, 18)}...
            </span>
            <div className='votingReceiptM__valid__code__content__outcome'>
              <img src={toAbsoluteUrl('/media/design/icon_x_circle_red.svg')} width='20' />
              <span className='votingReceiptM__invalid__code__content__outcome__txt'>
                {translate('Invalid')}
              </span>
            </div>
          </div>
        </div>

        <div className='votingReceiptM__valid__status__content'>
          <span className='votingReceiptM__valid__status__content__label'>
            {translate('Voting status')}{' '}
          </span>
          <div className='votingReceiptM__valid__status__content__outcome'>
            <img src={toAbsoluteUrl('/media/design/icon_x_circle_red.svg')} />

            <div className='votingReceiptM__valid__status__content__outcomeTxtContainer'>
              <span className='votingReceiptM__valid__status__content__outcome__txt'>
                {addPeriodToText(
                  translate('The provided code is wrong or the voter has not cast their vote yet')
                )}{' '}
              </span>

              <span className='votingReceiptM__valid__status__content__outcome__desc'>
                {addPeriodToText(
                  translate(
                    'Ensure that you have chosen the correct contest and entered the corresponding verification code accurately'
                  )
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='ModalFooter'>
        <ModalBtn
          view='CancelBtn'
          text={translate('Close')}
          onClick={() => {
            props.onRequestClose()
            formik.setFieldValue('verifyCode', '')
            setHeaderLabel(translate('Verify Vote Receipt'))
          }}
        />

        <ModalBtn
          view='ActionBtn'
          text={translate('OK')}
          onClick={() => {
            props.onRequestClose()
            formik.setFieldValue('verifyCode', '')
            setHeaderLabel(translate('Verify Vote Receipt'))
          }}
        />
      </div>
    </>
  )

  const renderView = (modalView) => {
    if (modalView == 'VotingReceipt') {
      return VotingReceipt
    }

    if (modalView == 'VotingValid') {
      return VotingValid
    }

    if (modalView == 'VotingInvalid') {
      return VotingInvalid
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        <VoterModalHeader onRequestClose={props.onRequestClose} headerLabel={headerLabel} />

        {/* BODY CONTENT */}
        {renderView(modalView)}
      </div>
    </Modal>
  )
}
