/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
// import {AdminSidebar} from '../../../reusable/admin_sidebar/AdminSidebar'
// import './EventInfo.scss'
import './FilterModal.scss'

import {ModalHeader} from '../../../../modals/ModalHeader'

import Modal from 'react-modal'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'

import CustomAdminDropdown from '../../../reusable/admin_dropdown/AdminFormDropDown'
import {useTranslation} from '../../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    width: '1139px',
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}
export const initialValues = {}

export function EmailSendModal(props) {
  const {translate} = useTranslation()

  let {headerLabel, isOpen, onRequestClose, options} = props

  const [eventId, setEventId] = useState('')

  const {customBtnClass} = useVoterStyles()

  const [modalIsOpen, setIsOpen] = useState(false)

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className=''>
        <ModalHeader onRequestClose={props.onRequestClose} title={translate('Send Emails')} />

        {/* BODY CONTENT */}
        <div className='FilterModal'>
          <CustomAdminDropdown
            isRequired={false}
            options={options}
            value={eventId}
            setValue={setEventId}
            title={translate('Event')}
            isForm={false}
          />
        </div>

        <div className='ModalFooter'>
          <button
            type='submit'
            className={'filterModal__cancelBtn '}
            onClick={props.onRequestClose}
          >
            {translate('Cancel')}
          </button>

          <button
            type='submit'
            className={
              customBtnClass
                ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
                : 'modalFooter__primaryBtn'
            }
            onClick={() => {
              props.onSend(eventId)
            }}
          >
            {translate('Send Emails')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
