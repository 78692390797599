// EventList.js
import React from 'react'
import {EventDisplayCard} from '../event_display_card/EventDisplayCard'

import DropdownBanner from '../dropdown_banner/DropdownBanner'
import {useTranslation} from '../../hooks/useTranslation'
import {isAdmin} from '../../pages/common/utility_functions/AuthUtility'

const EventList = ({events = [], status, onSelectEvent = (event: any) => {}}) => {
  const {translate} = useTranslation()

  return (
    <DropdownBanner status={status} isOpenByDefault={events.length > 0}>
      {events.length > 0 ? (
        <>
          {events.map((event) => (
            <EventDisplayCard
              key={event.id}
              event={event}
              title={event.name}
              closeDate={event.closeDate}
              view={!isAdmin() ? 'voter' : 'admin'}
              onSelectEvent={onSelectEvent}
            />
          ))}
        </>
      ) : (
        <h2>{translate(`There are no ${status.toLowerCase()} events at the moment`)}.</h2>
      )}
    </DropdownBanner>
  )
}

export default EventList
