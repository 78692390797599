// src/components/Attachments/NoAttachmentsMessage.js
import React from 'react'
import {useVoterStyles} from '../../../contexts/VoterStylesContext'
import {useTranslation} from '../../../hooks/useTranslation'
import styled from 'styled-components'
import {addPeriodToText} from '../../../pages/common/utility_functions/TextUtility'
import {FiSlash} from 'react-icons/fi'

const NoAttachmentsMessage = () => {
  const {primaryColor} = useVoterStyles()
  const {translate} = useTranslation()

  return (
    <StyledNoAttachmentsMessage>
      <FiSlash color={primaryColor} size={28} />
      <span>{addPeriodToText(translate('No attachments added yet'))}</span>
    </StyledNoAttachmentsMessage>
  )
}

const StyledNoAttachmentsMessage = styled.div`
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    font-size: 18px;
    color: #181c32; /* Assuming you have a theme setup */
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
  }
`

export default NoAttachmentsMessage
