import React from 'react'
import {useTranslation} from '../../hooks/useTranslation'
import useCountdown from '../../hooks/useCountdown'
import LanguageSpecificColon from '../language_specific_colon/LanguageSpecificColon'

const CountdownTimerSmall = ({countDownDate, title}) => {
  const {translate} = useTranslation()
  const {days, hours, minutes, seconds} = useCountdown(countDownDate)

  return (
    <div className='dashboard__votingStatusWrap'>
      <div className='eventDisplayCard__body__content__vtClosed__label'>
        {translate(title)}
        <LanguageSpecificColon />
      </div>
      <div className='eventDisplayCard__body__content__vtClosed__value'>
        {days} <span className='common__fw400'>{translate('day(s)')}</span> {hours}{' '}
        <span className='common__fw400'>{translate('h')}</span> {minutes}{' '}
        <span className='common__fw400'>{translate('min')}</span> {seconds}{' '}
        <span className='common__fw400'>{translate('s')}</span>
      </div>
    </div>
  )
}

export default CountdownTimerSmall
