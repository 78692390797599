import {useContext, useState} from 'react'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
// import './AdminSidebar.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import {
  isContactInfoRoleAdmin,
  isEventModuleAdmin,
  isMonitoringAdmin,
  isSuperAdmin,
} from '../../../../../KeycloakAdminService'
import {useTranslation} from '../../../../hooks/useTranslation'
import SideBarItem from '../sidebar_item/SideBarItem'

export function SuperAdmin__Sidebar(props) {
  const {translate} = useTranslation()

  const {customLogoImg} = useVoterStyles()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const TABS = {
    events: {
      path: '/admin/dashboard',
      tabValue: 'Events',
      tabLabel: 'Elections',
      iconName: 'Elections',
      hasPermissionToView: isSuperAdmin() || isEventModuleAdmin() || isMonitoringAdmin(),
    },
    electorsList: {
      path: '/admin/electors-list',
      tabValue: 'Electors List',
      tabLabel: 'Members List',
      iconName: 'Electors List',
      hasPermissionToView: isSuperAdmin() || isEventModuleAdmin() || isContactInfoRoleAdmin(),
    },
    adminUsers: {
      path: '/admin/admin-users',
      tabValue: 'Admin Users',
      tabLabel: 'Admin Users',
      iconName: 'Admin Users',
      hasPermissionToView: isSuperAdmin() || isContactInfoRoleAdmin(),
    },
    help: {
      path: '/admin/help-support',
      tabValue: 'Help & Support',
      tabLabel: 'Support',
      iconName: 'Help & Support',
      hasPermissionToView: isSuperAdmin(),
    },
    myAccount: {
      path: '/admin/my-profile',
      tabValue: 'My Account',
      tabLabel: 'My Account',
      iconName: 'My Account',
      hasPermissionToView: true,
    },
    reports: {
      path: '/admin/reports',
      tabValue: 'Reports',
      tabLabel: 'Reports',
      iconName: 'Reports',
      hasPermissionToView: isSuperAdmin() || isMonitoringAdmin() || isContactInfoRoleAdmin(),
    },
  }
  const [currentTab, setCurrentTab] = useState(TABS.events.tabValue)

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English' ? 'AdminSidebar--french' : 'AdminSidebar'
      }
    >
      <div className='adminSidebar__logo__container'>
        <img
          className='adminSidebar__logo'
          src={toAbsoluteUrl(customLogoImg)}
          alt='Elections Logo'
        />
        <div className='adminSidebar__adminLabel'>{translate('Admin')}</div>
      </div>

      <hr className='AdminSidebar__divider'></hr>

      <div className='adminSideBar__tabContainer'>
        {Object.entries(TABS).map(([tabkey, tabValue]) => {
          return <SideBarItem currentTab={currentTab} setCurrentTab={setCurrentTab} {...tabValue} />
        })}
      </div>
    </div>
  )
}
