import React from 'react'
import {useTranslation} from '../../hooks/useTranslation'
import LanguageSpecificColon from '../language_specific_colon/LanguageSpecificColon'
import useCountdown from '../../hooks/useCountdown'

const CountdownTimerBig = ({title = 'Voting closes in', countDownDate, layout}) => {
  const {days, hours, minutes, seconds} = useCountdown(countDownDate)
  const {translate} = useTranslation()
  return (
    <div
      className={`eventVoteCard__footer__contentWrap ${
        layout !== 'row' ? 'eventVoteCard__footer__contentWrap-vertical' : ''
      }`}
    >
      <span
        className={`eventVoteCard__footer__title
      ${layout !== 'row' ? 'eventVoteCard__footer__title-vertical' : ''} `}
      >
        {translate(title)}
        <LanguageSpecificColon />
      </span>

      <div className='common__flex__row'>
        <TimeCard label={translate('Day(s)')} value={days} />
        <span className='eventVoteCard__footer__timeDivider'>:</span>
        <TimeCard label={translate('Hours')} value={hours} />
        <span className='eventVoteCard__footer__timeDivider'>:</span>
        <TimeCard label={translate('Minutes')} value={minutes} />
        <span className='eventVoteCard__footer__timeDivider'>:</span>
        <TimeCard label={translate('Seconds')} value={seconds} />
      </div>
    </div>
  )
}

const TimeCard = ({label, value}) => {
  return (
    <div className='eventVoteCard__footer__timeCardWrap'>
      <span className='eventVoteCard__footer__timeCard__smallLabel'>{label}</span>
      <div className='eventVoteCard__footer__timeCard'>
        <span className='eventVoteCard__footer__timeCard__value'>{value}</span>
      </div>
    </div>
  )
}

export default CountdownTimerBig
