const configOptions = {
  timeZoneDateString: '',
  currentTimeInString: 'Current Time',
  shortTimeString: '',
  disableImportElectors: true,
  registrationEnabled: false,
  customSecurityAnswer: 'PIPSC membership number.',
  securityAnswerLength: 4,
  usernameStartsWith: '5555',
  voteReceiptEnabled: false, // todo
}
export default configOptions
