/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

// import React, {FC} from 'react'
import {useState, useEffect, useContext} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import KeycloakService from '../../Keycloak'
import {LanguageContext} from '../contexts/LanguageContext'

import VoterAuthService from '../services/auth/VoterAuthService'
import {languageKeyToValue} from '../languages/languagekey'
import {beforeUnload} from '../App'
import ROUTE_PATHS from '../routing/RoutePaths'
import {checkBrowserLanguagePreference} from '../helperfunctions/LanguageHelpers'

export const useVoterKeyCloakAuth = ({adminView, setAdminView, user, setUser}) => {
  // so admin view means logged in in keycloak for voter
  const [initialized, setInitialized] = useState(false)

  const {setCurrentLangauge, currentLanguage} = useContext(LanguageContext)

  const history = useHistory()

  const clearLocalSession = () => {
    window.removeEventListener('beforeunload', beforeUnload)
    setAdminView(false)
    if (
      !window.location.pathname.includes('/voting/voter-login/') &&
      !window.location.pathname.startsWith('/voting/voter-onboarding/phone-verify') &&
      !window.location.pathname.includes('/voting/voter-auth/reset-password')
      // &&
      // !window.location.pathname.includes('/voting/voter-auth/status') &&
      // !window.location.pathname.includes(ROUTE_PATHS.LANDING) &&
      // !window.location.pathname.includes('/voting/register')
    ) {
      history.push(ROUTE_PATHS.HOME)
      setUser({votingChannel: '', username: ''})
    }
    if (window.location.pathname.includes('/voting/register-complete')) {
      history.push(ROUTE_PATHS.HOME)
      setUser({votingChannel: '', username: ''})
    }
  }

  const checkAuth = async () => {
    if (initialized && KeycloakService?.isLoggedIn()) {
      const parsedToken = KeycloakService.getParsedToken()

      if (parsedToken) {
        let user = await VoterAuthService.getUserInfo()
        if (user) {
          setUser({
            ...user,
            // @ts-ignore
            votingChannel: user.votingChannel ? user.votingChannel : parsedToken.votingChannel,
            username: parsedToken.preferred_username,
          })
          // @ts-ignore
          if (user.votingChannel === 'online') {
            setAdminView(true)
            let receipt = sessionStorage.getItem('receipt')
            if (receipt) {
              let eventId = sessionStorage.getItem('eventId')
              let event = await VoterAuthService.getEventById(eventId)
              if (event) {
                //@ts-ignore
                let hasVoted = user?.votedInEvents?.includes(eventId)
                history.push('/election', {event, hasVoted, receipt})
              } else {
                history.push('/voting/voter/dashboard')
              }
            } else {
              history.push('/voting/voter/dashboard')
            }
            window.addEventListener('beforeunload', beforeUnload)
          }
        }
      }
      // recheck language once all auth redirects are complete
      checkLanguage()
    }
    if (initialized && !KeycloakService?.isLoggedIn()) {
      clearLocalSession()
    }
    if (initialized && KeycloakService?.isLoggedIn() && user?.votingChannel === 'inPerson') {
      window.removeEventListener('beforeunload', beforeUnload)
      setAdminView(false)
      history.push(ROUTE_PATHS.HOME)
    }
  }
  const checkVoteReceiptPath = () => {
    //http://localhost:3011/checkvotereceipt?eventId=testlaura5&receipt=abcdef
    if (window.location.pathname.startsWith('/checkvotereceipt')) {
      var url = new URL(window.location.href)
      var receipt = url.searchParams.get('receipt')
      var eventId = url.searchParams.get('eventId')
      sessionStorage.setItem('receipt', receipt)
      sessionStorage.setItem('eventId', eventId)
      if (!KeycloakService?.isLoggedIn()) {
        history.push(ROUTE_PATHS.HOME)
      }
    }
  }

  useEffect(() => {
    const initializeSSO = async () => {
      if (!initialized) {
        KeycloakService.initKeycloak(() => {
          setInitialized(true)
        })
      }
      setTimeout(() => {
        setInitialized(true)
      }, 5000)
    }
    initializeSSO()
  }, [])

  useEffect(() => {
    checkAuth()
  }, [initialized, KeycloakService?.isLoggedIn()])

  useEffect(() => {
    // checkVoteReceiptPath()
    checkTiviLanguage()
    checkLanguage()
  }, [])

  // set language saved in app
  const setSavedLanguage = (language) => {
    setCurrentLangauge(language)
    localStorage.setItem('language', language)
    if (KeycloakService.isLoggedIn()) {
      VoterAuthService.updateUserLanguage(language)
    }
  }

  const checkTiviLanguage = () => {
    // save local url param in session storage so it will persist redirects
    // remove from session storage once updated in app
    var url = new URL(window.location.href)
    let tiviLocale = url.searchParams?.get('locale')
    if (tiviLocale) {
      let language = languageKeyToValue[tiviLocale]
      sessionStorage.setItem('language', language)
    }
  }

  const checkLanguage = () => {
    // get language value from browser, Tivi, localStorage, or keycloak token and set in app
    let browserLanguage = checkBrowserLanguagePreference()
    let tiviLanguage = sessionStorage.getItem('language')
    let savedLanguage = localStorage.getItem('language')
    const parsedToken = KeycloakService?.getParsedToken()

    if (tiviLanguage) {
      setSavedLanguage(tiviLanguage)
      sessionStorage.removeItem('language')
    } else if (savedLanguage) {
      setSavedLanguage(savedLanguage)
    } else if (parsedToken?.locale) {
      let language = languageKeyToValue[parsedToken.locale]
      setSavedLanguage(language)
    } else if (browserLanguage) {
      setSavedLanguage(browserLanguage)
    }
  }
}
