/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'

import {useHistory} from 'react-router-dom'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import {ModalWrapper} from '../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'
import './MEXICO__SuperAdmin__Reports.scss'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {useTranslation} from '../../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function MEXICO__SuperAdmin__Reports(props) {
  const {translate} = useTranslation()
  const history = useHistory()
  const {adminUser} = useSuperAdminUserContext()
  const [requestSent, setRequestSent] = useState(false)
  // note: used for getAuthLogReport required params
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const [openModal, setOpenModal] = useState(false)
  const [modalView, setModalView] = useState('ReportDownload__dateSelect')
  const [downloadReportDate, setDownloadReportDate] = useState(null)

  const [userType, setUserType] = useState(null)

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }

  const downloadReport = async (startDate, endDate) => {
    try {
      setModalView('ReportDownload__loading')
      const formData = {
        userType,
        startDate,
        endDate,
      }

      let res = await AdminVoterService.getAuthLogReport(formData, LANGUAGE_CONTEXT.currentLanguage)
      if (res?.success) {
        // @ts-ignore
        const href = URL.createObjectURL(res.data)
        const link = document.createElement('a')
        link.download = `Authentication Logs - ${userType}`
        link.href = href
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setModalView('ReportDownloaded')
      } else {
        setOpenModal(false)
        alert(translate('Error'))
      }
    } catch (error) {
      console.log(error)
      setOpenModal(false)
      alert(translate('Error'))
    }
  }

  return (
    <div className='SuperAdmin__Reports' id='Voter'>
      <ModalWrapper
        isOpen={openModal}
        onRequestClose={() => {
          setModalView('ReportDownload__dateSelect')
          setOpenModal(false)
        }}
        style={customStyles}
        modalView={modalView}
        headerLabel={translate('Generate Report')}
        onCallback={(startDate, endDate) => {
          downloadReport(startDate, endDate)
        }}
      />

      <div className='common__pg__header'>
        <span className='common__pg__header__title'>{translate('Reports')}</span>
      </div>

      <div className='reports__body'>
        <div className='reports__body__card'>
          <span className='reports__body__card__txt'>
            {translate('Electors Authentication Log Report')}
          </span>
          <CustomBtn
            view='ActionBtn'
            text='Generate'
            height={37}
            onClick={() => {
              setUserType('voter')
              setOpenModal(true)
            }}
          />
        </div>
        <div className='reports__body__card__seperator'></div>
        <div className='reports__body__card'>
          <span className='reports__body__card__txt'>
            {translate('Admin Users Authentication Log Report')}
          </span>
          <CustomBtn
            view='ActionBtn'
            text='Generate'
            height={37}
            onClick={() => {
              setUserType('admin')
              setOpenModal(true)
            }}
          />
        </div>
      </div>
    </div>
  )
}
