import axios from 'axios'
import environment from '../../../../utils/environment'

export const jumioInstance = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}/auth/jumio`,
  // withCredentials: true,
  headers: {},
})

// attach access token
jumioInstance.interceptors.request.use(
  async (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
jumioInstance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    const error = {
      ...err.response.data.error,
      ...err,
    }

    // console.error(error);
    return Promise.reject(error)
  }
)
