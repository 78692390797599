import {useTranslation} from '../../../../../hooks/useTranslation'
import {isContactInfoRoleAdmin, isSuperAdmin} from '../../../../../../KeycloakAdminService'

export const ElectorsListTableHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <div className='voterAddrList__tableHeader'>
      <span className='table-header-label electorListTable__item--1'>{translate('Member ID')}</span>

      <span className='table-header-label electorListTable__item--2'>{translate('Full Name')}</span>

      <span className='table-header-label electorListTable__item--3'>
        {translate('Email Address')}
      </span>
      <span className='table-header-label electorListTable__item--4'>{translate('Group')}</span>

      <span className='table-header-label electorListTable__item--5'>{translate('Subgroup')}</span>
      <span className='table-header-label electorListTable__item--6'>{translate('Region')}</span>
      <span className='table-header-label electorListTable__item--7'>{translate('Branch')}</span>

      <span className='table-header-label electorListTable__item--w5'>{translate('Type')}</span>

      <span className='table-header-label electorListTable__item--10'>
        {translate('Last Sign In')}
      </span>

      {(isSuperAdmin() || isContactInfoRoleAdmin()) && (
        <span className='table-header-label electorListTable__item--9'>{translate('Action')}</span>
      )}
    </div>
  )
}
export default ElectorsListTableHeader
