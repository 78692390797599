import './SelectField.scss'
import {useTranslation} from '../../../hooks/useTranslation'

const SelectField = (props) => {
  const {translate} = useTranslation()
  const {
    formik,
    type,
    field,
    placeholder,
    label,
    isRequired,
    noLabel,
    customOnChange,
    view,
    width,
    options,
    customStyling,
    noPlaceholderValue,
  } = props

  const selectOptions = props.options.map((option) => (
    <option key={option.label} value={option.value}>
      {translate(option.label)}
    </option>
  ))

  if (view == 'admin_dropdown') {
    return (
      <div>
        {!customOnChange ? (
          <div>
            <select
              style={{...customStyling, width: 357}}
              className='SelectField__voter SelectField__voterAdmin'
              {...formik.getFieldProps(field)}
              name={field}
            >
              {selectOptions}
            </select>
          </div>
        ) : (
          <select
            style={{...customStyling, width: 357}}
            className='SelectField__voter SelectField__voterAdmin'
            {...formik.getFieldProps(field)}
            name={field}
            onChange={customOnChange}
          >
            {selectOptions}
          </select>
        )}
      </div>
    )
  }

  if (view == 'voter') {
    return (
      <div style={{display: 'flex', flexFlow: 'column', width: width ? width : '100%'}}>
        <label
          className={
            isRequired && !noLabel
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {translate(label)}
        </label>

        {!customOnChange ? (
          <select
            className='SelectField__voter'
            {...formik.getFieldProps(field)}
            name={field}
            style={{
              ...customStyling,
              color: formik.values[field] === 0 && !noPlaceholderValue ? '#a1a5b7' : '#000',
            }}
          >
            {selectOptions}
          </select>
        ) : (
          <select
            className='SelectField__voter'
            {...formik.getFieldProps(field)}
            name={field}
            onChange={customOnChange}
            style={{
              ...customStyling,
              color: formik.values[field] === 0 && !noPlaceholderValue ? '#a1a5b7' : '#000',
            }}
          >
            {selectOptions}
          </select>
        )}

        {/* {formik.touched[field] && formik.errors[field] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors[field]}</div>
          </div>
        )} */}
      </div>
    )
  }

  return (
    <div>
      <label
        className={
          isRequired && !noLabel
            ? 'form-label fs-6 fw-bold text-dark required'
            : 'form-label fs-6 fw-bold text-dark'
        }
      >
        {translate(label)}
      </label>
      {customOnChange ? (
        <select
          className='SelectField '
          {...formik.getFieldProps(field)}
          name={field}
          onChange={customOnChange}
        >
          {selectOptions}
        </select>
      ) : (
        <select className='SelectField ' {...formik.getFieldProps(field)} name={field}>
          {selectOptions}
        </select>
      )}

      {formik.touched[field] && formik.errors[field] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors[field]}</div>
        </div>
      )}
    </div>
  )
}

export default SelectField
