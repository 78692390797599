import {useContext, useState} from 'react'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'

import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {LanguageContext} from '../../../../contexts/LanguageContext'
import './AdminSidebar.scss'
import SideBarItem from '../sidebar_item/SideBarItem'

export function AdminSidebar(props) {
  const {customVoterLogoImg} = useVoterStyles()
  const [currentTab, setCurrentTab] = useState('Dashboard')

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  // const activeTab = {
  //   nova_scotia: 'adminSideBar__tabActiveWrap',
  //   mexico: 'adminSideBar__tabActiveWrap',
  // }

  // const activeTabTxt = {
  //   nova_scotia: 'adminSideBar__tabActiveTxt',
  //   mexico: 'adminSideBar__tabActiveTxt',
  // }

  const TABS = {
    elections: {
      path: '/voting/voter/dashboard',
      tabValue: 'Dashboard',
      tabLabel: 'Elections',
      iconName: 'Elections',
      hasPermissionToView: true,
    },

    help: {
      path: '/voting/voter/help-support',
      tabValue: 'Help & Support',
      tabLabel: 'Support',
      iconName: 'Help & Support',
      hasPermissionToView: true,
    },

    myAccount: {
      path: '/voting/voter/my-profile',
      tabValue: 'My Account',
      tabLabel: 'My Account',
      iconName: 'My Account',
      hasPermissionToView: true,
    },
  }

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English' ? 'AdminSidebar--spanish' : 'AdminSidebar'
      }
    >
      <img
        className='adminSidebar__logo'
        src={toAbsoluteUrl(customVoterLogoImg)}
        alt='Elections Logo'
      />

      <hr className='AdminSidebar__divider'></hr>

      <div className='adminSideBar__tabContainer'>
        {Object.entries(TABS).map(([tabkey, tabValue]) => {
          return <SideBarItem currentTab={currentTab} setCurrentTab={setCurrentTab} {...tabValue} />
        })}
      </div>
    </div>
  )
}
