import {useEffect, useContext, useState} from 'react'
import {getFormattedCurrentTime} from '../languages/datetimeformats'
import {LanguageContext} from '../contexts/LanguageContext'

const useCurrentTime = () => {
  const {currentLanguage} = useContext(LanguageContext)
  const [currentTime, setCurrentTime] = useState(null)

  const getAndSetCurrentTime = () => {
    setCurrentTime(getFormattedCurrentTime(currentLanguage))
  }

  //   without this there is a 1 second delay before updating the language
  useEffect(() => {
    getAndSetCurrentTime()
  }, [currentLanguage])

  useEffect(() => {
    const interval = setInterval(getAndSetCurrentTime, 1000)
    return () => clearInterval(interval)
  }, [currentLanguage])

  return currentTime
}

export default useCurrentTime
