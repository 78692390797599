/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'

import {LanguageContext} from '../../../../../../contexts/LanguageContext'
import {toAbsoluteUrl} from '../../../../../../helperfunctions/AssetHelpers'
import '../../../common.scss'
import './EventVoteCard.scss'

import '../../MEXICO__Dashboard.scss'
import {ModalWrapper} from '../../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'

import moment from 'moment'

import VoterAuthService from '../../../../../../services/auth/VoterAuthService'
import {useLocation} from 'react-router-dom'
import {beforeUnload} from '../../../../../../App'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {formatDateLocale} from '../../../../../../languages/datetimeformats'
import {languageValueToKey} from '../../../../../../languages/languagekey'
import configOptions from '../../../../../../../utils/configOptions'
import {VotingReceiptModals} from '../voting_receipt_modal/VotingReceiptModals'
import useCountdown from '../../../../../../hooks/useCountdown'
import LanguageSpecificColon from '../../../../../../components/language_specific_colon/LanguageSpecificColon'
import useCheckVotingOpenStateChange from '../../../../../../hooks/useCheckVotingOpenStateChange'
import {convertToMomentWithTimeZone} from '../../../../../common/utility_functions/DateUtility'
import EventStateStatus from '../../../../../../components/event_state_status/EventStateStatus'
import {
  COUNTDOWN_LABEL,
  STATE_STATUSES,
  getEventStateStatus,
} from '../../../../../../helperfunctions/GetEventStateStatus'
import EventCountdownTimeDateContainer from '../../../../../../components/countdown_timer/CountdownTimerDateContainer'
import {addPeriodToText} from '../../../../../common/utility_functions/TextUtility'
import CustomSpinner from '../../../../../../components/spinner/Spinner'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999999999',
  },
}

export function EventVoteCard(props) {
  const {translate, translateObj} = useTranslation()

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const location = useLocation()
  const {checkReceipt, receiptParam, hasVoted} = props
  const propsEvent = props.event
  const [event, setEvent] = useState(propsEvent || {})

  let {closeDate} = event
  closeDate = convertToMomentWithTimeZone(closeDate)

  const fetchEvent = async () => {
    let updatedEvent = await VoterAuthService.getEventById(propsEvent.tiviId)
    if (updatedEvent) {
      setEvent(updatedEvent)
    }
  }
  useEffect(() => {
    fetchEvent()
  }, [location])

  const {votingOpen, loading} = useCheckVotingOpenStateChange({
    event,
    fetchEvent,
    closeDate,
  })
  const eventStatus = getEventStateStatus(event.state, votingOpen)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalView, setModalView] = useState('INE__ExampleBallot')

  const [votingModalOpen, setVotingModalOpen] = useState(false)
  const [votingModalView, setVotingModalView] = useState('VotingReceipt')

  const [headerLabel, setHeaderLabel] = useState(translate('Verify Vote Receipt'))

  function afterOpenModal() {}
  function closeModal() {
    setModalIsOpen(false)
  }

  useEffect(() => {
    if (receiptParam) {
      setVotingModalOpen(true)
    }
  }, [])

  if (loading) {
    return <CustomSpinner />
  }

  return (
    <div className='EventVoteCard' id='Voter'>
      <ModalWrapper
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
        ballotUrl={event.ballotUrl}
        headerLabel={translate('Ballot preview')}
      />

      <VotingReceiptModals
        isOpen={votingModalOpen}
        onRequestClose={() => {
          setVotingModalOpen(false)
        }}
        style={customStyles}
        modalView={votingModalView}
        setModalView={setVotingModalView}
        headerLabel={headerLabel}
        setHeaderLabel={setHeaderLabel}
        questions={event.questions || []}
        checkReceipt={checkReceipt}
        receiptParam={receiptParam}
      />

      <div className='eventVoteCard__body'>
        <div className='eventVoteCard__body__row1'>
          <img
            alt='ballot box'
            className='eventVoteCard__body__row1--calendar'
            src={toAbsoluteUrl('/media/design/ballot-box.svg')}
          />
          <div className='eventVoteCard__body__row1__content'>
            <div className='eventVoteCard__body__row1__content__titleWrap'>
              <div>
                <div className='eventVoteCard__body__row1__content__votIsOpenWrap'>
                  <span className='dashboard__body__topBanner__voteStatus__label'>
                    {translateObj(event.name)}
                  </span>
                  <span className='dashboard__body__topBanner__voteStatus__divider'>|</span>

                  <EventStateStatus eventStatus={eventStatus} />
                </div>
                {event.description && (
                  <span className='dashboard__body__topBanner__label'>
                    {addPeriodToText(translateObj(event.description))}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='eventVoteCard__body__row2'>
          <img
            alt='alarm icon'
            className='eventVoteCard__body__row2__icon--clock'
            src={toAbsoluteUrl('/media/design/icon_pink_alarm.svg')}
          />

          <div className='eventVoteCard__body__row2__content'>
            <div className='eventVoteCard__body__row2__content__cards'>
              <div className='common__flex__column'>
                <div className='dashboard__body__topBanner__closingAtCard'>
                  <span className='dashboard__body__topBanner__closingAtCard__label'>
                    {eventStatus !== STATE_STATUSES.closed
                      ? translate('Voting will close on')
                      : translate(COUNTDOWN_LABEL.closed)}
                    <LanguageSpecificColon />
                  </span>

                  <span className='dashboard__body__topBanner__closingAtCard__value'>
                    {formatDateLocale(closeDate, LANGUAGE_CONTEXT)}{' '}
                    {translate(configOptions.shortTimeString)}
                  </span>
                </div>
                {configOptions.voteReceiptEnabled && (
                  <div className='eventVoteCard__body__row2__content__linkWrap eventVoteCard__body__row2__content__linkWrap--1'>
                    <img
                      className='eventVoteCard__body__row2__content__linkWrap__icon--ballot'
                      alt='vote receipt icon'
                      src={toAbsoluteUrl('/media/design/icon_grey_reciept.svg')}
                    />
                    <span
                      className='eventVoteCard__body__row2__content__linkWrap__txt'
                      onClick={() => {
                        if (
                          event.state &&
                          ['ended', 'decrypting', 'decrypted'].includes(event.state)
                        ) {
                          alert(
                            addPeriodToText(
                              translate('You can verify your vote after all votes are tallied')
                            )
                          )
                        } else {
                          setVotingModalView('VotingReceipt')
                          setVotingModalOpen(true)
                        }
                      }}
                    >
                      {translate('Verify Vote Receipt')}
                    </span>
                  </div>
                )}
              </div>
              <div className='common__flex__column dashboard__body__topBanner__votingStatusCardWrap'>
                <div className='dashboard__body__topBanner__votingStatusCard'>
                  <span className='dashboard__body__topBanner__votingStatusCard__label'>
                    {translate('Have you voted yet?')}
                  </span>

                  <span className='dashboard__body__topBanner__votingStatusCard__value'>
                    {!hasVoted ? translate('You have not voted yet') : translate('Voted')}
                  </span>
                </div>

                {configOptions.voteReceiptEnabled && (
                  <div className='eventVoteCard__body__row2__content__linkWrap eventVoteCard__body__row2__content__linkWrap--mobile'>
                    <img
                      className='eventVoteCard__body__row2__content__linkWrap__icon--ballot'
                      alt='vote receipt icon'
                      src={toAbsoluteUrl('/media/design/icon_grey_reciept.svg')}
                    />
                    <span
                      className='eventVoteCard__body__row2__content__linkWrap__txt'
                      onClick={() => {
                        if (
                          event.state &&
                          ['ended', 'decrypting', 'decrypted'].includes(event.state)
                        ) {
                          alert(
                            addPeriodToText(
                              translate('You can verify your vote after all votes are tallied')
                            )
                          )
                        } else {
                          setVotingModalView('VotingReceipt')
                          setVotingModalOpen(true)
                        }
                      }}
                    >
                      {translate('Verify Vote Receipt')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {eventStatus !== STATE_STATUSES.closed && (
        <div className='eventVoteCard__footer'>
          <div className=' eventVoteCard__footer__contentWrap'>
            <EventCountdownTimeDateContainer
              event={event}
              votingOpen={votingOpen}
              size='big'
              layout='row'
              hideFormattedDate
            />
          </div>

          {votingOpen && (!hasVoted || event.multipleVotesEnabled) ? (
            <div className='eventVoteCard__footer__btnWrap'>
              <button
                className={`dashboard__body__topBanner__voteNowBtn eventVoteCard__footer__btn--voteNow`}
                onClick={() => {
                  VoterAuthService.updateUserTiviAuthenticated({tiviId: event.tiviId})
                  window.removeEventListener('beforeunload', beforeUnload)
                  window.location.href = `${event.eventLink}?locale=${
                    languageValueToKey[LANGUAGE_CONTEXT.currentLanguage] || 'en'
                  }`
                }}
              >
                {translate('Vote Now')}
              </button>{' '}
            </div>
          ) : (
            <div className='eventVoteCard__footer__noBtn' />
          )}
        </div>
      )}
    </div>
  )
}
