import React, {useContext, useEffect, useState} from 'react'
import Modal from 'react-modal'
import './FileViewerModal.scss'
import {languageValueToKey} from '../../languages/languagekey'
import {LanguageContext} from '../../contexts/LanguageContext'
import ModalSpinner from '../modal_spinner/ModalSpinner'
import UnsupportedFile from '../unsuported_file/UnsupportedFile'
import {getFileTypeFromName} from '../../pages/common/utility_functions/FileUtility'
import PDFViewer from '../../modules/reusable/pdf_viewer/PdfViewer'
import {FiX} from 'react-icons/fi'

interface FileMultiLingualFields {
  [key: string]: string
}

interface CustomFile {
  fileUrl: FileMultiLingualFields
  filename: FileMultiLingualFields
}

interface FileViewerModalProps {
  file: CustomFile
  modalIsOpen: boolean
  closeModal: () => void
}

const FileViewerModal: React.FC<FileViewerModalProps> = ({file, modalIsOpen, closeModal}) => {
  const {currentLanguage} = useContext(LanguageContext)
  const [fileUrl, setFileUrl] = useState<string | null>(null)
  const [fileType, setFileType] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const loadFile = () => {
    try {
      if (file && file.fileUrl) {
        const url = file.fileUrl[languageValueToKey[currentLanguage]]
        const filename = file.filename[languageValueToKey[currentLanguage]]
        setFileUrl(url)
        const fileType = getFileTypeFromName(filename) // Determine file type using utility function
        setFileType(fileType)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(true) // Set loading to true before making any asynchronous calls
    loadFile()
    setLoading(false) // Set loading to false after processing
  }, [file, currentLanguage])

  const renderContent = () => {
    if (loading) {
      return <ModalSpinner />
    }

    switch (fileType) {
      case 'document':
        return <PDFViewer pdfViewURL={fileUrl} />
      case 'image':
        return <img className='file-viewer-modal__content__img' src={fileUrl} alt='File Viewer' />
      default:
        return <UnsupportedFile />
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel='File Viewer'
      ariaHideApp={false}
      className='shared-modal file-viewer-modal'
      overlayClassName='shared-modal--overlay'
    >
      <div className='file-viewer-modal__content'>
        <div className='file-viewer-modal__content__header'>
          <button className='file-viewer-modal__content__close-btn' onClick={closeModal}>
            <FiX size={24} />
          </button>
        </div>
        {renderContent()}
      </div>
    </Modal>
  )
}

export default FileViewerModal
