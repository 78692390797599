import {
  COUNTDOWN_LABEL,
  STATE_STATUSES,
  getEventStateStatus,
} from '../../helperfunctions/GetEventStateStatus'
import CountdownTimerBig from './CountdownTimerBig'
import CountdownTimerSmall from './CountdownTimerSmall'
import RenderTBD from './RenderTBD'
import FormattedDate from './FormattedDate'

const CountdownTimer = (props) => {
  const {size} = props
  if (size === 'small') {
    return <CountdownTimerSmall {...props} />
  }
  return <CountdownTimerBig {...props} />
}

export const EventCountdownTimeDateContainer = ({
  event,
  votingOpen,
  size = 'big',
  layout = null,
  hideFormattedDate = false,
}) => {
  const eventStateStatus = getEventStateStatus(event.state, votingOpen)
  switch (eventStateStatus) {
    case STATE_STATUSES.upcoming:
      let countDownDate = event.openDate
      let diff = new Date().getTime() - new Date(countDownDate).getTime()
      if (diff <= 0)
        return (
          <CountdownTimer
            size={size}
            countDownDate={countDownDate}
            title={COUNTDOWN_LABEL.upcoming}
            layout={layout}
          />
        )
      return <RenderTBD title={COUNTDOWN_LABEL.upcoming} size={size} layout={layout} />
    case STATE_STATUSES.active:
      return (
        <CountdownTimer
          size={size}
          countDownDate={event.closeDate}
          title={COUNTDOWN_LABEL.active}
          layout={layout}
        />
      )
    case STATE_STATUSES.closed:
      if (hideFormattedDate) {
        return <div />
      }
      return <FormattedDate date={event.closeDate} title={COUNTDOWN_LABEL.closed} size={size} />
  }
}
export default EventCountdownTimeDateContainer
