import React, {useCallback, useRef, useState} from 'react'
import {ModalHeader} from '../../modals/ModalHeader'
import {toAbsoluteUrl} from '../../../helperfunctions/AssetHelpers'
import {useTranslation} from '../../../hooks/useTranslation'
import {addPeriodToText} from '../../common/utility_functions/TextUtility'

const FileUploaderModal = ({
  onClose,
  setUploadErr,
  customBtnClass = {},
  customStyling = {},
  onCallback = (p0: {redirect: string; data: any}) => {},
}) => {
  const [localFileData, setLocalFileData] = useState(null)
  const uploadRef = useRef(null)
  const {translate} = useTranslation()
  const allowedFiles = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'mp3', 'mp4', 'mov', 'wav']

  const getFileType = (filename) => {
    let extension = filename.split('.').pop()
    return extension
  }

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      e.persist()

      const file = e.target.files[0]
      setLocalFileData({name: file.name, file: file})
      console.log('file', file)

      const isLt2M = file.size / 1024 / 1024 < 25

      // If the file size (calculated in byte form) is greater than 25MB
      if (!isLt2M) {
        setUploadErr(addPeriodToText(translate('The maximum supported file size is 25 MB')))
        // setCurrentView('uploadError')
        return console.log('The maximum supported file size is 25 MB')
      }

      if (!allowedFiles.includes(getFileType(file.name))) {
        // setCurrentView('wrongFileTypeErr')
        return console.log('wrongFileTypeErr')
      }

      try {
        // SUCCESS REDIRECT
        // setCurrentView('Add Document')

        onCallback({redirect: 'Add Document', data: {name: file.name, file: file}})
      } catch (error) {
        setUploadErr(addPeriodToText(translate('Something went wrong, please contact support')))
        // setCurrentView('uploadError')

        return console.log(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div>
        <div
          className='addImageModal__uploadContainer'
          onClick={onImageClick}
          style={customStyling}
        >
          <img src={toAbsoluteUrl('/media/design/icon_pink_upload.svg')} alt='upload' />
          <h4 className='addImageModal__uploadTxt'>{translate('File Uploader')}</h4>
          <span className='addImageModal__uploadSubTxt'>
            {translate(`Click to upload file. 25MB Max`)}({allowedFiles.join(', ')})
          </span>
        </div>

        <input type='file' onChange={onFileUpload} ref={uploadRef} style={{display: 'none'}} />
      </div>
    </>
  )
}

export default FileUploaderModal
