import {useContext, useEffect, useState} from 'react'
import {LanguageContext} from '../../contexts/LanguageContext'
import AdminVoterAuthService from '../../services/auth/AdminVoterAuthService'
import {beforeUnload} from '../../App'
import {languageValueToKey} from '../../languages/languagekey'
import {AdminDropdown} from '../voter_admin/reusable/admin_dropdown/AdminDropdown'
import {DeleteFileModal} from '../reusable/delete_file_modal/DeleteFileModal'
import FileIcon from '../../components/file_icon/FileIcon'
import FileViewerModal from '../../components/file_viewer_modal/FileViewerModal'

const DocCard = ({doc, getDocs, index, viewOnly}) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [openFileModal, setOpenFileModal] = useState(false)
  const {currentLanguage} = useContext(LanguageContext)

  const deleteFile = async () => {
    try {
      await AdminVoterAuthService.deleteHelpDoc(doc.id)
      getDocs()
      setDeleteModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  const downloadFile = async () => {
    try {
      window.removeEventListener('beforeunload', beforeUnload)

      let alink = document.createElement('a')
      alink.href = doc.fileUrl[languageValueToKey[currentLanguage]]
      alink.download = `${doc.filename[languageValueToKey[currentLanguage]]}`
      document.body.appendChild(alink)
      alink.click()
      document.body.removeChild(alink)
    } catch (error) {
      console.log(error)
    }
  }

  if (
    !doc.filename ||
    !doc.filename[languageValueToKey[currentLanguage]] ||
    !doc.title ||
    !doc.title[languageValueToKey[currentLanguage]]
  ) {
    return <div />
  }

  return (
    <div className='helpSupport__card' key={index}>
      <DeleteFileModal
        modalOpen={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
        onDelete={() => {
          deleteFile()
        }}
      />

      {openFileModal && (
        <FileViewerModal
          file={doc}
          modalIsOpen={openFileModal}
          closeModal={() => {
            setOpenFileModal(false)
          }}
        />
      )}

      {/* body */}
      <div className='helpSupport__body'>
        <span className='helpSupport__body__txt'>
          {doc.title[languageValueToKey[currentLanguage]]}
        </span>
      </div>

      {/* footer */}
      <div className='helpSupport__footer'>
        <FileIcon filename={doc.filename} />

        <AdminDropdown
          viewOnly={viewOnly}
          view='EditDelete'
          onCallback={(value) => {
            if (value == 'View') {
              setOpenFileModal(true)
            }
            if (value == 'Delete') {
              setDeleteModal(true)
            }
            if (value == 'Download') {
              downloadFile()
            }
          }}
        />
      </div>
    </div>
  )
}

export default DocCard
