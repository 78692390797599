/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import './MEXICO__SuperAdmin__HelpAndSupport.scss'
import {useTranslation} from '../../../hooks/useTranslation'
import KeycloakAdminService from '../../../../KeycloakAdminService'
import AdminVoterAuthService from '../../../services/auth/AdminVoterAuthService'
import VoterAuthService from '../../../services/auth/VoterAuthService'

import Attachments from '../../../components/attachments/Attachments'
import HelpAndSupportHeader from './help_and_support_header/HelpAndSupportHeader'
import {EventAttachModal as FileUploadModal} from '../../private/super_admin/events/event_detail/event_attachments/components/EventAttachModal'
import {isViewOnlyHelpManagement} from '../../common/utility_functions/AuthUtility'

export function MEXICO__SuperAdmin__HelpAndSupport(props) {
  const {translate} = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [currentView, setCurrentView] = useState('Add Document')
  const [listOfDocs, setListOfDocs] = useState([] as any)

  const isAdmin = KeycloakAdminService?.isLoggedIn()
  const viewOnly = isViewOnlyHelpManagement()

  const getHelpDocs = async () => {
    try {
      let resp
      if (isAdmin) {
        resp = await AdminVoterAuthService.getHelpDocs()
      } else {
        resp = await VoterAuthService.getHelpDocs()
      }
      if (resp) {
        console.log('resp', resp)
        setListOfDocs(resp)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getHelpDocs()
  }, [])

  return (
    <div className='MEXICO__MyProfile SuperAdmin__HelpAndSupport'>
      <FileUploadModal
        modalOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false)
        }}
        view={currentView}
        eventId={null}
        getAttachments={getHelpDocs}
      />

      <span className='helpAndSupportM__title'> {translate('Support')}</span>

      <HelpAndSupportHeader setModalOpen={setModalOpen} />

      <div className='myProfile__body'>
        {/* Attachments */}
        <Attachments listOfDocs={listOfDocs} getAttachments={getHelpDocs} viewOnly={viewOnly} />
      </div>
    </div>
  )
}
