/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'

import './MEXICO__AuthAppSetup.scss'

import '@djthoms/pretty-checkbox'
import {useTranslation} from '../../../../hooks/useTranslation'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {VoterOnboardingContext} from '../../../../contexts/VoterOnboardingContext'
import {VoterUserContext} from '../../../../contexts/VoterUserContext'
import {useSuperAdminUserContext} from '../../../../contexts/SuperAdminUserContext'
import {VoterLoginFlowContext} from '../../../../contexts/VoterLoginFlowContext'
import {LanguageContext} from '../../../../contexts/LanguageContext'

import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import {ProgressBar} from '../reusable/ProgressBar'
import {ProgressBarMobile} from '../reusable/ProgressBarMobile'
import RecapatchaDisclaimer from '../../../../resuable/recapatcha/RecapatchaDisclaimer'
import AuthAppModal from '../auth_app_modal/AuthAppModal'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'
import {addPeriodToText} from '../../../common/utility_functions/TextUtility'

export function MEXICO__AuthAppSetup(props) {
  const {translate} = useTranslation()

  const history = useHistory()
  const qrCodeDataUrl = props.location?.state?.qrCodeDataUrl
  const factorSid = props.location?.state?.factorSid

  const [checkedOption, setCheckedOption] = useState(null)
  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()
  const {authMethod, setAuthMethod} = useContext(VoterOnboardingContext)
  const VoterUser = useContext(VoterUserContext)
  const {adminUser} = useSuperAdminUserContext()
  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  // note: used for rendering certain styles based on the language
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const [infoModalOpen, setInfoModalOpen] = useState(false)

  return (
    <div className='VerifyMethod' id='Voter'>
      <AuthAppModal
        modalOpen={infoModalOpen}
        onClose={() => {
          setInfoModalOpen(false)
        }}
      />
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          alt='banner'
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div
          className={
            LANGUAGE_CONTEXT.currentLanguage == 'English'
              ? VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding'
                ? 'authMethod__form'
                : 'authMethodLogin__form'
              : //ELSE, SPANISH
              VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding'
              ? ' authMethodLogin__form authMethod__form--spanish'
              : ' authMethodLogin__form authMethod__form--spanish'
          }
        >
          {VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding' && <ProgressBar currentStep={2} />}
          {VOTER_LOGIN_CONTEXT.loginFlow == 'Onboarding' && <ProgressBarMobile currentStep={2} />}
          {/*  */}

          {/* PHONE INFO SECTION */}
          {/* <div className='authMethod__form__body'>
            <div className='authMethod__form__body__phoneWrap'>
              <div className='authMethod__form__body__phoneWrap__txtWrap'>
                <span className='authMethod__form__body__phoneWrap__txtWrap__label'>
                  {translate('Phone Number:')}
                </span>
                <span className='authMethod__form__body__phoneWrap__txtWrap__value'>
                  {VoterUser?.user?.phone}
                </span>
              </div>

              <span className='authMethod__form__body__phoneWrap__disclaimer'>
                {translate('Not your’s?')}{' '}
                <span className='common__link'>{translate('Click here')}</span>
              </span>
            </div>
          </div> 

          {/* USERNAME INFO SECTION */}
          <div className='authMethod__form__body authMethod__form__body__usernameWrap'>
            <div className='authMethod__form__body__usernameWrap__txtWrap'>
              <span className='authMethod__form__body__usernameWrap__txtWrap__label'>
                {VOTER_LOGIN_CONTEXT.loginFlow.startsWith('Admin')
                  ? translate('Your username')
                  : translate('Your membership number')}
                <LanguageSpecificColon />
              </span>
              <span className='authMethod__form__body__usernameWrap__txtWrap__value'>
                {VoterUser?.user?.username || adminUser?.username}
              </span>
            </div>
            {!VOTER_LOGIN_CONTEXT.loginFlow.startsWith('Admin') && (
              <div className='authMethod__form__body__usernameWrap__banner'>
                <img className='' src={toAbsoluteUrl('/media/design/pink_hand.svg')} />
                <span className='authMethod__form__body__usernameWrap__banner__txt'>
                  {addPeriodToText(
                    translate(
                      'you will be asked for this username every time you login to the user dashboard, please write it down and keep in safe place'
                    )
                  )}
                </span>
              </div>
            )}
          </div>

          {/* AUTH METHOD SECTION */}
          <div className='authAppSetup__form__body__methodWrap'>
            {!VOTER_LOGIN_CONTEXT.loginFlow.startsWith('Admin') && (
              <div className='authMethod__form__body__methodWrap__txtWrap'>
                <span className='authMethod__form__body__methodWrap__txtWrap__label'>
                  {translate('Choose an authentication method')}
                </span>
                <img
                  onClick={() => {
                    setInfoModalOpen(true)
                  }}
                  alt='info icon'
                  className='authAppSetup__form__body__method__infoIcon'
                  src={toAbsoluteUrl('/media/design/info-icon-blue.svg')}
                />
              </div>
            )}
            <div className='authAppSetup__form__body__method__subtitleWrap'>
              <span className='authAppSetup__form__body__method__subtitle'>
                {addPeriodToText(
                  translate(
                    'To set up your login method you will need to scan this QR code with any available authentication app'
                  )
                )}
              </span>
            </div>
            {/* QR CODE */}
            <div className='qrCodeSetupContainer'>
              <img
                alt='qrCode'
                className='authAppSetup__form__body__method__qrCode'
                src={qrCodeDataUrl}
              />

              <span className='authAppSetup__form__body__method__scanMeLabel'>
                {translate('Scan Me!')}
              </span>
            </div>
          </div>

          {/*  */}
          <div className='phoneVerify__form__actionBar'>
            {!VOTER_LOGIN_CONTEXT.loginFlow.startsWith('Admin') && (
              <button
                className='phoneVerify__form__actionBar__backBtn'
                onClick={() => {
                  history.push('/voting/voter-onboarding/auth-method')
                }}
              >
                {translate('Back')}
              </button>
            )}
            <button
              className='authMethodLogin__nextBtn'
              onClick={() => {
                setAuthMethod('Authapp')
                if (VOTER_LOGIN_CONTEXT.loginFlow.startsWith('Admin')) {
                  history.push('/admin/auth/verify', {factorSid})
                } else {
                  history.push('/voting/voter-onboarding/auth-verify')
                }
              }}
            >
              {translate('Next')}
            </button>
          </div>
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt400' view='mobile' />
    </div>
  )
}
