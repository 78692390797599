import React, {Suspense, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {Routes} from './routing/Routes'

import app_config from '../config.json'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import Cookies from 'universal-cookie'
import './styles/global.scss'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyToken'
const VERIFY_COOKIE_ADMIN = 'verifyTokenAdmin'
export const ONBOARDING_COOKIE = 'onboardingAccessToken'

type Props = {
  basename: string
}

export const beforeUnload = (event) => {
  event.returnValue = 'Are you sure you want to leave?'
}
const App: React.FC<Props> = ({basename}) => {
  useEffect(() => {
    cookies.remove(VERIFY_COOKIE)
    cookies.remove(VERIFY_COOKIE_ADMIN)
    cookies.remove(ONBOARDING_COOKIE)
  }, [])

  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter basename={basename}>
        <GoogleReCaptchaProvider reCaptchaKey={app_config.RECAPTCHA_SITE_KEY}>
          <Routes />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
