import {useState, useEffect} from 'react'
import moment from 'moment-timezone'
import configOptions from '../../utils/configOptions'
import {convertToMomentWithTimeZone} from '../pages/common/utility_functions/DateUtility'

const useCountdown = (countdownToDate) => {
  const countdownDate = convertToMomentWithTimeZone(countdownToDate)
  const [currentDays, setCurrentDays] = useState(null)
  const [currentHours, setCurrentHours] = useState(null)
  const [currentMinutes, setCurrentMinutes] = useState(null)
  const [currentSeconds, setCurrentSeconds] = useState(null)

  const getDays = () => {
    let now = moment()
    if (configOptions.timeZoneDateString) {
      now = moment().tz(configOptions.timeZoneDateString)
    }
    let days = countdownDate.diff(now, 'days')
    if (days !== currentDays) {
      setCurrentDays(days)
    }
    return days
  }

  const getHours = () => {
    let now = moment()
    if (configOptions.timeZoneDateString) {
      now = moment().tz(configOptions.timeZoneDateString)
    }
    now = now.add(getDays(), 'days')
    let hours = countdownDate.diff(now, 'hours')
    if (hours !== currentHours) {
      setCurrentHours(hours)
    }
    return hours
  }

  const getMinutes = () => {
    let now = moment()
    if (configOptions.timeZoneDateString) {
      now = moment().tz(configOptions.timeZoneDateString)
    }
    now = now.add(getDays(), 'days').add(getHours(), 'hours')
    let minutes = countdownDate.diff(now, 'minutes')
    if (minutes !== currentMinutes) {
      setCurrentMinutes(minutes)
    }
    return minutes
  }

  const getSeconds = () => {
    let now = moment()
    if (configOptions.timeZoneDateString) {
      now = moment().tz(configOptions.timeZoneDateString)
    }
    now = now.add(getDays(), 'days').add(getHours(), 'hours').add(getMinutes(), 'minutes')
    let seconds = countdownDate.diff(now, 'seconds')
    if (seconds !== currentSeconds) {
      setCurrentSeconds(seconds)
    }
    return seconds
  }

  useEffect(() => {
    const interval = setInterval(getSeconds, 1000)
    return () => clearInterval(interval)
  }, [])

  return {
    days: currentDays,
    hours: currentHours,
    minutes: currentMinutes,
    seconds: currentSeconds,
  }
}

export default useCountdown
