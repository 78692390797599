import React from 'react'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../helperfunctions/AssetHelpers'

interface DividerImageProps {
  spacingLeft?: string
  spacingRight?: string
}

const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DividerImage = styled.img<DividerImageProps>`
  margin-left: ${(props) => props.spacingLeft || '0'};
  margin-right: ${(props) => props.spacingRight || '0'};
`

interface DividerProps {
  spacingLeft?: string
  spacingRight?: string
}

const Divider: React.FC<DividerProps> = ({spacingLeft = '0', spacingRight = '0'}) => {
  return (
    <DividerContainer>
      <DividerImage
        src={toAbsoluteUrl('/media/design/divider-icon.svg')}
        alt='divider icon'
        spacingLeft={spacingLeft}
        spacingRight={spacingRight}
      />
    </DividerContainer>
  )
}

export default Divider
