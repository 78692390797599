import {useHistory} from 'react-router-dom'
import {AdminSVG} from '../admin_svg/AdminSVG'

import {useTranslation} from '../../../../hooks/useTranslation'

export function SideBarItem(props) {
  const {translate} = useTranslation()

  const history = useHistory()

  const {path, tabValue, tabLabel, iconName, setCurrentTab, currentTab, hasPermissionToView} = props

  const active = currentTab === tabValue

  if (!hasPermissionToView) {
    return null
  }

  return (
    <div
      className={active ? 'adminSideBar__tabActiveWrap' : 'adminSideBar__tabInactiveWrap'}
      onClick={() => {
        history.push(path)
        setCurrentTab(tabValue)
      }}
    >
      <AdminSVG icon={iconName} inactive={!active} />
      <span className={active ? 'adminSideBar__tabActiveTxt' : 'adminSideBar__tabInactiveTxt'}>
        {translate(tabLabel)}
      </span>
    </div>
  )
}
export default SideBarItem
