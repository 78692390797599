import './CustomBtn.scss'
import {useTranslation} from '../../../hooks/useTranslation'

export function CustomBtn(props) {
  const {translate} = useTranslation()

  let {
    view,
    type = 'submit',
    text,
    height,
    width,
    disabled,
    customStyling = {},
    customClass = '',
  } = props

  const buttonStyles = {
    height,
    width,
    ...customStyling, // Apply custom styles
  }

  const ActionBtn = (
    <button
      type={type}
      style={buttonStyles}
      className={`CustomBtn ${customClass}`} // Add customClass to className
      disabled={disabled}
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const PrimaryBtn = (
    <button
      type={type}
      style={buttonStyles}
      className={`CustomBtn--primary ${customClass}`} // Add customClass to className
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const PrimaryBtnGrey = (
    <button
      type={type}
      style={buttonStyles}
      className={`CustomBtn--primaryGrey ${customClass}`} // Add customClass to className
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const SecondaryBtn = (
    <button
      type={type}
      style={buttonStyles}
      className={`CustomBtn--cancel ${customClass}`} // Add customClass to className
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const DangerBtn = (
    <button
      type='submit'
      style={buttonStyles}
      className={`CustomBtn--danger ${customClass}`} // Add customClass to className
      onClick={props.onClick}
    >
      {translate(text)}
    </button>
  )

  const renderView = () => {
    if (view === 'ActionBtn') {
      return ActionBtn
    }
    if (view === 'SecondaryBtn') {
      return SecondaryBtn
    }
    if (view === 'PrimaryBtn') {
      return PrimaryBtn
    }

    if (view === 'PrimaryBtn--grey') {
      return PrimaryBtnGrey
    }

    if (view === 'DangerBtn') {
      return DangerBtn
    }
  }

  return renderView()
}
