import styled from 'styled-components'
import {useTranslation} from '../../hooks/useTranslation'

const UnsupportedFileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    color: #333; /* Example color */
    /* Add any other styles you want */
  }
`

const UnsupportedFile: React.FC = () => {
  const {translate} = useTranslation()
  return (
    <UnsupportedFileContainer>
      <p>{translate('Viewing online is not available for this file type.')}</p>
    </UnsupportedFileContainer>
  )
}

export default UnsupportedFile
