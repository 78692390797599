import themeVariables from '../../../styles/_variables'

const USER_TYPE_COLORS = {
  REG: {
    color: themeVariables.successColor,
    backgroundColor: '#F5F8FA',
  },
  RTIR: {
    color: '#AA7762',
    backgroundColor: '#FCF2DC',
  },
  RAND: {
    color: '#3699FF',
    backgroundColor: '#F5F8FA',
  },
  CANC: {
    color: '#D20033',
    backgroundColor: '#FDE3E9',
  },
}

export default USER_TYPE_COLORS
