import React from 'react'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {toAbsoluteUrl} from '../../../../../../helperfunctions/AssetHelpers'

const EventDetailHeader = ({currentEvent}) => {
  const {translateObj} = useTranslation()
  return (
    <div className='superAdmin__eventDetail__header'>
      <div className='superAdmin__dashboard__headerIconWrap'>
        <img
          alt='ballot box icon'
          className=''
          src={toAbsoluteUrl('/media/design/ballot-box.svg')}
        />
        <span className='superAdmin__events__list__card__txt'>
          {translateObj(currentEvent.name)}
        </span>
      </div>
    </div>
  )
}

export default EventDetailHeader
