import config from '../config'
const currentEnvironment = config?.ENV ? config.ENV : 'pipsc_dev2'
const currentVersion = 'app'

const env = {
  development: {
    rest: 'http://localhost:3001',
    voter_reg_rest: 'http://localhost:3002',
    keycloak_url: 'https://qa3.auth.civik.ca/',
    keycloak_url_admin: 'https://keycloak.console.qa3.mx.tivi.global:9443/',
  },

  voter_development: {
    rest: 'https://dev.api.civik.ca',
    voter_reg_rest: 'http://localhost:3001',
    keycloak_url: 'https://auth.nwt.civik.ca/',
    keycloak_url_admin: 'https://keycloak.console.nwt.civik.ca:9443/',
  },

  remote_development: {
    rest: 'https://dev.api.civik.ca',
    voter_reg_rest: 'https://qa3.api.voterregistration.civik.ca',
    keycloak_url: 'https://qa3.auth.civik.ca/',
    keycloak_url_admin: 'https://keycloak.console.qa3.mx.tivi.global:9443/',
  },

  stage: {
    rest: 'https://stage.api.civik.ca',
    voter_reg_rest: 'https://qa3.api.voterregistration.civik.ca',
    keycloak_url: 'https://qa3.auth.civik.ca/',
    keycloak_url_admin: 'https://keycloak.console.qa3.mx.tivi.global:9443/',
  },
  production: {
    rest: 'https://api.civik.ca',
    voter_reg_rest: 'https://qa3.api.voterregistration.civik.ca',
    keycloak_url: 'https://qa3.auth.civik.ca/',
    keycloak_url_admin: 'https://keycloak.console.qa3.mx.tivi.global:9443/',
  },
  pipsc: {
    rest: 'https://dev.api.civik.ca',
    voter_reg_rest: 'https://api.pipsc.civik.ca',
    // voter_reg_rest: 'http://localhost:3001',
    keycloak_url: 'https://auth.pipsc.civik.ca/',
    keycloak_url_admin: 'https://keycloak.console.dev.pipsc.civik.ca:9443/',
  },
  pipsc_dev2: {
    rest: 'https://dev.api.civik.ca',
    voter_reg_rest: 'https://api.dev2.pipsc.civik.ca',
    // voter_reg_rest: 'http://localhost:3001',
    keycloak_url: 'https://auth.dev2.pipsc.civik.ca/',
    keycloak_url_admin: 'https://keycloak.console.env.dev2.pipsc.civik.ca:9443/',
  },
  pipsc_prod: {
    rest: 'https://dev.api.civik.ca',
    voter_reg_rest: 'https://api.elections.pipsc-ipfpc.ca',
    keycloak_url: 'https://auth.elections.pipsc-ipfpc.ca/',
    keycloak_url_admin: 'https://keycloak.console.main.elections.pipsc-ipfpc.ca:9443/',
  },
}

const syn = {
  development: {
    syngrafii_origin: 'https://sandbox.syngrafii.com',
  },
  voter_development: {
    syngrafii_origin: 'https://sandbox.syngrafii.com',
  },
  remote_development: {
    syngrafii_origin: 'https://sandbox.syngrafii.com',
  },
  stage: {
    syngrafii_origin: 'https://sign.syngrafii.com',
  },
  production: {
    syngrafii_origin: 'https://sign.syngrafii.com',
  },
}

const ver = {
  app: {
    version: '1.0',
  },
}

const environment = {
  /**
   * Returns the environment endpoints set above
   *
   * @return {String}
   */
  resolveApi: () => {
    return env[currentEnvironment]
  },

  resolveVersion: () => {
    return ver[currentVersion]
  },

  resolveSyngrafii: () => {
    return syn[currentEnvironment].syngrafii_origin
  },
}

export default environment
