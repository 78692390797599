import {toAbsoluteUrl} from '../../helperfunctions/AssetHelpers'
import {useTranslation} from '../../hooks/useTranslation'
import {addPeriodToText} from '../../pages/common/utility_functions/TextUtility'

const PasswordValidationTxt = ({text, isValid}) => {
  const {translate} = useTranslation()

  const ValidIcon = 'green_check_circle_icon.svg'
  const InvalidIcon = 'icon_x_circle_red.svg'

  return (
    <div className='createPassword__form__body__validationWrap'>
      <img
        alt={`circle ${isValid ? 'check' : 'x'} icon`}
        className='createPassword__form__body__validation__icon'
        src={toAbsoluteUrl(`/media/design/${isValid ? ValidIcon : InvalidIcon}`)}
      />

      <span
        role='alert'
        className={`createPassword__form__body__validation__${isValid ? 'successTxt' : 'errorTxt'}`}
      >
        {addPeriodToText(translate(text))}
      </span>
    </div>
  )
}

export default PasswordValidationTxt
