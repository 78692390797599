/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
// import SPANISH_TRANSLATION from './translations/Spanish.json'
import './MEXICO__Dashboard.scss'
import '../common.scss'

import {LanguageContext} from '../../../../contexts/LanguageContext'

import {useLocation} from 'react-router-dom'

import VoterAuthService from '../../../../services/auth/VoterAuthService'
import {beforeUnload} from '../../../../App'
import {useTranslation} from '../../../../hooks/useTranslation'

import EventDisplaySection from './components/events_display_section/EventsDisplaySection'

export function MEXICO__Dashboard() {
  const {translate} = useTranslation()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)
  const [elections, setElections] = useState([] as any)
  const location = useLocation()

  const fetchEvents = async () => {
    let updatedEvents = await VoterAuthService.getUserEvents()

    if (updatedEvents) {
      setElections(updatedEvents)
    }
  }

  useEffect(() => {
    fetchEvents()
    window.addEventListener('beforeunload', beforeUnload)
  }, [location.pathname])

  return (
    <div
      className={
        LANGUAGE_CONTEXT.currentLanguage !== 'English'
          ? 'MEXICO__Dashboard--spanish'
          : 'MEXICO__Dashboard'
      }
    >
      <div className='common__pg__header '>
        <span className='common__pg__header__title'>{translate('Elections')}</span>
      </div>
      <span
        className={
          LANGUAGE_CONTEXT.currentLanguage !== 'English'
            ? 'commonHeaderM__title--spanish'
            : 'commonHeaderM__title'
        }
      >
        {' '}
        {translate('Elections')}
      </span>
      <EventDisplaySection events={elections} />
    </div>
  )
}
