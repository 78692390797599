import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helperfunctions/AssetHelpers'
import './TextField.scss'

import {FormikProps} from 'formik' // Import FormikProps if not already imported
import {PiEye, PiEyeSlash} from 'react-icons/pi'
import {v4 as uuidv4} from 'uuid'

interface TextFieldProps {
  formik: FormikProps<any> // Replace 'any' with your actual form data type
  type: string // or the appropriate type for 'type'
  field: string
  placeholder: string
  label?: string
  value?: string
  isRequired?: boolean
  noLabel?: boolean
  view?: string
  disabled?: boolean
  customStyling?: Record<string, any>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
}

const TextField: React.FC<TextFieldProps> = ({
  formik,
  type,
  field,
  placeholder,
  label = '',
  value = '',
  isRequired = false,
  noLabel = false,
  view = '',
  disabled = false,
  customStyling = {},
  onChange = () => {},
  onClick = () => {},
}) => {
  const [inputType, setInputType] = useState('text')
  const [passwordVisable, setPasswordVisable] = useState(false)

  const id = uuidv4()

  const checkType = (type) => {
    if (type == 'email') {
      return 'email'
    } else if (type == 'password') {
      return 'password'
    } else {
      return 'text'
    }
  }

  const AdminSearchInput = (
    <div className='TextField__voter TextField__adminSearchBar' style={customStyling}>
      <img
        alt=''
        className='TextField__adminSearchBarImg'
        src={toAbsoluteUrl('/media/design/admin_search_icon.svg')}
      />
      <input
        placeholder={placeholder}
        {...formik.getFieldProps(field)}
        className={clsx(
          'TextField__adminSearchBar__inner',
          {'is-invalid': formik.touched[field] && formik.errors[field]},
          {
            'is-valid': formik.touched[field] && !formik.errors[field],
          }
        )}
        type={checkType(type)}
        name={field}
        autoComplete='off'
        disabled={disabled}
        value={formik.values[field]} // Use value prop to control the input value
        onChange={(e) => {
          formik.handleChange(e)
          if (onChange) {
            onChange(e)
          }
        }}
      />
    </div>
  )

  const SearchBarNoFormikInput = (
    <div className='TextField__voter TextField__adminSearchBar' style={customStyling}>
      <img
        alt=''
        className='TextField__adminSearchBarImg'
        src={toAbsoluteUrl('/media/design/admin_search_icon.svg')}
      />
      <input
        placeholder={placeholder}
        className={clsx('TextField__adminSearchBar__inner')}
        type={checkType(type)}
        name={field}
        autoComplete='off'
        disabled={disabled}
        value={value} // Use value prop to control the input value
        onChange={(e) => {
          onChange(e)
        }}
      />
    </div>
  )

  const VoterInput = (
    <div style={customStyling}>
      {!noLabel && (
        <label
          htmlFor={id}
          className={
            isRequired
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {label}
        </label>
      )}

      <input
        id={id}
        onClick={onClick}
        placeholder={placeholder}
        {...formik.getFieldProps(field)}
        className={clsx(
          !disabled ? 'TextField__voter' : 'TextField__voter TextField__voterDisabled',
          {'is-invalid': formik.touched[field] && formik.errors[field]},
          {
            'is-valid': formik.touched[field] && !formik.errors[field],
          }
        )}
        type={checkType(type)}
        name={field}
        autoComplete='off'
        disabled={disabled}
        // onChange={onChange}
        value={formik.values[field]} // Use value prop to control the input value
        onChange={(e) => {
          formik.handleChange(e)
          onChange(e)
        }}
      />
    </div>
  )

  const PasswordInput = (
    <div style={customStyling}>
      {!noLabel && (
        <label
          htmlFor={id}
          className={
            isRequired
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {label}
        </label>
      )}
      <div
        className={
          'text-field__password-input-container ' +
          clsx(
            !disabled ? 'TextField__voter' : 'TextField__voter TextField__voterDisabled',
            {'is-invalid': formik.touched[field] && formik.errors[field]},
            {
              'is-valid': formik.touched[field] && !formik.errors[field],
            }
          )
        }
      >
        <input
          id={id}
          onClick={onClick}
          placeholder={placeholder}
          {...formik.getFieldProps(field)}
          className={'text-field__password-input'}
          type={passwordVisable ? 'text' : 'password'}
          name={field}
          autoComplete='off'
          disabled={disabled}
          value={formik.values[field]} // Use value prop to control the input value
          onChange={(e) => {
            formik.handleChange(e)
            onChange(e)
          }}
        />
        {/* if password visable state is true, then show the open eye icon */}
        {!passwordVisable ? (
          <PiEye
            size={24}
            onClick={() => {
              setPasswordVisable(true)
            }}
          />
        ) : (
          <PiEyeSlash
            size={24}
            onClick={() => {
              setPasswordVisable(false)
            }}
          />
        )}
      </div>
    </div>
  )

  const renderView = () => {
    if (view == 'voter') {
      return VoterInput
    }

    if (view == 'password') {
      return PasswordInput
    }

    if (view == 'admin_searchbar-no-formik') {
      return SearchBarNoFormikInput
    }

    if (view == 'admin_searchbar') {
      return AdminSearchInput
    }
  }

  useEffect(() => {
    // Effect to set input type based on the provided 'type' prop
    if (type === 'email') {
      setInputType('email')
    } else if (type === 'password') {
      setInputType('password')
    } else {
      setInputType('text')
    }
  }, [type])

  return <>{renderView()}</>
}

export default TextField
