import {useHistory} from 'react-router-dom'
import './BackBtn.scss'
import {useTranslation} from '../../../hooks/useTranslation'

export const BackBtn = ({navigateTo = null, customStyling = {}}) => {
  const history = useHistory()
  const {translate} = useTranslation()
  return (
    <div
      style={customStyling}
      className='BackBtn'
      onClick={() => {
        navigateTo ? history.push(navigateTo) : history.goBack()
      }}
    >
      <span className='backBtn__label'>
        {'<'} {translate('Back')}
      </span>
    </div>
  )
}
