/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import '@djthoms/pretty-checkbox'

import {useTranslation} from '../../../../hooks/useTranslation'
import {VoterLoginFlowContext} from '../../../../contexts/VoterLoginFlowContext'
import {VoterUserContext} from '../../../../contexts/VoterUserContext'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'

import {LanguageContext} from '../../../../contexts/LanguageContext'
import OnlineVoterAuthService from '../../../../services/auth/OnlineVoterAuthService'
import {ProgressBar} from '../reusable/ProgressBar'
import {ProgressBarMobile} from '../reusable/ProgressBarMobile'
import TextField from '../../../../modules/auth/reusable/TextField'
import {PasswordValidate} from '../PasswordValidate'
import RecapatchaDisclaimer from '../../../../resuable/recapatcha/RecapatchaDisclaimer'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import LanguageSpecificColon from '../../../../components/language_specific_colon/LanguageSpecificColon'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const passwordSchema = Yup.object().shape({})

export function MEXICO__CreatePassword() {
  const {translate} = useTranslation()

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)
  const [checkedOption, setCheckedOption] = useState(null)
  const [loading, setLoading] = useState(false)
  const [validationResult, setValidationResult] = useState(false)
  const [passwordMatchErr, setPasswordMatchErr] = useState(false)

  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()

  const VoterUser = useContext(VoterUserContext)

  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          let password = values.password
          let confirmPassword = values.confirmPassword

          if (password !== confirmPassword) {
            console.log('passwords must be the same!')
            setPasswordMatchErr(true)
            return
          }
          const userInput = {
            password,
            confirmPassword,
          }

          let resp = await OnlineVoterAuthService.createNewPassword(userInput.password)
          if (resp.user) {
            VoterUser.setUser(resp.user)
          }
          VOTER_LOGIN_CONTEXT.setLoginFlow('Login')
          setPasswordMatchErr(false)
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  useEffect(() => {
    let password = formik.values.password
    let confirmPassword = formik.values.confirmPassword

    if (password == confirmPassword) {
      setPasswordMatchErr(false)
    }
  }, [formik.values.password, formik.values.confirmPassword])

  return (
    <div className=' CreatePassword' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div
          className={
            LANGUAGE_CONTEXT.currentLanguage !== 'English'
              ? ' common__form createPassword__form  authMethod__form--spanish'
              : 'common__form createPassword__form'
          }
        >
          {/* HEADER */}
          <ProgressBar currentStep={4} />
          <ProgressBarMobile currentStep={4} />
          {/* BODY */}
          <div className='createPassword__form__body'>
            <div className='createPassword__form__body__userInfoContainer'>
              <span className='createPassword__form__body__userInfo__greetingTxt'>
                {translate('Hi')},{' '}
                <span className='common__primaryTxtColor'>{VoterUser.user?.username}</span>
              </span>
              <div className='createPassword__form__body__phoneInfoContainer'>
                <span className='createPassword__form__body__phoneInfo__label'>
                  {translate('Phone Number')}
                  <LanguageSpecificColon />
                </span>
                <span className='createPassword__form__body__phoneInfo__value'>
                  {VoterUser.user?.phone}
                </span>
              </div>
            </div>

            {/* Password section */}
            <form
              className='createPassword__form__body__passwordContainer'
              onSubmit={(e) => {
                e.preventDefault()
                formik.submitForm()
              }}
            >
              <span className='createPassword__form__body__password__label'>
                {translate('Create password')}
              </span>

              <div>
                <TextField
                  formik={formik}
                  type='password'
                  field='password'
                  placeholder={translate('New password')}
                  label={translate('New password')}
                  isRequired={true}
                  view='password'
                />

                <div className='createPassword__form__body__password__confirmInput'>
                  <TextField
                    formik={formik}
                    type='password'
                    field='confirmPassword'
                    placeholder={translate('Confirm new password')}
                    label={translate('Confirm new password')}
                    isRequired={true}
                    view='password'
                  />

                  <PasswordValidate
                    formik={formik}
                    result={(value) => {
                      setValidationResult(value)
                    }}
                  />

                  {passwordMatchErr && (
                    <div className='createPassword__form__body__password__matchErrWrap'>
                      <span
                        className='createPassword__form__body__validation__errorTxt'
                        role='alert'
                      >
                        {translate('Password & Confirm Password must match!')}
                      </span>{' '}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>

          {/* FOOTER  */}
          <div className='phoneVerify__form__actionBar'>
            <button
              type='button'
              onClick={() => {
                formik.submitForm()
              }}
              className={
                validationResult
                  ? 'authMethodLogin__nextBtn'
                  : 'authMethodLogin__nextBtn common__disabledBtn'
              }
              disabled={!validationResult}
            >
              {translate('Save')}
            </button>
          </div>
        </div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt600' view='mobile' />
    </div>
  )
}
