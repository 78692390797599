import React, {useState, useEffect, useContext, useCallback} from 'react'
import './ModalHeader.scss'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'

export function ModalHeader(props) {
  let {headerLabel} = props

  const [loading, setLoading] = useState(false)
  const {customLogoImg} = useVoterStyles()

  return (
    <div className='VoterModalHeader' style={headerLabel ? {padding: '16px 20px 12px 30px'} : {}}>
      {headerLabel ? (
        <span className='voterModalHeader__title'>{headerLabel}</span>
      ) : (
        <img src={toAbsoluteUrl(customLogoImg)} className='voterModalHeader__logo' />
      )}

      {/* X BUTTON */}
      <img
        src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
        className='civik-modal-x-btn'
        onClick={props.onRequestClose}
      />
    </div>
  )
}
