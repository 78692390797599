/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import './MEXICO__SuperAdmin__ElectorsList.scss'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
import EligibleElectorsList from '../../../../voter_admin/pages/nova_scotia/electors_list/EligibleElectorsList'
import {ImportAddressList} from '../../../../voter_admin/pages/nova_scotia/address_list/ImportAddressList'
import {useSuperAdminUserContext} from '../../../../../contexts/SuperAdminUserContext'
import KeycloakAdminService from '../../../../../../KeycloakAdminService'
import {beforeUnload} from '../../../../../App'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {languageValueToKey} from '../../../../../languages/languagekey'

export const initialValues = {}

export const schema = Yup.object().shape({})

export const listTypes = {
  voterStrikeOff: 'voter-strike-off',
  eligibleVoters: 'eligible-voters',
  voterDelete: 'voter-delete',
}

export function EligibleElectorsListTabContainer(props) {
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('Electors List')
  const {tiviLink} = useSuperAdminUserContext()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const viewOnly =
    !KeycloakAdminService.hasRole('tivi-root-admin') &&
    !KeycloakAdminService.hasRole('tivi-event-admin')

  const contactInfoRole = KeycloakAdminService.hasRole('tivi-contactinfo')

  const renderView = () => {
    if (currentTab === 'Import List') {
      return <ImportAddressList listType={listTypes.eligibleVoters} />
    }
    if (currentTab === 'Voter-Strike-Off') {
      return <ImportAddressList listType={listTypes.voterStrikeOff} />
    }
    if (currentTab === 'Electors List') {
      return <EligibleElectorsList />
    }
    if (currentTab === listTypes.voterDelete) {
      return <ImportAddressList listType={listTypes.voterDelete} />
    }
  }

  useEffect(() => {
    renderView()
  }, [currentTab])

  return (
    <>
      <div className='SuperAdmin__ElectorsList' id='Voter'>
        {/* CONTENT */}

        {/* TABS */}
        <div className='voterAddrList__tabWrap' style={{display: 'flex'}}>
          <div className='adminUsers__header'>
            <span
              onClick={() => {
                setCurrentTab('Electors List')
              }}
              className={
                currentTab == 'Electors List'
                  ? 'voterAddrList__activeTab'
                  : 'voterAddrList__inactiveTab'
              }
            >
              {translate('Eligible Electors')}
            </span>

            {!viewOnly && (
              <>
                <span
                  onClick={() => {
                    setCurrentTab('Import List')
                  }}
                  className={
                    currentTab == 'Import List'
                      ? 'voterAddrList__activeTab'
                      : 'voterAddrList__inactiveTab'
                  }
                >
                  {translate('Import List')}
                </span>
              </>
            )}

            {!viewOnly && (
              <>
                <span
                  onClick={() => {
                    setCurrentTab('Voter-Strike-Off')
                  }}
                  className={
                    currentTab == 'Voter-Strike-Off'
                      ? 'voterAddrList__activeTab'
                      : 'voterAddrList__inactiveTab'
                  }
                >
                  {translate('Import Voter Strike-Off List')}
                </span>
              </>
            )}

            {!viewOnly && (
              <>
                <span
                  onClick={() => {
                    setCurrentTab(listTypes.voterDelete)
                  }}
                  className={
                    currentTab === listTypes.voterDelete
                      ? 'voterAddrList__activeTab'
                      : 'voterAddrList__inactiveTab'
                  }
                >
                  {translate('Upload Ineligible Voter List')}
                </span>
              </>
            )}
          </div>
        </div>

        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </>
  )
}

export default EligibleElectorsListTabContainer
