import React from 'react'
import {Spinner} from 'react-bootstrap-v5'
import styled from 'styled-components'
import {useTranslation} from '../../hooks/useTranslation'

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const ModalSpinner: React.FC = () => {
  const {translate} = useTranslation()
  return (
    <SpinnerContainer>
      <Spinner animation='border' role='status'>
        <span className='sr-only'>{translate('Loading')}...</span>
      </Spinner>
    </SpinnerContainer>
  )
}

export default ModalSpinner
