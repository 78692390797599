/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'

import './RegenQRCodeVoter.scss'

import SecurityQuestion from '../regenQRcode/SecurityQuestion'
import QRCodeRegen from '../regenQRcode/QRcode'
import VoterAuthService from '../../../../../../services/auth/VoterAuthService'
import {useTranslation} from '../../../../../../hooks/useTranslation'

const RegenQRCodeVoter = (props) => {
  const {translate} = useTranslation()

  const history = useHistory()
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('')
  const [securityCode, setSecurityCode] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [factorSid, setfactorSid] = useState('')

  const onSubmitSecurityCode = async () => {
    try {
      let result = await VoterAuthService.regenerateAuthAppQRCode(securityCode)
      if (result.success) {
        setQrCodeDataUrl(result.qrCodeDataUrl)
        setfactorSid(result.factorSid)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onSubmitVerificationCode = async () => {
    try {
      let result = await VoterAuthService.verifyRegenerateAuthAppQRCode(verificationCode, factorSid)
      if (result.success) {
        history.push('/voting/voter/my-profile', {status: 'verificationSuccess--qrCodeChange'})
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='MEXICO__MyProfile '>
      <div className='common__pg__header'>
        <span className='common__pg__header__title'>{translate('Setup/Change QR Code')}</span>
      </div>

      <div className='myProfile__body'>
        <div className='myProfile__body__details regenQr__body__details'>
          {!qrCodeDataUrl ? (
            <SecurityQuestion
              onSubmit={onSubmitSecurityCode}
              code={securityCode}
              setCode={setSecurityCode}
              view='Voter__MyProfile'
            />
          ) : (
            <QRCodeRegen
              qrCodeDataUrl={qrCodeDataUrl}
              code={verificationCode}
              setCode={setVerificationCode}
              onSubmit={onSubmitVerificationCode}
              view='Voter__MyProfile'
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default RegenQRCodeVoter
